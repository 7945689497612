// import config from '../../kx-sdk/config';
// let getAuthConfig = require('../../kx-sdk/getAuthConfig');
// const api = (buildMode = false) => {
//     let config = getAuthConfig(buildMode)
//     return {
//         login: config.KX_BASE + '/common/v0.1/login',
//         logout: config.KX_BASE + '/common/v0.1/logout',
//         forgotPassword: config.KX_BASE + '/common/v0.1/forgot-password',
//         resetPassword: config.KX_BASE + '/common/v0.1/reset-password',
//         getNewToken: config.KX_BASE + '/common/v0.1/issue-new-token'
//     }
// }
module.exports = (KX_BASE) => {
    return {
        login: KX_BASE + '/common/v0.1/login',
        logout: KX_BASE + '/common/v0.1/logout',
        forgotPassword: KX_BASE + '/common/v0.1/forgot-password',
        resetPassword: KX_BASE + '/common/v0.1/reset-password',
        getNewToken: KX_BASE + '/common/v0.1/issue-new-token',
        loginUsingGoogle: KX_BASE + '/common/v0.1/google-login',
        getSAMLEndpoint: KX_BASE + '/common/v0.1/get-saml-endpoint',
        samlLogin: KX_BASE + '/common/v0.1/saml-login',
        client_slrt: KX_BASE + '/tenant/v2.0/generate-client-slrt',
        helpAndSupport: KX_BASE + '/common/v0.1/help',
        resetPasswordSpecialUrl: KX_BASE + '/common/v0.1/reset-password-url'
    }
};