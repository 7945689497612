import constants from "@/api"
const initialState = () => ({
    // listOfAllSupplements: [],
    listOfSupplements: [],
    listOfRequestedSupplements: [],
    isFetchingSupplements: false,
    isFetchingRequest: false,
    suppPaginationConf: {
        paginationLength: 0,
        total: 1,
        page: 1,
        rowsPerPage: 8,
        filterText: "",
    },
    requestedPaginationConf: {
        paginationLength: 0,
        total: 1,
        page: 1,
        rowsPerPage: 8,
        filterText: "",
    },
    suppSelectMode: true,
    suppleAttachTo: "Dashboard Level",
    selectedLayoutForSupplAttachment: "",
    selectedSupplements: [],
    showOnlyConnectedSupplements: false,
    initialDefaultParams: [],
    badgeContent: 0,
    connectedSupplements: [],
    connectedSupplementsIds: []
})

const mutations = {
    incrementBadge(state){
        state.badgeContent++
    },
    decrementBadge(state){
        state.badgeContent--
    },
    emptyBadge(state){
        state.badgeContent = 0
    },
    setSupplements(state, list) {
        state.listOfSupplements = list;
    },
    setRequestedSupplements(state, list) {
        state.listOfRequestedSupplements = list;
    },
    toggleAttachSupplementsMode(state) {
        state.suppSelectMode = !state.suppSelectMode
    },
    setSupplementAttachTo(state, data) {
        state.suppleAttachTo = data
    },
    setSelectedLayoutForSupplAttachment(state, data) {
        state.selectedLayoutForSupplAttachment = data
    },
    setSelectedSupplArray(state, data = []) {
        state.selectedSupplements = data
    },
    setInitailDefaultParams(state, data) {
        state.initialDefaultParams = data;
    },
    setShowOnlyConnectedSupplements(state, data = false) {
        state.showOnlyConnectedSupplements = data
    },
    setSuppPaginationconf(state, config) {
        Object.assign(state.suppPaginationConf, config)
    },
    setRequestPaginationconf(state, config) {
        Object.assign(state.suppPaginationConf, config)
    },
    setFilterText(state, { tabId, value }) {
        const tabConf = { 0: "suppPaginationConf", 1: "suppPaginationConf", 2: "requestedPaginationConf" };
        // console.log({ selectedTab, value });
        // console.log(`[ state[tabConf[selectedTab]]]: `, state[tabConf[selectedTab]]);
        state[tabConf[tabId]].filterText = value;
    },
    setConnectedSupplement(state, list) {
        state.connectedSupplements = list;
    },
    setConnectedSupplementsIds(state, list) {
        state.connectedSupplementsIds = list;
    }
}

const actions = {
    getAllSuplements: async ({ dispatch, commit, state }, payload = {}) => {
        try {
            state.isFetchingSupplements = true;
            let response = await dispatch("apiCall", {
                method: "get",
                url: constants.BASE_EX_URL() + "/Supplements/v0.1/",
                params: { ...state.suppPaginationConf, ...payload }
            }, { root: true })
            console.log(`[response]: `, response);
            if (!response.ok) {
                throw new Error(response.message)
            }
            // state.listOfAllSupplements = response.allSupplements
            // console.log(state.listOfAllSupplements)
            state.suppPaginationConf.total = response.totalSupplements
            state.suppPaginationConf.paginationLength = Math.ceil(response.totalSupplements / state.suppPaginationConf.rowsPerPage)
            commit("setSupplements", response.list)
            state.isFetchingSupplements = false;
            return response;
        } catch (error) {
            console.error(error);
            return false
        }
    },

    getAllRequestedSupplements: async ({ dispatch, commit, state }, payload = {}) => {
        try {
            state.isFetchingRequest = true;
            let response = await dispatch("apiCall", {
                method: "get",
                url: constants.BASE_EX_URL() + "/RequestSupplementsManagement/v0.1/",
                params: { ...state.requestedPaginationConf, ...payload }
            }, { root: true })
            console.log(`[response]: `, response);
            if (!response.ok) {
                throw new Error(response.message)
            }
            state.requestedPaginationConf.total = response.total
            state.requestedPaginationConf.paginationLength = Math.ceil(response.total / state.requestedPaginationConf.rowsPerPage)
            commit("setRequestedSupplements", response.list)
            state.isFetchingRequest = false;
            return response;
        } catch (error) {
            console.error(error);
        }
    },

    createRequest: async ({ dispatch, commit, state }, payload) => {
        try {
            console.log(payload);
            let response = await dispatch("apiCall", {
                method: "post",
                url: constants.BASE_EX_URL() + "/RequestSupplementsManagement/v0.1/",
                data: payload
            }, { root: true })
            console.log(`[response]: `, response);
            state.requestedPaginationConf.page = 1;
            state.requestedPaginationConf.filterText = "";
            dispatch("getAllRequestedSupplements");
            return response;
        } catch (error) {
            console.error(error);
            commit("openSnackbar", error.message, { root: true })
        }
    },

    updateRequest: async ({ dispatch, commit, state }, payload) => {
        try {
            console.log(`[payload]: `, payload);
            let response = await dispatch("apiCall", {
                method: "put",
                url: constants.BASE_EX_URL() + "/RequestSupplementsManagement/v0.1/" + payload._id,
                data: payload
            }, { root: true })
            console.log(`[response]: `, response);
            dispatch("getAllRequestedSupplements");
            return response;
        } catch (error) {
            console.error(error);
            commit("openSnackbar", error.message, { root: true })
        }
    },

    getSupplementsByIds: async ({ dispatch }, payload) => {
        try {
            let response = await dispatch("apiCall", {
                method: "post",
                url: constants.BASE_EX_URL() + "/Supplements/v0.1/" + "by-ids",
                data: { identifierRefs: payload }
            }, { root: true })
            console.log(`[response]:= `, response);
            if (!response.ok) {
                throw new Error(response.message)
            }
            return response;
        } catch (error) {
            console.error(error);
            return []
        }
    },
    ////////////////////////Supplement render/////////////////////////////

    selfRoleConfig: async ({ dispatch, commit, state }, config) => {
        try {
            let result = await dispatch("apiCall", {
                method: "post",
                url: constants.BASE_EX_URL() + "/supplementRender/v0.1/" + "selfRoleConfig",
                data: { ...config }
            }, { root: true });
            return result;
        } catch (error) {
            console.log("[selfRoleConfig]", error);
            return false
        }
    },
    fetchHrcyMap: async ({ dispatch, commit, state }, config, userinfo, filterData = {}) => {
        try {
            console.log(`[filterData]: `, filterData);
            console.log("config", config);
            console.log(`[userinfo]: `, userinfo);
            let result = await dispatch("apiCall", {
                method: "post",
                url: constants.BASE_EX_URL() + "/supplementRender/v0.1/" + "hrcy",
                data: { ...config }
            }, { root: true });
            return result;
        } catch (error) {
            console.log("[selfRoleConfig]", error);
            return false
        }
    },
    fetchMasterDataMap: async ({ dispatch, commit, state }, config) => {
        try {
            let result = await dispatch("apiCall", {
                method: "post",
                url: constants.BASE_EX_URL() + "/supplementRender/v0.1/" + "masterDateMap",
                data: { ...config }
            }, { root: true });
            return result;
        } catch (error) {
            console.log("[selfRoleConfig]", error);
            return false
        }
    },

    fetchIdp: async ({ dispatch, commit, state }, config) => {
        try {
            let result = await dispatch("apiCall", {
                method: "post",
                url: constants.BASE_EX_URL() + "/supplementRender/v0.1/" + "idp",
                data: { ...config }
            }, { root: true });
            return result;
        } catch (error) {
            console.log("[inidefaultParams]", error);
            return false
        }
    },

    ////////////////////////Attach Supplement///////////////////////////
    saveAttachSupplements: async ({ dispatch, commit, state }, payload) => {
        try {
            let result = await dispatch("apiCall", {
                method: "put",
                url: constants.BASE_EX_URL() + '/BIReports/v0.1/attachSupplements',
                data: { ...payload, initialDefaultParams: state.initialDefaultParams }
            }, { root: true });
            return result;
        } catch (error) {
            console.log("[saveAttachSupplements]", error);
            return { ok: false, message: error.toString() }
        }
    }
}


const getters = {
    listOfSupplements: (state) => state.listOfSupplements,
    // listOfAllSupplements: (state) => state.listOfAllSupplements,
    listOfRequestedSupplements: (state) => state.listOfRequestedSupplements,
    suppPaginationConf: (state) => state.suppPaginationConf,
    requestedPaginationConf: (state) => state.requestedPaginationConf,
}

export default {
    namespaced: true,
    state: initialState(),
    mutations,
    actions,
    getters
}