let getAuthConfig = require("../getAuthConfig");
module.exports = (requestType, window, authSourceOrigin, callBack) => {
    const { portals: { KX_UI }, LOG_TAG, AUTH_TAB_SHARE_TIMEOUT } = getAuthConfig();
    if (window && window.opener) {
        authSourceOrigin = authSourceOrigin || KX_UI;
        console.log(LOG_TAG, " authenticating!!!");
        let timer = window.setTimeout(() => {
            callBack && callBack("time-out");
            console.log(LOG_TAG, "failed due to timeout");
            window.removeEventListener("message", receiver);
        }, AUTH_TAB_SHARE_TIMEOUT || 15000)
        let receiver = (event) => {
            if (event.origin == authSourceOrigin) {
                console.log(LOG_TAG, "auth response arrived = ", event);
                // check for timestamp
                callBack && callBack(null, event.data);
                window.clearTimeout(timer);
                window.removeEventListener("message", receiver);
                // console.log("%c  window.removeEventListener('message', receiver)", "color:yellow; font-weight:bold;font-size:20px")
            }
        };
        window.addEventListener("message", receiver);
        // console.log("%c  window.addEventListener('message', receiver)", "color:yellow; font-weight:bold;font-size:20px")
        window.opener.postMessage(
            {
                request: requestType,
                origin: window.location.origin,
                ts: new Date()
            },
            authSourceOrigin
        );
    } else {
        console.log(LOG_TAG, '[warning]', 'window is not opened by another source.');
        callBack && callBack(null, 'no parent');
        window && window.removeEventListener("message", receiver);
    }
}