<template>
  <div class="content-view-port">
    <v-card
      class="content-view-port d-flex justify-center"
      style="border-radius: 20px; padding: 5px"
    >
      <v-card-text class="text-center">
        <div v-if="this.isDataAvailble" v-html="textModefied"></div>
        <div v-if="!this.isDataAvailble" class="no-data">
          {{ chartConfig.messageForNoData || "No Data" }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["chartConfig", "dataProvider"],
  data() {
    return {
      textModefied: "",
      columnsInText: null,
      show: false,
      isDataAvailble: true,
    };
  },
  mounted() {
    console.log(`[dataProvider]: `, this.dataProvider.length);
    let allColumnInText = [],
      interpolateText = this.chartConfig.text;
    let getAllColumns = () => {
      let startMatch = "{{",
        endMatch = "}}";
      if (
        interpolateText.indexOf(startMatch) < 0 ||
        interpolateText.indexOf(startMatch) < 0
      )
        return;
      let getOneColumn = () => {
        let startIdx = interpolateText.indexOf(startMatch) + startMatch.length;
        let endIdx = interpolateText.indexOf(endMatch);
        return interpolateText.substring(startIdx, endIdx);
      };
      allColumnInText.push(getOneColumn());
      interpolateText = interpolateText.replace(
        startMatch + getOneColumn() + endMatch,
        ""
      );
      if (
        interpolateText.indexOf(startMatch) > -1 &&
        interpolateText.indexOf(endMatch)
      ) {
        getAllColumns();
      } else return allColumnInText;
      return allColumnInText;
    };
    getAllColumns();
    // console.log('allColumnInText',allColumnInText);

    this.textModefied = this.chartConfig.text;
    // if (!this.columnsInText) {
    //   this.show = true;
    //   return;
    // }
    // console.log('this.columnsInText',this.columnsInText);

    if (this.dataProvider.length === 0) {
      this.show = false;
      console.log({ textInterpolation: "nothing data has to show" });
      if (allColumnInText.length) {
        this.isDataAvailble = false;
      }
    } else if (this.dataProvider.length > 0) {
      let hasAllKeys = allColumnInText.every((column) => {
        return this.dataProvider[0].hasOwnProperty(column);
      });
      if (hasAllKeys) {
        for (let column in this.dataProvider[0])
          this.textModefied = this.textModefied
            .split("{{" + column + "}}")
            .join(this.dataProvider[0][column]);
        this.show = true;
      } else {
        this.show = false;
        console.log(
          "All keys are not present in data which is specified in the text",
          { allColumnInText: this.allColumnInText, column: this.chartConfig }
        );
      }
    }
  },
};
</script>

<style></style>
