import getThemes from "../theme"
class context {

    // #generic = {}
    #amKLic
    // templateData = {}
    // #templateCtx = {}
    // armory = {
    //     supplements: {},
    //     elements: {},
    //     // exportConfig: {},

    // }
    // am4cores
    theme

    constructor({ ready, theme }) {
        this.#amKLic = "CH198746946" // FIXME: vulnerable. it exposing in browser.
        this.ready = ready
        this.setTheme(theme)
        // if (theme) {
        //     this.theme = getThemes(theme)
        // } else {
        //     this.theme = getThemes('theme_1')
        // }
        // this.applyTheme()
    }
    isLiteralObject = function (a) {
        return (!!a) && (a.constructor === Object);
    };

    recLookup(obj, path) {
        let parts = path.split(".");
        if (parts.length == 1) {
            return obj[parts[0]];
        }
        return this.recLookup(obj[parts[0]], parts.slice(1).join("."));
    }

    getValue(str) {
        if (String(str).startsWith("$")) {
            let kk = str.substring(1)
            return this.getValue(this.recLookup(this.theme, kk))
        } else {
            return str
        }
    }

    getChildrensArray(obj, seperator = "-", previous = false) {
        return [].concat.apply(
            [],
            Object.keys(obj).map(v => {
                if (this.isLiteralObject(obj[v])) {
                    // console.log(v);
                    return this.getChildrensArray(
                        obj[v],
                        seperator,
                        `${previous ? previous + seperator : "--"}${v}`
                    );
                } else if (Array.isArray(obj[v])) {
                    return ''
                } else {
                    // console.log(v);
                    let vvv = this.getValue(obj[v]);
                    return { [`${previous ? previous + seperator : "--"}${v}`]: vvv };
                }
            })
        );
    }

    applyTheme() {
        let style = document.documentElement.style;
        if (this.theme.typography) {
            let variables = this.getChildrensArray(this.theme)
            variables.forEach(e => {
                if (this.isLiteralObject(e)) {
                    for (const [key, value] of Object.entries(e)) {
                        style.setProperty(key, value)
                    }

                }
            });
        }
    }
    setTheme(name) {
        this.theme = getThemes(name || "theme_1");
        this.applyTheme()
    }
    // make it more private
    // setGeneric(obj) {
    //     this.#generic = obj;
    // }
    getGeneric(key) {
        return this[`${key}`]
    }
    setTemplateConfig() { }
    setAmchartTheme({ am4core, am4themes_animated }) {
        // let { am4core } = this.am4cores
        // this.am4core = am4core
        // function amBiTheme(target) {
        //     if (target instanceof am4core.ColorSet) {
        //         target.list = colors.map(color => am4core.color(color))
        //     }
        // }
        // am4core.useTheme(amBiTheme);
        am4core.useTheme(am4themes_animated);
        am4core.addLicense(this.#amKLic);
        am4core.options.commercialLicense = true

    }
}

export default context