<template>
  <v-dialog max-width="700px" v-model="resetPasswordDialogStatus" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="body-1">Reset Password</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Enter Username</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">Enter OTP</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field hide-details v-model.trim="username" label="Enter Username"></v-text-field>
                </v-col>
                <v-col cols="12" justify="center" align="end">
                  <v-btn text color="deep-purple accent-4" @click="requestOTP">Send OTP</v-btn>
                  <v-dialog max-width="500px" v-model="otpMessageDialogStatus" persistent>
                      <v-card class="pa-4">
                      <v-card-text>OTP delivered to Email ID - <b>{{userEmail}}</b></v-card-text>
                      <v-card-text><b>NOTE :</b> <br/>
                        <ol>
                          <li> If your email address exists in Kennect database, you will receive a password reset details at your email address in a few minutes.</li>
                          <li> If you didn't get the email, please check the spam folder.</li>
                        </ol>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="py-0">
                          <v-spacer></v-spacer>
                          <!-- <v-btn color="primary" text @click.stop="closeMessageDialog">Cancel</v-btn> -->
                          <v-btn color="primary" text @click.stop="otpMessageDialogHandler(false)">OK</v-btn>
                      </v-card-actions>
                      </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12" class="mx-auto">
                  <v-alert :value="isAlert" :type="alertType">{{alertMessage}}</v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model.trim="OTP" label="Enter OTP" type="text" hide-details></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="isNewPasswordInvisible ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="isNewPasswordInvisible = !isNewPasswordInvisible"
                    prepend-inner-icon="mdi-lock-outline"
                    :type="isNewPasswordInvisible ? 'password' : 'text'"
                    hide-details
                    v-model="newPassword"
                    label="Enter Password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="isConfirmPasswordInvisible ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="isConfirmPasswordInvisible = !isConfirmPasswordInvisible"
                    prepend-inner-icon="mdi-lock-outline"
                    :type="isConfirmPasswordInvisible ? 'password' : 'text'"
                    hide-details
                    v-model="confirmPassword"
                    label="Enter Password Again"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" justify="center" align="end">
                  <v-btn
                    text
                    color="deep-purple accent-4"
                    :loading="isPasswordResetLoading"
                    @click="resetPassword"
                  >Reset Password</v-btn>
                </v-col>
                <v-col cols="12" class="mx-auto">
                  <v-alert :value="isAlert" :type="alertType">{{alertMessage}}</v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import getAuthConfig from "../getAuthConfig"; //= getAuthConfig();
// const { uri } = getAuthConfig();

export default {
  props: ["resetPasswordDialogStatus"],
  data() {
    return {
      otpMessageDialogStatus: false,
      step: 1,
      uri: getAuthConfig().uri,
      alertMessage: "",
      isAlert: false,
      alertType: "error",
      username: "",
      OTP: "",
      newPassword: "",
      confirmPassword: "",
      passwordResetToken: "",
      isNewPasswordInvisible: true,
      isConfirmPasswordInvisible: true,
      isSubmitOTPLoading: false,
      isPasswordResetLoading: false,
      userEmail: ""
    };
  },
  methods: {
    closeDialog({ isPasswordResetSuccessful }) {
      this.clearForm();
      this.$emit("closeResetPasswordDialog", {
        isPasswordResetSuccessful
      });
    },
    clearForm() {
      this.step = 1;
      this.alertMessage = "";
      this.isAlert = false;
      this.username = "";
      this.OTP = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.passwordResetToken = "";
      this.isNewPasswordInvisible = true;
      this.isConfirmPasswordInvisible = true;
      this.isPasswordResetLoading = false;
      this.isSubmitOTPLoading = false;
    },
    otpMessageDialogHandler(status) {
      this.otpMessageDialogStatus = status;
    },
    async requestOTP() {
      try {
        this.resetAlert();
        if (this.username === "") {
          this.showAlert({
            message: "Username cannot be empty.",
            type: "error"
          });
          return;
        }
        let url = this.uri.forgotPassword;
        this.isSubmitOTPLoading = true;
        let response = await axios.post(url, {
          username: this.username
        });
        this.userEmail = response.data.ok ? response.data.email : "";
        if (!response.data.ok) {
          if (response.data.message) {
            this.showAlert({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.showAlert({
              message: "Error Occured while requesting OTP.",
              type: "error"
            });
          }
          this.isSubmitOTPLoading = false;
          return;
        }
        this.isSubmitOTPLoading = false;
        this.otpMessageDialogHandler(true);
        this.step = 2;
      } catch (error) {
        this.isSubmitOTPLoading = false;
        this.showAlert({
          message: "Error Occured while requesting OTP.",
          type: "error"
        });
      }
    },
    async resetPassword() {
      try {
        this.resetAlert();
        if (this.OTP === "") {
          this.showAlert({
            message: "OTP cannot be empty.",
            type: "error"
          });
          return;
        }
        if (this.OTP.length !== 6) {
          this.showAlert({
            message: "OTP must be of 6 characters.",
            type: "error"
          });
          return;
        }
        if (this.newPassword === "") {
          this.showAlert({
            message: "Password cannot be empty.",
            type: "error"
          });
          return;
        }
        if (this.confirmPassword === "") {
          this.showAlert({
            message: "Password cannot be empty.",
            type: "error"
          });
          return;
        }
        if (this.newPassword !== this.confirmPassword) {
          this.showAlert({
            message: "Passwords doesn't match.",
            type: "error"
          });
          return;
        }
        if(!/^(?=.{8,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(this.newPassword)){
          this.showAlert({
            message: "Invalid password. Minimum password length must be 8 and Maximum is upto 20 characters and must contain atleast 1 Uppercase letter, 1 Lowercase letter, 1 Digit and 1 Special character",
            type: "error"
          });
          return
        }
        this.isPasswordResetLoading = true;
        let url = this.uri.resetPassword;
        let response = await axios.post(url, {
          username: this.username,
          otp: this.OTP,
          newPassword: this.newPassword
        });
        if (!response.data.ok) {
          if (response.data.message) {
            this.showAlert({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.showAlert({
              message: "Error Occured while Resetting Password.",
              type: "error"
            });
          }
          this.isPasswordResetLoading = false;
          return;
        }
        this.isPasswordResetLoading = false;
        this.closeDialog({
          isPasswordResetSuccessful: true
        });
      } catch (error) {
        this.isPasswordResetLoading = false;
        this.showAlert({
          message: "Error Occured while submitting OTP.",
          type: "error"
        });
      }
    },
    showAlert({ message, type }) {
      this.isAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    resetAlert() {
      this.isAlert = false;
    }
  }
};
</script>