// Set message listener to get logout request from AuthOpener/Parent Window
function setLogoutReqFromParent(store, isNuxt = false) {
  let accessToken = sessionStorage.getItem("auth");
  if (accessToken && accessToken !== "") {
    // Handle logout request from parent window
    let handleLogoutReqFromParent = event => {
      // console.log('event :>> ', event);
      // console.log('store 134958394587:>> ', store);
      // console.log('isNuxt 134958394587 :>> ', isNuxt);
      let tabId = sessionStorage.getItem("tabId");
      let opener = sessionStorage.getItem("opener");

      if (event.origin === opener && event.data.tabId === tabId) {
        if (event.data.request === "logout") {
          const text = event.data.isNuxt && event.data.isNuxt ?  'root/logout' : 'logout'
          console.log('text :>> ', text);
          store.commit(text, { request: "logoutReqFromParent" });
          window.removeEventListener("message", handleLogoutReqFromParent);
        }
      }
    };
    window.addEventListener("message", handleLogoutReqFromParent);
  }
}

module.exports = {
  setLogoutReqFromParent,
};

