<template>
  <v-dialog v-model="helpDialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mt-2" text v-bind="attrs" v-on="on">
        Help
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="body-1">Help & Support</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeHelpDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="mx-auto">
              <v-alert :value="isAlert" :type="alertType">{{
                alertMessage
              }}</v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Username*"
                v-model="username"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Organization*"
                v-model="companyName"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Employee ID*"
                v-model="employeeId"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Phone Number"
                v-model="phoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Email ID" v-model="email"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Message*"
                v-model="message"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div class="text-center pb-5">
        <v-btn color="blue darken-1" dark @click="submitHelpForm">
          Submit
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import getAuthConfig from "../getAuthConfig";
export default {
  data() {
    return {
      uri: getAuthConfig().uri,
      helpDialog: false,
      username: "",
      companyName: "",
      employeeId: "",
      phoneNumber: "",
      email: "",
      alertMessage: "",
      isAlert: false,
      alertType: "error",
      message: "",
    };
  },
  methods: {
    showAlert({ message, type }) {
      this.isAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    formValidation() {
      if (this.username.trim() === "") {
        this.showAlert({
          message: "Username feild cannot be empty",
          type: "error",
        });
        return false;
      }
      if (this.companyName.trim() === "") {
        this.showAlert({
          message: "Company Name feild cannot be empty",
          type: "error",
        });
        return false;
      }
      if (this.employeeId.trim() === "") {
        this.showAlert({
          message: "Employee ID feild cannot be empty",
          type: "error",
        });
        return false;
      }
      if (this.email.trim() === "" && this.phoneNumber === "") {
        this.showAlert({
          message: "Please enter Email or Phone Number",
          type: "error",
        });
        return false;
      }
      const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!EMAIL_REGEX.test(this.email)) {
        this.showAlert({
          message: "Email is invalid",
          type: "error",
        });
        return false;
      }
      if (this.message === "") {
        this.showAlert({
          message: "Message Feild cannot be empty",
          type: "error",
        });
        return false;
      }
      return true;
    },
    async submitHelpForm() {
      try {
        if (!this.formValidation()) {
          return;
        }
        this.resetAlert();
        let url = this.uri.helpAndSupport;
        let response = await axios.post(url, {
          username: this.username,
          tenant: this.companyName,
          employerGID: this.employeeId,
          phone: this.phoneNumber,
          email: this.email,
          message: this.message,
        });
        if (!response.data.ok) {
          throw "Error in sending help request..";
        }
        console.log("Logged Output: submitHelpForm -> response", response);
        this.closeHelpDialog();
      } catch (error) {
        console.error("Error in submitting the help and support form:", error);
      }
    },
    clearFormData() {
      this.username = "";
      this.companyName = "";
      this.employeeId = "";
      this.email = "";
      this.phoneNumber = "";
      this.message = "";
    },
    resetAlert() {
      this.isAlert = false;
    },
    closeHelpDialog() {
      this.clearFormData();
      this.resetAlert();
      this.helpDialog = false;
    },
  },
};
</script>
