import axios from 'axios'
import store from '../store/index'

axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (error.response.status === 401) {
        console.log("UNAUTHORIZED...................", store)
        if (!error.config.headers.hasOwnProperty('isRetry') && error.config.headers.isRetry !== true) {
            try {
                let config = {
                    ...error.config,
                    headers: {
                        ...error.config.headers,
                        "Authorization": store.state.auth,
                        "isRetry": true
                    }
                }
                return axios(config);
            } catch (err) {
                console.log('err', err.toString());
                return err;
            }
        } else {
            if(sessionStorage.hasOwnProperty('auth') && sessionStorage.getItem('auth')!=""){
                await store.dispatch('logout');
            }
            return error;
        }
    } else {
        return error;
    }
});

export default axios;