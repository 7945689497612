<template>
  <div>
    <v-container>
      <v-row>
        <slot></slot>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style></style>
