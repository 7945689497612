import constants from '@/api'
import Vue from 'vue'

const initialState = () => ({
  test: 'test'
})

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      const initial = initialState()
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key]
      })
    }
  },
  actions: {
    getAllLayouts: ({ commit, dispatch }, payload) => {
      return dispatch(
        'apiCall',
        {
          method: 'get',
          params: payload,
          url: constants.REPORT_LAYOUTS() + "/"
        },
        { root: true }
      )
    }
  },
  getters: {}
}
