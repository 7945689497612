<template>
  <v-dialog max-width="700px" v-model="SAMLLoginDialogStatus" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="body-1">SSO Login</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-dialog v-model="isSAMLLoginInProcess" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text class="text-center">
              SSO Login is in progress
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="12">
            <v-text-field hide-details v-model="username" label="Enter Email" autocomplete="off"></v-text-field>
          </v-col>
          <v-col cols="12" justify="center" align="end">
            <v-btn text color="deep-purple accent-4" @click="samlLogin">Submit</v-btn>
          </v-col>
          <v-col cols="12" class="mx-auto">
            <v-alert :value="isAlert" :type="alertType">
              {{
              alertMessage
              }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import getAuthConfig from "../getAuthConfig"; //= getAuthConfig();
const StorageUtils = require("@common-ui/authenticator/StorageUtils");

export default {
  props: ["SAMLLoginDialogStatus"],
  data() {
    return {
      uri: getAuthConfig().uri,
      KX_BASE: getAuthConfig()["sdkConf"]["KX_BASE"],
      alertMessage: "",
      isAlert: false,
      alertType: "error",
      username: "",
      isSAMLLoginInProcess: false,
    };
  },
  computed: {
    isMobile() {
      return StorageUtils.isMobile();
    },
  },
  methods: {
    closeDialog({ isSAMLLoginSuccessful }) {
      this.clearForm();
      this.$emit("closeSAMLLoginDialog", {
        isSAMLLoginSuccessful,
      });
    },
    clearForm() {
      this.alertMessage = "";
      this.isAlert = false;
      this.username = "";
    },
    showAlert({ message, type }) {
      this.isAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    resetAlert() {
      this.isAlert = false;
    },
    async samlLogin() {
      try {
        console.log("this.KX_BASE :>> ", this.KX_BASE);
        this.resetAlert();
        if (this.username === "") {
          this.showAlert({
            message: "Username cannot be empty.",
            type: "error",
          });
          return;
        }
        this.isSAMLLoginInProcess = true;
        let url = this.uri.getSAMLEndpoint;

        // Get Tenant Specific SAML/SSO Endpoint URL
        let response = await axios.post(url, {
          username: this.username,
        });
        if (!response.data.ok) {
          if (response.data.message) {
            this.showAlert({
              message: response.data.message,
              type: "error",
            });
          } else {
            this.showAlert({
              message: "Error Occured while SAML Login.",
              type: "error",
            });
          }
          this.isSAMLLoginInProcess = false;
          return;
        }
        // console.log('response.data.sso_login_url :', response.data.sso_login_url);

        // Open SAML endpoint URL in iFrame for 3rd party login
        let ssoResponse = await this.openSAMLLoginURLIniFrame(
          response.data.sso_login_url
        );
        console.log("ssoResponse :", ssoResponse);
        if (!ssoResponse.ok) {
          this.isSAMLLoginInProcess = false;
          this.showAlert({
            message: "Failed to login using SSO",
            type: "error",
          });
          return;
        }
        this.isSAMLLoginInProcess = false;
        console.log(
          "TOKEN VERIFICATION SUCCESS AND LOGIN WITH RECEIVED ACCESS TOKEN AND REFRESH TOKEN "
        );
        this.$emit("ssoLogin", ssoResponse);
        // TODO SET AUTH in STORE AND SESSION STORAGE TO CONTINUE FURTHER
      } catch (error) {
        // window.removeEventListener("message", samlTokenHandler);
        console.log("error :", error);
        this.isSAMLLoginInProcess = false;
        this.showAlert({
          message: "Error Occured while requesting SSO Login.",
          type: "error",
        });
      }
    },
    async openSAMLLoginURLIniFrame(url) {
      // console.log('url [FOR SAML/SSO LOGIN] :>> ', url);
      return new Promise(async (resolve, reject) => {
        let windowRef = null;
        let success = false;
        try {
          let endpoint_url = url;
          let foundToken = (samlResponse) => {
            console.log('found token = ', samlResponse)
            return this.verifySAMLToken(samlResponse)
              .then((samlVerificationResp) => {
                console.log("samlVerificationResp :", samlVerificationResp);
                success = true;
                resolve(samlVerificationResp);
              })
              .catch((error) => {
                console.log("error:", error);
                reject(error);
              });
          };
          if (this.isMobile) {
            windowRef = cordova.InAppBrowser.open(
              endpoint_url,
              "_blank",
              "width=550,height=450,left=420,top=120"
            );
            console.log("windowRef = ", windowRef);
            windowRef.addEventListener("loadstop", function () {
              windowRef.executeScript({
                code: "localStorage.setItem( 'name', 'alpha' );",
              });
              var loop = setInterval(function () {
                windowRef.executeScript(
                  {
                    code: "localStorage.getItem('saml')",
                  },
                  function (values) {
                    var saml = values[0];
                     console.log('maybe found token = ', values)
                    if (saml) {
                      clearInterval(loop);
                      windowRef.close();
                      // alert("saml" + saml);
                      foundToken(saml);
                    }
                  }
                );
              }, 500);
            });
            // windowRef.addEventListener( "loadstop", function() {
            //         win.executeScript(
            //             // { code: "document.body.innerHTML" },
            //             { code: "localStorage.getItem( 'a' )"},
            //             function( values ) {
            //                 alert( values[ 0 ] );
            //             }
            //         );
            //     });
          } else {
            windowRef = await window.open(
              endpoint_url,
              "_blank",
              "width=550,height=450,left=420,top=120"
            );

            // windowRef.onunload = () => {
            //   if (samlTokenHandler) {
            //     window.removeEventListener("message", samlTokenHandler);
            //   }
            //   if (!success) {
            //     reject("Window Closed.");
            //   }
            // };
            let samlTokenHandler = async (event) => {
              try {
                console.log("event[samlTokenHandler] :", event);
                // Allow data from trusted Origin
                if (event.origin === this.KX_BASE || this.KX_BASE === "https://kx.stg.kennectweb.com") {
                  let samlResponse = event.data; // SAML Token from 3rd party
                  foundToken(samlResponse);
                }else{
                   reject({ ok: false, message: "Invalid Origin Detected" });
                }

                window.removeEventListener("message", samlTokenHandler);
              } catch (error) {
                window.removeEventListener("message", samlTokenHandler);
                reject(error);
              }
            };
            window.addEventListener("message", samlTokenHandler);
          }
        } catch (error) {
          console.log("error :", error);
          window.removeEventListener("message", samlTokenHandler);
          reject(error);
        }
      });
    },

    async verifySAMLToken(token) {
      try {
        // Call another API to get verify received SAML Token and get SAT and LRT to login to the system
        let samlLogin_url = this.uri.samlLogin;
        let samlTokenVerficationResp = await axios.post(samlLogin_url, {
          username: this.username,
          samlToken: token,
        });
        // console.log("samlTokenVerficationResp :", samlTokenVerficationResp);
        if (!samlTokenVerficationResp.data.ok) {
          if (samlTokenVerficationResp.data.message) {
            return {
              ok: false,
              message: samlTokenVerficationResp.data.message,
            };
          } else {
            return {
              ok: false,
              message: "Error Occured while SSO Login.",
            };
          }
          return;
        }
        // console.log(
        //   "samlTokenVerficationResp FROM SAML VERIFICATION [API]:",
        //   samlTokenVerficationResp.data
        // );

        // return({
        //   ok: true,
        //   message: "SSO/SAML Token verification Success",
        //   result: samlTokenVerficationResp.data
        // });
        return samlTokenVerficationResp.data;
      } catch (error) {
        console.log("error: ", error);
        return {
          ok: false,
          message: "Failed to verify SSO/SAML Token",
        };
      }
    },
  },
};
</script>
