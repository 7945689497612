const initialState = () => ({
    structuralPreview: false,
    selectedType: "structural",
    selectedDevice: "desktop",
    selectedDataType: "dummy",
    deviceScale: {},
    deviceScaleMapping: {
        android: {
            // samsung galaxy note

            className: "view_1",
            width: 400,
            height: 640,
        },
        ios: {
            // iphone 6
            className: "view_2",
            width: 375,
            height: 667,
        },
        ipad: {
            // ipad mini
            className: "view_5",
            width: 768,
            height: 1024,
        },
        desktop: {}
    },
    rotate: false
});

const mutations = {
    setStructuralPreview(state, payload) {
        state.structuralPreview = payload;
    },

    setSelectedType(state, value) {
        state.selectedType = value;
    },
    setSelectedDevice(state, value) {
        state.selectedDevice = value;
        state.deviceScale = state.deviceScaleMapping[value]
    },
    setSelectedDataType(state, value) {
        state.selectedDataType = value;
    },
    setRotation(state) {
        console.log("+++++++++++++++++")
        state.rotate = !state.rotate;
    }
};

const getters = {
    selectedType: (state) => state.selectedType,
    selectedDevice: (state) => state.selectedDevice,
    selectedDataType: (state) => state.selectedDataType,
    deviceScale: (state) => state.deviceScale,
};

export default {
    namespaced: true,
    state: initialState(),
    getters,
    mutations
}