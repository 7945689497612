/* NPM MODULES */
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import amCharts from './plugins/amCharts'
import authenticator from '@common-ui/authenticator'
import dashelement from '@common-ui/bi-dash'

/* PROJECT COMPONENTS */
import './ui'
import Provider from './Provider.vue'
import router from './router/index.js'
import store from './store/index.js'

import Icon from '../src/components/Common/Icon'
import SurveyInstance from "../src/components/Common/SurveyInstance.vue"

import customSurveyWidget from "../src/components/Common/VueSurveyWidget.js"
customSurveyWidget();
// import ChartPreview from "./elements-components/Graphs/ChartPreview.vue"

Vue.config.productionTip = false

Vue.use(authenticator, { store })

Vue.component('Icon', Icon)
Vue.component('SurveyInstance', SurveyInstance)
Vue.use(dashelement, { store, __svg_html: true })
// Vue.component('ChartPreview', ChartPreview)

export const eventBus = new Vue();

new Vue({
  vuetify,
  amCharts,
  store,
  router,
  render: (h) => h(Provider)
}).$mount('#app')
