<template>
  <div :style="bgImg" id="test23">
    <div class="temp_class">
      <v-btn
        text
        color="success"
        class="mt-2 login_btn"
        @click="openIframeForAuth()"
        >Login Again</v-btn
      >
      <b
        >Your session has been expired or you are logged out from other window.
        Please login again.</b
      >
    </div>
  </div>
</template>

<script>
import authByPopUpUtils from "../api/attemptAuthByPopUpUtils";
import bgImg from "../../common/bg/BG option 2.svg";
//"../../common/bg/BG option 2.svg";
export default {
  data: () => ({}),
  computed: {
    bgImg: function () {
      return {
        height: "100vh",
        backgroundColor: "white",
        // backgroundImage: bgImg,
      };
    },
  },
  methods: {
    // Handle auth for child window [ if parent window or any other child window logout at any instance of time ]
    openIframeForAuth() {
      let src =
        authByPopUpUtils.KX_UI + "/#KennectAuth/#" + window.location.origin;
      let iFrameWindowRef = window.open(
        src,
        "_blank",
        "width=650,height=700,left=420,top=120"
      );

      let iFrameMessageHandler = (event) => {
        if (event.origin === authByPopUpUtils.KX_UI) {
          try {
            this.$store.dispatch("setAuth", event.data);
            event.source.postMessage("iFrameAuthSuccess", event.origin);
          } catch (error) {
            console.log("error :", error);
            event.source.postMessage("iFrameAuthFailed", event.origin);
          }
          window.removeEventListener("message", iFrameMessageHandler);
        }
      };
      window.addEventListener("message", iFrameMessageHandler);
    },
  },
};
</script>

<style>
.login_btn {
  background-color: #00e5ff !important;
  border-color: #00e5ff !important;
  color: white;
}
.temp_class {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>