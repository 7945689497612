<template>
  <div>
    <v-dialog
      :retain-focus="false"  
      max-width="700px"
      :value="
        optResetPasswordDialogStatus ||
          !!resetToken ||
          !!$store.state['authStore'].otpAsPasswordDialog
      "
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="body-1">{{
            !!resetToken || !!$store.state["authStore"].otpAsPasswordDialog
              ? "Set your new Password to continue"
              : "Reset Password"
          }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isCloseButtonVisible && !resetToken && !$store.state['authStore'].otpAsPasswordDialog"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container style="padding: 24px 24px 16px 24px;">
          <v-row>
            <v-col
              cols="12"
              v-if="
                !resetToken && !$store.state['authStore'].otpAsPasswordDialog
              "
            >
              <!-- <b>Username:-</b> <span style="color:#1976d2; font-weight:bold; font-size:18px"> {{username}} </span>  -->
              <v-text-field
                v-model.trim="username"
                label="Enter Username"
                :disabled="isUserOnBoarding"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              v-if="
                !resetToken && !$store.state['authStore'].otpAsPasswordDialog
              "
            >
              <v-text-field
                autocomplete="off"
                v-model.trim="OTP"
                label="Enter OTP"
                type="text"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :append-icon="
                  isNewPasswordInvisible ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="isNewPasswordInvisible = !isNewPasswordInvisible"
                prepend-inner-icon="mdi-lock-outline"
                :type="isNewPasswordInvisible ? 'password' : 'text'"
                hide-details
                v-model.trim="newPassword"
                autocomplete="off"
                label="Enter Password"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :append-icon="
                  isConfirmPasswordInvisible ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="
                  isConfirmPasswordInvisible = !isConfirmPasswordInvisible
                "
                prepend-inner-icon="mdi-lock-outline"
                :type="isConfirmPasswordInvisible ? 'password' : 'text'"
                hide-details
                v-model.trim="confirmPassword"
                label="Enter Password Again"
              ></v-text-field>
            </v-col>
            <v-col cols="12" justify="center" align="end">
              <v-btn
                text
                color="deep-purple accent-4"
                :loading="isPasswordResetLoading"
                @click="resetPassword"
                >{{
                  !!resetToken ||
                  !!$store.state["authStore"].otpAsPasswordDialog
                    ? "Set Password"
                    : "Reset Password"
                }}</v-btn
              >
            </v-col>
            <v-col cols="12" class="mx-auto">
              <v-alert :value="isAlert" :type="alertType">{{
                alertMessage
              }}</v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isPasswordResetSuccess" persistent max-width="400">
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="body-1">Kennect Systems</span>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <br /><b
            >Your password has been successfully reset. <br />
            Thank You.</b
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="goToLoginPage()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import getAuthConfig from "../getAuthConfig";
import kxBrowserUtils from "@common-ui/authenticator/api/browserUtils";

export default {
  props: {
    optResetPasswordDialogStatus: Boolean,
    isUserOnBoarding: {
      type: Boolean,
      default: false,
    },
    isCloseButtonVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uri: getAuthConfig().uri,
      alertMessage: "",
      isAlert: false,
      alertType: "error",
      username: "",
      OTP: "",
      newPassword: "",
      confirmPassword: "",
      isNewPasswordInvisible: true,
      isConfirmPasswordInvisible: true,
      isPasswordResetLoading: false,
      isPasswordResetSuccess: false,
      loginURI: getAuthConfig().sdkConf.KNX_UI,
      resetToken: "",
    };
  },
  created() {
    // if (this.isUserOnBoarding) {
    this.userOnBoardingHandler();
    // }
  },
  methods: {
    userOnBoardingHandler() {
      let { username, OTP, reset } = kxBrowserUtils.extractHash(window);
      this.username = username;
      this.OTP = OTP;
      this.resetToken = reset;
    },
    async resetPasswordUsingSpecialUrl() {
      try {
        let url = this.uri.resetPasswordSpecialUrl;
        let response = await axios.post(url, {
          username:
            this.username ||
            this.$store.state["authStore"].mortalForOtpLogin.username,
          otp: this.OTP || this.$store.state["authStore"].mortalForOtpLogin.OTP,
          newPassword: this.newPassword,
          token:
            this.resetToken ||
            this.$store.state["authStore"].mortalForOtpLogin.passwordResetToken,
        });
        if (!response.data.ok) {
          this.showAlert({
            message: response.data.message || "Failed to reset Password",
            type: "error",
          });
          this.isPasswordResetLoading = false;
        } else {
          let authData = response.data;
          this.isPasswordResetSuccess = true;
          this.$emit(
            "onLoginSuccess",
            authData.token,
            authData.refreshToken,
            authData.mortal
          );
        }
      } catch (error) {
        console.error("Error in the userOnBoarding Method:", error);
      }
    },
    closeDialog() {
      this.clearFields();
      this.resetAlert();
      this.$emit("closeOtpResetDialog");
    },
    resetPasswordFormValidation() {
      if (!this.username) {
        this.showAlert({
          message: "username cannot be empty.",
          type: "error",
        });
        return false;
      }
      if (this.OTP === "") {
        this.showAlert({
          message: "OTP cannot be empty.",
          type: "error",
        });
        return false;
      }
      if (this.OTP.length !== 6) {
        this.showAlert({
          message: "OTP must be of 6 characters.",
          type: "error",
        });
        return false;
      }
      if (this.newPassword === "") {
        this.showAlert({
          message: "Password cannot be empty.",
          type: "error",
        });
        return false;
      }
      if (!(this.newPassword.length >= 6)) {
        this.showAlert({
          message: "Password length must be of 6 characters.",
          type: "error",
        });
        return false;
      }
      if (this.confirmPassword === "") {
        this.showAlert({
          message: "Password cannot be empty.",
          type: "error",
        });
        return false;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.showAlert({
          message: "Passwords doesn't match.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    async resetPassword() {
      try {
        if (
          !this.$store.state["authStore"].otpAsPasswordDialog &&
          !this.resetPasswordFormValidation()
        ) {
          return;
        }
        if (this.$store.state["authStore"].otpAsPasswordDialog) {
          if (this.newPassword === "") {
            this.showAlert({
              message: "Password cannot be empty.",
              type: "error",
            });
            return;
          }
          if (!(this.newPassword.length >= 6)) {
            this.showAlert({
              message: "Password length must be of 6 characters.",
              type: "error",
            });
            return;
          }
          if (this.confirmPassword === "") {
            this.showAlert({
              message: "Password cannot be empty.",
              type: "error",
            });
            return;
          }
          if (this.newPassword !== this.confirmPassword) {
            this.showAlert({
              message: "Passwords doesn't match.",
              type: "error",
            });
            return;
          }
          if(!/^(?=.{8,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(this.newPassword)){
            this.showAlert({
              message: "Invalid password. Minimum password length must be 8 and Maximum is upto 20 characters and must contain atleast 1 Uppercase letter, 1 Lowercase letter, 1 Digit and 1 Special character",
              type: "error"
            });
            return
          }
        }
        this.resetAlert();
        this.isPasswordResetLoading = true;

        // TODO: This is for have an OTP (reset Password)
        if (
          !this.resetToken &&
          !this.$store.state["authStore"].otpAsPasswordDialog
        ) {
          let url = this.uri.resetPassword;
          let response = await axios.post(url, {
            username: this.username,
            otp: this.OTP,
            newPassword: this.newPassword,
          });
          if (!response.data.ok) {
            if (response.data.message) {
              this.showAlert({
                message: response.data.message,
                type: "error",
              });
            } else {
              this.showAlert({
                message: "Error Occured while Resetting Password.",
                type: "error",
              });
            }
            this.isPasswordResetLoading = false;
            return;
          }
          this.showAlert({
            message: response.data.message,
            type: "info",
          });
          this.isPasswordResetLoading = false;
          this.isPasswordResetSuccess = true;
        }
        
        // TODO: This is for special url (reset Password)
        if (
          !!this.resetToken ||
          !!this.$store.state["authStore"].otpAsPasswordDialog
        ) {
          await this.resetPasswordUsingSpecialUrl();
        }
      } catch (error) {
        console.log("error :>> ", error);
        this.isPasswordResetLoading = false;
        this.showAlert({
          message: "Error Occured while submitting OTP.",
          type: "error",
        });
      }
    },
    showAlert({ message, type }) {
      this.isAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    resetAlert() {
      this.isAlert = false;
    },
    clearFields() {
      (this.OTP = ""),
        (this.newPassword = ""),
        (this.confirmPassword = ""),
        (this.isPasswordResetSuccess = false);
    },
    goToLoginPage() {
      this.clearFields();
      window.location = this.loginURI;
    },
  },
};
</script>
