import { render, staticRenderFns } from "./ultra-big-number.vue?vue&type=template&id=242610dc&scoped=true&"
import script from "./ultra-big-number.vue?vue&type=script&lang=js&"
export * from "./ultra-big-number.vue?vue&type=script&lang=js&"
import style0 from "./ultra-big-number.vue?vue&type=style&index=0&id=242610dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../bi-portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242610dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../bi-portal/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VProgressLinear})
