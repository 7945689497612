<template>
  <div class="chartdiv">
    <div class="no-data chartdiv" v-if="!dataProvider || !dataProvider.length">
      {{ chartConfig.messageForNoData || "No Data" }}
    </div>
    <div
      class="chartdiv"
      v-else
      :class="refName"
      :ref="refName"
      :style="divStyle"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["dataProvider", "chartConfig", "divStyle"],
  data() {
    return {
      config: {},
      refName: "bi-amchart-" + Math.random().toString().substr(2),
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.config = this.$Chelper.clone(this.chartConfig || {});
      this.config.strokeWidth = 0;

      //this applies config to all graph chart 
      this.config.fontSize = this.config.fontSize || 14;
      this.config.legend = {
        maxHeight: 120,
        scrollable: true,
        markers: {
          width: 14,
          height: 14,
        },
        valueLabels: {
          align: "right",
          textAlign: "end",
        },
        itemContainers: {
          marginRight: 14,
        },
        ...this.config.legend,
      };

      this.config.responsive = {
        enabled: true,
        type: "Responsive",
      };
      // console.log(`[this]:::::::::::::::::::::: `, this);
      this.config.data = this.$Chelper.clone(this.dataProvider || {});
      let { am4core } = this.$am4cores;
      let { colors } = this.$biCtx.theme;
      function amBiTheme(target) {
        if (target instanceof am4core.ColorSet) {
          target.list = colors.map((color) => am4core.color(color));
        }
      }
      am4core.useTheme(amBiTheme);
      am4core.createFromConfig(this.$Chelper.clone(this.config), this.refName);
    },
  },
};
</script>

<style scoped>
.chartdiv {
  height: 100%;
  width: 100%;
}
</style>