<template>
  <div style="margin:1%">
    <v-row align="center" justify="center" class="grey lighten-5" style="height: 300px;">
      <portal-card :isKenxPortal="isKenxPortal" :id="portal" :key="portal" v-for="portal in visiblePortals" />
      <!-- <portal-card :id="portal" :key="portal" v-for="portal in visiblePortals" @logoutReqFromChild="logoutAllChildWin" /> -->
    </v-row>
  </div>
</template>

<script>
import portalCard from "./portalCard";
export default {
  data: () => ({
    // portals: ["su", "bx", "dx", "dsi", "kenx", "inc"],
    isKenxPortal: false,
  }),
  components: { portalCard },
  computed: {
    userType() {
      return this.$store.getters["authStore/userType"];
    },
    // privileges() {
    //   // console.log(
    //   //   'this.$store.state["authStore/mortal"].privileges; ',
    //   //   this.$store.getters["authStore/privileges"]
    //   // );
    //   return this.$store.getters["authStore/privileges"];
    // },
    portals(){
      return this.$store.getters["authStore/portals"];
    },
    visiblePortals() {
      this.isKenxPortal =  this.userType === 30 ? true : false;
      // return this.$store.getters["authStore/portals"];
      // let list = this.portals.filter((elem) => {
      //   return this.privileges.hasOwnProperty(elem);
      // });
      // if (this.privileges.hasOwnProperty("kbet") && !this.isKenxPortal) {
      //   list.push("bx");
      // }
      // if (this.privileges.hasOwnProperty("kbet") && !this.isKenxPortal) {
      //   list.push("dsi");
      // }
      // // if (this.privileges.hasOwnProperty("incentive") && !this.isKenxPortal) {
      // //   list.push("inc");
      // // }
      // if (this.privileges.hasOwnProperty("dw")) {
      //   list.push("dx");
      // }
      // if (this.privileges.hasOwnProperty("kenx") && !this.isKenxPortal) {
      //   list.push("kenx");
      // }
      // return list;
      return this.portals
    }
  },
  methods:{
    // logoutAllChildWin(){
    //   this.$emit("logoutReqFromChild2")
    //   console.log("logoutAllChildWin from portalList")
    // }
  }
};
</script>

<style>
</style>