<template>
  <v-dialog v-model="confirmDialogStatus" persistent width="430">
    <v-card>
      <v-card-title primary-title>{{ header }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="setStatus(false)">Cancel</v-btn>
        <v-btn color="primary" text @click="setStatus(true)">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    confirmDialogStatus() {
      return this.$store.state.confirmDialog.status
    },
    message() {
      return this.$store.state.confirmDialog.message
    },
    header() {
      return this.$store.state.confirmDialog.header
    }
  },
  methods: {
    setStatus(confirmationStatus) {
      this.$store.commit('closeConfirmDialog', {
        confirmationStatus
      })
    }
  }
}
</script>