import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
    routes: [
      {
        path: "/compose-report",
        name: "compose-report",
        component: () => import("../views/layout-composer/index"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/Home"),
      },
      {
        path: "*",
        redirect: { name: "home" },
      },
    ],
});
  