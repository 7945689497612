import constants from '@/api';
import Vue from 'vue'

const initialState = () => ({
    selectedElement: false,
    allEcs: [],
    isDisplayName: true,
    tableData: [],
    ecParams: {},
    errorTextTableHeader: "",
    isDummyEcEFSMapperError: false
})


const mutations = {
    setSelectedElement(state, config) {
        state.selectedElement = config;
    },
    setSelectedElementsProps(state, { prop, value }) {
        // console.log("ElementBuilder", { prop, value })
        state.selectedElement[prop] = value;
        Vue.set(state.selectedElement, prop, value)
    },
    setParamstoInitial(state) {
        state.ecParams = {}
    },
    setDisplayNameBoolean(state, value){
        state.isDisplayName = value
    },
    setErrorText(state, payload = "") {
        state.errorTextTableHeader = payload;
    },
    setError(state, payload) {
        state.isDummyEcEFSMapperError = payload;
    }
}

const actions = {
    createElement: ({ commit, state, dispatch }, payload) => {
        return dispatch(
            'apiCall',
            {
                method: 'post',
                data: payload,
                url: constants.REPORT_ELEMENTS()
            },
            { root: true }
        )
    },
    updateElement: ({ commit, state, dispatch }, payload) => {
        return dispatch(
            'apiCall',
            {
                method: 'put',
                data: payload,
                url: constants.REPORT_ELEMENTS()
            },
            { root: true }
        )
    },


    setGraphConfiguration({ commit, state, dispatch }, payload) {
        delete payload.data;
        commit("setSelectedElementsProps", { prop: "configuration", value: payload });
        commit("ReportBuilder/setLocalVersionOfElementsData", {
            prop: "configuration",
            data: payload,
            _id: state.selectedElement._id
        }, { root: true })
    },

    getEcs: async ({ commit, state, dispatch }) => {
        try {
            let response = await dispatch("apiCall", {
                method: "get",
                url: constants.REPORT_ELEMENTS() + "ecs",
            }, { root: true })
            console.log(`[response]:= `, response);
            if (!response.ok) {
                throw new Error(response.message)
            }
            state.allEcs = response.result
            return response;
        } catch (error) {
            console.error(error);
            return []
        }
    },
    getExportData: async ({ commit, state, dispatch }, payload) => {
        try {
            let response = await dispatch("apiCall", {
                method: "get",
                url: constants.REPORT_ELEMENTS() + "exportdata",
                params: { ...payload }
            }, { root: true })
            console.log(`[response]:=`, response);
            if (response.info)
                state.tableData = response.info
            console.log(state.tableData)
            if (response.ECParams)
                state.ecParams = response.ECParams
            console.log(state.ecParams)
            if (!response.ok) {
                return []
            }
            return response;
        } catch (error) {
            console.error(error);
            return []
        }
    }
}

const getters = {
    selectedElement: (state) => state.selectedElement,
    ecParams: (state) => state.ecParams
}

export default {
    namespaced: true,
    state: initialState(),
    mutations,
    actions,
    getters
}