<template>
  <div class="bi-email-self-elem">
    <v-btn
      outlined
      class="email-btn"
      @click="email"
      :loading="loading"
      :disabled="loading"
      :title="chartConfig.title || 'Email'"
    >
      <span>
        <SvgIcon name="email_send" /> {{ chartConfig.title || "Email" }}
      </span>
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>mdi-cached</v-icon>
        </span>
      </template>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["chartConfig", "dataProvider", "getEmailDownload"],
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async email() {
      this.loading = true;
      let a = await this.getEmailDownload({
        ecName: this.chartConfig.ecName,
        as: `email_${this.chartConfig.emailType}`,
        title: this.chartConfig.title,
        mailBodyText: this.chartConfig.mailBodyText,
        cc: this.chartConfig.cc,
      });
      this.loading = false;
    },
  }
};
</script>

<style scoped>
.email-btn {
  width: 100%;
  justify-content: inherit;
  color: #0466d1;
  text-transform: none;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.email-btn span {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.email-btn svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
</style>