// const globby = require('globby');
// function _interopDefaultLegacy(e) { return e && typeof e === 'object' && 'default' in e ? e : { 'default': e }; }
// const globby__default = _interopDefaultLegacy(globby);

import context from "./plugins/kbi";
// import am4themes_dark from "@amcharts/amcharts4/themes/dark";
let toPascalCase = function (str) {
    return str
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w+)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, 'g'), '')
        .replace(new RegExp(/\w/), s => s.toUpperCase());
};

export default {
    install(vue, opts) {
        // console.log('--------------Installing the BI Elements-------------------',)
        const { components, __svg_html, installCtx } = opts;
        if (components) {
            if (Array.isArray(components)) {
                components.forEach(key => {
                    let str = key.replace(/\//g, " ").replace(/.vue/g, "");
                    // console.log(toPascalCase(str))
                    const file = key
                    vue.component(toPascalCase(str), () => import("./components/" + file))
                })
            } else {
                console.warn("[Warning]: ", "components should be array")
            }
        } else {
            let req = require.context('./components/', true, /\.(js|vue)$/i);
            req.keys().map(key => {
                let str = key.replace(/\//g, " ").replace(/.vue/g, "");
                // console.log(toPascalCase(str))
                const file = key.replace(/^./, "");
                return vue.component(toPascalCase(str), () => import("./components" + file))
            });
        }
        if (__svg_html) {
            vue.component("SvgIcon", () => import("./components/common/svg-icon-html"))
        } else {
            vue.component("SvgIcon", () => import("./components/common/svg-icon-image"))

        }

        vue.prototype.$Chelper = {
            clone: (data) => JSON.parse(JSON.stringify(data)),
            makeStringReadable: (str) =>
                str.replace(/[_]/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
            uniqueArrayObject: a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)),
            modifyMainNumber(mainNumber) {
                return mainNumber.toLocaleString("en-IN", {
                    maximumFractionDigits: 2
                });
            }
        }
        vue.prototype.$InstallCtx = async function (type, { theme, amchart }) {
            console.log("Installing BI context...",)
            if (type == "install") {
                vue.prototype.$biCtx = new context({ read: true, theme });
                if (!!amchart) {
                    vue.prototype.$am4cores = this.$am4core()
                    this.$biCtx.setAmchartTheme(this.$am4cores)
                }

            }
            // if(type)
        }

    }
}

/*
Assumptions in parent project

Vuetify @
Material Icons as font

insire parent store/index.js

    import authStore from '@common-ui/authenticator/authStore'

    //and do the following change

    new Vuex.Store({  ...,
        modules: { authStore, ... }
    }

*/