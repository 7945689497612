module.exports = {
    extractHash,
    
}

function extractHash(window) {
    var hash = window.location.hash;
    if(!hash) return {}
    if (hash.indexOf("#/") >= 0) {
        hash = hash.replace('/', '');
    }
    var queryString = hash.split('#')[1];
    var obj = {};
    var pairs = queryString.split('&');
    for (var i in pairs) {
        var split = pairs[i].split('=');
        obj[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }
    return obj;
}