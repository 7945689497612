const buildInfo = require('@common-ui/kx-sdk/getBuildInfo')();
import getAuthConfig from "@common-ui/authenticator/getAuthConfig";

let BASE_KX_UI = '';
let BASE_KX_URL = '';

let authConfig = getAuthConfig();
BASE_KX_URL = authConfig.sdkConf.KX_BASE;

let BASE_BX_URL = '';
let BASE_DX_URL = '';
// let BASE_EX_URL = 'http://localhost:1507';
let BASE_EX_URL = '';

const apibase = {
    getBuildInfo() {
        return buildInfo;
    },
    setPortalKeys(planet) {
        // console.log('planet', planet);
        if (planet.bx) {
            // if (planet.bx.includes('bx.stg.kennectweb.com')) {
            //     planet.bx = planet.bx.replace('https://bx.stg.kennectweb.com', 'http://localhost:1337');
            // }
            BASE_BX_URL = planet.bx;
            sessionStorage.setItem('BASE_BX_URL', BASE_BX_URL)
        }
        if (planet.dx) {
            BASE_DX_URL = planet.dx;
            sessionStorage.setItem('BASE_DX_URL', BASE_DX_URL)
        }

        if (planet.ex) {
            BASE_EX_URL = planet.ex;
            sessionStorage.setItem('BASE_EX_URL', BASE_EX_URL)
        }
    },
    setFromSessionStorage() {
        let bxUrl = sessionStorage.getItem('BASE_BX_URL')
        if (bxUrl) {
            // if (bxUrl.includes('bx.stg.kennectweb.com')) {
            //     bxUrl = bxUrl.replace('https://bx.stg.kennectweb.com', 'http://localhost:1337');
            // }
            BASE_BX_URL = bxUrl;
        }
        let dxUrl = sessionStorage.getItem('BASE_DX_URL')
        if (dxUrl) {
            BASE_DX_URL = dxUrl;
        }

        let exUrl = sessionStorage.getItem('BASE_EX_URL')
        if (exUrl) {
            BASE_EX_URL = exUrl;
        }
    },

    LOGOUT: BASE_KX_URL + '/common/v0.1/logout',

    BASE_KX_URL: () => BASE_KX_URL,
    BASE_BX_URL: () => BASE_BX_URL,
    BASE_EX_URL: () => BASE_EX_URL,
    BASE_DX_URL: () => BASE_DX_URL,
    BASE_KX_UI: () => BASE_KX_UI,

    BI_REPORT: () => BASE_EX_URL + '/BIReports/v0.1',
    REPORT_LAYOUTS: () => BASE_EX_URL + '/Layouts/v0.1',
    REPORT_ELEMENTS: () => BASE_EX_URL + '/elements/v0.1/',
    REPORT_SUPPLEMENTS: () => BASE_EX_URL + '/Supplements/v0.1',
}
export default apibase;
