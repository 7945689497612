<template>
  <div
    class="g-recaptcha"
    :data-sitekey="siteKey"
    data-callback="onReCaptchaSuccessCallback"
    data-size="invisible"
  ></div>
</template>

<script>
export default {
  props: ["showCaptcha"],
  data() {
    return {
      siteKey: "6LeDph8cAAAAACdmW_m4D2fT8PxpRaEtZ20gT1Hp",
    };
  },
  methods: {
    onReCaptchaSuccessCallback(response) {
      this.$emit("onReCaptchaSuccess", { ok: !!response, reCaptchaToken: response });
    },
    resetReCaptcha() {
      console.log("RESET CAPTCHA ", window.grecaptcha);
      if (window && window.grecaptcha) {
        window.grecaptcha.reset();
      }
    },
  },
  created() {
    // console.log("CREATED");
  },
  mounted() {
    window.onReCaptchaSuccessCallback = this.onReCaptchaSuccessCallback;
  },
};
</script>

<style>
</style>