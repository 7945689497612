<template>
  <div class="text-xs-center">
    <v-menu offset-y :close-on-content-click="false" nudge-left="260" v-model="menuOpen">
      <!-- <v-btn color="indigo" dark slot="activator">Menu as Popover</v-btn> -->
      <template v-slot:activator="{ on }">
        <!-- <v-btn v-on="on"> -->
        <v-btn icon v-on="on" medium>
          <v-icon style="color: white;" medium>fingerprint</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-icon x-large>account_circle</v-icon>
            <v-list-item-content>
              <!-- <v-list-tile-title>Omkar</v-list-tile-title> -->
              <v-list-item-title v-if="userData">{{userData.name}}</v-list-item-title>
              <span>{{ employerGID }}</span>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon :class="fav ? 'cyan--text' : ''" @click="fav = !fav">
                <!-- <v-icon>favorite</v-icon> -->
                <v-icon>signal_wifi_4_bar</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <portalsList />
        <v-divider></v-divider>

        <slot name="extra-menu"></slot>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="secondary" @click="menuOpen = false">Settings</v-btn> -->
          <v-btn color="secondary" @click="logout" :loading="isLogoutLoading">Log Out</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import portalsList from "./portalsList";
import axios from "axios";

import getAuthConfig from "../getAuthConfig"; //= getAuthConfig();
// const { uri } = getAuthConfig();

export default {
  components: { portalsList },
  props: [
    "accessToken",
    "refreshToken",
    "mortal",
    "keepOpen",
    "isLogoutLoading",
  ],
  data: () => ({
    fav: true,
    menuOpen: false,
  }),
  computed: {
    userData() {
      if (!this.$store.state.authStore.mortal) {
        return false;
      }
      return this.$store.state.authStore.mortal.userData;
    },
    employerGID() {
      if (!this.$store.state.authStore.mortal) {
        return false;
      }
      return this.$store.state.authStore.mortal.employerGID
    }
  },
  created() {
    if (this.keepOpen) {
      this.menuOpen = true;
    }
  },
  methods: {
    async logout() {
      try {
        this.$emit("logout"); // Handle Logout From Mini Profile logout on Kenx App
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
