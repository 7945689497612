<template>
  <!-- <v-tooltip bottom v-if="!!tooltipText">
    <template v-slot:activator="{ on, attrs }"> -->
      <component :is="iconComponent" />
    <!-- </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip> -->
  <!-- <component v-else :is="iconComponent" v-bind="attrs" v-on="on" /> -->
</template>

<script>
/**
 * References:
 *  1. https://vuejs.org/v2/guide/components.html#Dynamic-Components
 *  2. https://stackoverflow.com/questions/58080549/vue-js-import-components-dynamically
 */
export default {
  props: {
    name: String,
    tooltipText: String
  },
  data() {
    return {
      iconComponent: ''
    }
  },
  created() {
    this.iconComponent = () => import(`../../assets/icons/${this.name}.svg`)
  }
}
</script>
