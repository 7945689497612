let StorageUtils = {
    getItem: async (key) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (StorageUtils.isMobile()) {
                    NativeStorage.getItem(key, (success) => {
                        resolve(success);
                    }, (error) => {
                        if (error.code === 2) {
                            resolve(false);
                        } else {
                            reject(error);
                        }
                    })
                } else {
                    resolve(sessionStorage.getItem(key));
                }
            } catch (error) {
                reject(error);
            }
        });
    },
    removeItem: async (key) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (StorageUtils.isMobile()) {
                    NativeStorage.remove(key, (success) => {
                        resolve(success);
                    }, (error) => {
                        if (error.code === 2) {
                            resolve(false);
                        } else {
                            reject(error);
                        }
                    })
                } else {
                    resolve(sessionStorage.removeItem(key));
                }
            } catch (error) {
                reject(error);
            }
        });
    },
    setItem: async (key, value) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (StorageUtils.isMobile()) {
                    NativeStorage.setItem(key, value, (success) => {
                        resolve(success);
                    }, (error) => {
                        console.log('error', error);
                        reject(error);
                    });
                } else {
                    resolve(sessionStorage.setItem(key, value));
                }
            } catch (error) {
                reject(error);
            }
        });
    },
    isMobile() {
        return window.cordova && window.cordova.platformId !== "browser"
    },
    clearStorage() {
        return new Promise(async (resolve, reject) => {
            try {
                if (StorageUtils.isMobile()) {
                    NativeStorage.clear((success) => {
                        resolve(success);
                    }, (error) => {
                        if (error.code === 2) {
                            resolve(false);
                        } else {
                            reject(error);
                        }
                    })
                } else {
                    resolve(sessionStorage.clear());
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}

module.exports = StorageUtils; 