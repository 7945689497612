const portals = require('../kx-sdk/portals')
const { getSdkConf } = require('../kx-sdk/config')
const getAuthConfig = () => {
    let sdkConf = getSdkConf();
    let conf = {
        local: {
            LOG_TAG: 'kx-auth-local',
            AUTH_TAB_SHARE_TIMEOUT: 5000,
        },
        stg: {
            LOG_TAG: 'kx-auth-stg',
            AUTH_TAB_SHARE_TIMEOUT: 5000,
        },
        prod: {
            LOG_TAG: 'kx-auth-prod',
            AUTH_TAB_SHARE_TIMEOUT: 5000,
        },
        dev: {
            LOG_TAG: 'kx-auth-dev',
            AUTH_TAB_SHARE_TIMEOUT: 5000,
        },
    };
    let toReturn = conf[sdkConf.modeApplied];
    toReturn.portals = portals;
    toReturn.sdkConf = sdkConf;
    toReturn.uri = require('./api/uri')(sdkConf.KX_BASE)
    return toReturn;
}
module.exports = getAuthConfig;