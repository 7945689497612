import Vue from 'vue'
import Vuex from 'vuex'
import EventEmitter from "events";
import constants from '@/api'
import axios from '../api/axios'
import portal_keys_constants from '../api/index'

import layoutComposer from '../views/layout-composer/store.js'
import ReportBuilder from './ReportBuilder.js';
// import PreviewStore from './PreviewStore';
import PreviewStore from "../components/Preview/store.js"
// import NumbersConfig from '../elements-components/Numbers/NumbersConfigStore.js'
// import supplementStore from "./supplementStore.js"
import supplementStore from "../components/Supplements/store.js"
import ElmentBuilderStore from "../components/ElementBuilder/store.js"

Vue.use(Vuex)
const myEventEmitter = new EventEmitter();

function initialState(userType) {
  return {
    auth: false,
    authLoadType: 'normal',
    userType: userType,
    darkTheme: false,
    snackbarTime: 2000,
    snackbarText: '',
    refreshToken: '',
    mortal: {},
    privileges: [],
    userData: {},
    reportId: '',
    report: {},
    confirmDialog: {
      status: false,
      message: "",
      header: "",
    },
    loading: false,
    btnLoader: false,
    allElementsForImport: [],
    reportSupplements: []
  }
}

const store = new Vuex.Store({
  state: initialState,
  modules: {
    layoutComposer,
    ReportBuilder,
    // NumbersConfig,
    supplementStore,
    ElmentBuilderStore,
    PreviewStore
  },
  mutations: {
    setAuthLoadType: (state, loadType) => {
      state.authLoadType = loadType
    },
    openSnackbar: (state, txt) => {
      state.snackbarTime = 2000
      state.snackbarText = txt
    },
    openSnackbarWithTime: (state, { txt, time }) => {
      state.snackbarTime = time
      state.snackbarText = txt
    },
    login: (state, { token, userType, refreshToken, mortal, reportId }) => {
      state.auth = token ? token : true
      state.refreshToken = refreshToken
      state.userType = userType
      state.mortal = mortal
      state.reportId = reportId
      sessionStorage.setItem('auth', token)
      sessionStorage.setItem('reportId', reportId)
      sessionStorage.setItem('userType', userType)
      sessionStorage.setItem('refreshToken', refreshToken)
      sessionStorage.setItem('mortal', JSON.stringify(mortal))

      if (JSON.parse(userType) === 20 || JSON.parse(userType) === 24) {
        // TODO: handle privileges here later on
        // state.privileges = mortal.privileges;
        // store.commit("setL0ORL1Privileges");
      }
    },
    userData: (state, userData) => {
      state.userData = userData
      sessionStorage.setItem('userData', JSON.stringify(userData))
    },
    clearAuth: (state) => {
      sessionStorage.clear()
      state.auth = false
      state.userType = false
      state.userData = null,
        state.authLoadType = "normal"
    },
    logout: (state, requestType) => {
      // console.log('requestType [logout] :>> ', requestType);
      // console.log('sessionStorage.getItem(tabId) :>> ', sessionStorage.getItem('tabId'));
      store.commit('layoutComposer/resetState')

      const iState = initialState(false)
      for (let key in iState) {
        state[key] = iState[key]
      }
      if (requestType && requestType.request === 'logoutReqFromSelf') {
        try {
          let tabId = sessionStorage.getItem('tabId')
          store.commit('clearAuth')
          // Send logout request to parent to logout itself and all child windows opened
          if (window.opener) {
            window.opener.postMessage(
              {
                request: 'logoutReqFromChild',
                origin: window.location.origin,
                tabId
              },
              authByPopUpUtils.KX_UI
            )
          }
        } catch (error) {
          console.log('error: ', error)
          console.log('FAILED TO SEND MSG TO PARENT WINDOW')
        }
      } else {
        store.commit('clearAuth')
      }
    },
    setReport(state, report) {
      state.report = report
    },
    openConfirmDialog(state, { message, header }) {
      state.confirmDialog.status = true;
      state.confirmDialog.header = header || "Confirmation";
      state.confirmDialog.message = message || "Are you sure?";
    },
    closeConfirmDialog(state, { confirmationStatus }) {
      myEventEmitter.emit("confirmationStatus", confirmationStatus);
      state.confirmDialog.status = false;
      state.confirmDialog.header = "";
      state.confirmDialog.message = "";
    },
    toggleLoader(state) {
      state.loading = !state.loading
    },
    loader(state, data) {
      state.loading = !!data;
    },
    toggleBtnLoader(state) {
      state.btnLoader = !state.btnLoader
    },
    setAllElementsForImport(state, payload = []) {
      state.allElementsForImport = payload
    }
  },
  actions: {
    apiCall({ commit, state }, partConfig = {}) {
      // console.log('partConfig :>> ', partConfig);
      // const randomId =  Math.floor(Math.random() * 11)
      // return { ok: true, element: { _id: !partConfig.data.hasOwnProperty("id") ? randomId  : partConfig.data.id, "name": "Test element" + (!partConfig.data.hasOwnProperty("id") ? randomId : partConfig.data.id) }, msg: "sdfsdf", partConfig}
      return new Promise((resolve, reject) => {
        // console.log('state.auth :>> ', state.auth);
        // if (!state.auth) {
        //   throw 'no auth token found'
        // }
        const temp = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoyMCwib3duZXIiOiJhZG1pbiIsImlkIjoiNWY2MWI3MGU3YzJmMzkxNWEwNTgwYzI2IiwidXNlcm5hbWUiOiJwaGFudG9tIiwidXNlckRhdGEiOnsibmFtZSI6IlBoYW50b20iLCJlbWFpbCI6InBoYW50b21AdGVzdC5jb20ifSwidGVuYW50IjoiNWY2MWI3MGU3YzJmMzkxNWEwNTgwYzI2IiwiZG9tYWluIjoicGhhbnRvbSIsInBvcnRhbHMiOlsiZHgiLCJieCIsImRzaSIsImluY2VudGl2ZSIsImJpIl0sInBvcnRhbEtleXMiOnsiYngiOiJodHRwOi8vbG9jYWxob3N0OjEzMzciLCJkeCI6Imh0dHA6Ly9sb2NhbGhvc3Q6MTQwMSJ9LCJzeW5jaHJvbml6YXRpb25Db25maWd1cmF0aW9uIjp7ImFjdGl2ZSI6ZmFsc2V9LCJwcml2aWxlZ2VzIjp7ImpvdXJuZXkiOnRydWUsInJlcG9ydCI6dHJ1ZSwiaW1wb3J0X2NvbmZpZyI6dHJ1ZSwibWRfY2QiOnRydWUsImxiaSI6dHJ1ZSwic3VydmV5X2FkdmFuY2VfbXVsdGlfZW50cnkiOnRydWUsInN1cnZleV9lZGl0YWJsZV9zaW5nbGVfZW50cnkiOnRydWUsInF1aXpfYXBwIjp0cnVlLCJjdXN0b21fYXBwIjp0cnVlLCJkZWxpdmVyeV9jb25maWciOnRydWUsIm1hbmFnZV91c2VyX2NyZWRlbnRpYWxzX2Zvcl8yMCI6dHJ1ZSwibWFuYWdlX3VzZXJfY3JlZGVudGlhbHNfZm9yXzI0Ijp0cnVlLCJhcGlfYWNjZXNzIjp0cnVlLCJtYW5hZ2VfdXNlcnMiOnRydWUsInJlYWRfdXNlcnMiOnRydWUsIm1hbmFnZV9ncm91cHMiOnRydWUsInJlYWRfZ3JvdXBzIjp0cnVlLCJtYW5hZ2VfYXBwX2Nvc21ldGljcyI6dHJ1ZSwibWFuYWdlX25zcGVjcyI6dHJ1ZSwicmVhZF9uc3BlY3MiOnRydWUsImFubm91Y2VtZW50Ijp0cnVlLCJxbXMiOnRydWUsImZpbGVzX3ZhdWx0Ijp0cnVlLCJ3aWRnZXQiOnRydWUsIm5vdGlmaWNhdGlvbiI6dHJ1ZSwia2VueF9ub3RpZmljYXRpb24iOnRydWUsInNjaGVtZV9idWlsZGVyIjp0cnVlLCJiaSI6dHJ1ZSwiZHZfZWMiOnRydWUsImFsbG93X2xheW91dF9idWlsZGVyIjp0cnVlfSwiaWF0IjoxNjIxMDYyMjI5LCJleHAiOjE2MjUzODIyMjl9.WMFPRoH1JADGkSvpRGyvr0pgwyujDPQINd702ZYa2TbDCUgfLTuFTD8njkvLb5epYrIqWmQ7HOjeKug5UZNkPzbWhyK5tdT6egKze6mcGuyxA3N41l9hf4wLv6agKDFogZZE8U-Bd5usgjto8icL3jqM9Z8LKpN7E7RkemkQvfw41445hQzd22hqXAp4XnPbCmi3uJAcSaqhukAQVs2m_Z-zYmKLq7cUmXL36tKEfPKFQ2mpFBpARLQU1bLQ9IutWb9zzRNSZQ5RAE8W1AJnQYMGhDhIy670qp_F2R3uMpP7tSMN4JJn2dlnTIPEOly9PIMaElChYF02hj0QWQH_3g"
        axios({
          ...partConfig,
          headers: {
            Authorization: state.auth
          }
        })
          .then((response) => {
            let data = response.data
            resolve(data)
          })
          .catch((err) => {
            console.log('[HTTP API Request Error]', err)
            if (err.message == 'Network Error') {
              commit('openSnackbar', 'Network Error')
            }
            reject({ ok: false, message: 'Error connecting to KBET' })
          })
      })
    },
    apiCallWithHeaders({ commit, state }, { partConfig = {}, headers }) {
      return new Promise((resolve, reject) => {
        if (!state.auth) {
          throw 'no auth token found'
        }
        axios({
          ...partConfig,
          headers: {
            Authorization: state.auth,
            ...headers
          }
        })
          .then((response) => {
            let data = response.data
            resolve(data)
          })
          .catch((err) => {
            console.log('[HTTP API Request Error]', err)
            if (err.message == 'Network Error') {
              commit('openSnackbar', 'Network Error')
            }
            reject({ ok: false, message: 'Error connecting to KBET' })
          })
      })
    },
    setAuth({ commit }, payload) {
      window.allDSIChilds = []
      sessionStorage.setItem('tabId', payload.tabId)
      commit('login', {
        token: payload.accessToken,
        userType: payload.mortal.type,
        refreshToken: payload.refreshToken,
        mortal: payload.mortal,
        reportId: payload.reportId
      })
      if (payload.mortal.userData) {
        commit('userData', payload.mortal.userData)
      }
      if (payload.mortal.portalKeys) {
        portal_keys_constants.setPortalKeys(payload.mortal.portalKeys)
      }
    },
    setAuthFromSessionStorage: ({ commit }) => {
      let token = sessionStorage.getItem('auth')
      let userType = sessionStorage.getItem('userType')
      let userData = sessionStorage.getItem('userData')
      let refreshToken = sessionStorage.getItem('refreshToken')
      let reportId = sessionStorage.getItem('reportId')
      let mortal = JSON.parse(sessionStorage.getItem('mortal')) || []
      console.log('session-storage ', {
        token,
        userType,
        userData,
        refreshToken,
        mortal,
        reportId
      })
      if (!!token) {
        userType = Number(userType) // Note: sessionStorage always store data in string
        commit('login', {
          token,
          userType,
          refreshToken,
          mortal,
          reportId
        })
      } else {
        commit('logout')
        return
      }
      if (!!userData) {
        portal_keys_constants.setFromSessionStorage()
        commit('userData', JSON.parse(userData))
      } else {
        console.log('user-data not present in session-storage')
      }
    },
    logout: ({ commit, state }) => {
      try {
        let url = constants.LOGOUT
        let result = axios({
          url,
          method: 'post',
          headers: {
            Authorization: state.auth,
            'refresh-token': state.refreshToken
          }
        })
        result
          .then((resp) => {
            console.log('resp :', resp)
            commit('logout', { request: 'logoutReqFromSelf' })
          })
          .catch((err) => {
            console.log('err :', err)
            commit('logout', { request: 'logoutReqFromSelf' })
          })
      } catch (error) {
        console.log('error :', error)
      }
    },
    getReportDetails: ({ commit, state, dispatch }, payload) => {
      return dispatch('apiCall', {
        method: 'get',
        params: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/report/'
      })
    },
    getAllElements: ({ commit, state, dispatch }, payload) => {
      return dispatch('apiCall', {
        method: 'get',
        params: payload,
        url: constants.BASE_EX_URL() + '/Elements/v0.1/'
      })
    },
    openConfirmDialog({ commit }, { message, header }) {
      return new Promise((resolve) => {
        commit("openConfirmDialog", {
          message,
          header,
        });
        myEventEmitter.once("confirmationStatus", (confirmationStatus) => {
          if (confirmationStatus === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    async createReport({ commit, dispatch, state }, payload) {
      try {
        let res = await dispatch('apiCall', {
          method: 'post',
          data: payload,
          url: constants.BASE_EX_URL() + '/BIReports/v0.1/'
        })
        console.log(`[res]: `, res);
        if (res.ok) {
          state.reportId = res.result && res.result._id;
          let reportDetails = await dispatch("getReportDetails", {
            reportId: state.reportId
          });
          if (reportDetails.ok) {
            await dispatch("setReportConfig", reportDetails.result)
          } else {
            await dispatch("setReportConfig", res.result)
          }
          return true;
        } else {
          return false
        }
      } catch (error) {
        console.log(error);
        return false
      }
    },

    setReportConfig({ commit, dispatch }, payload) {
      commit("setReport", payload);
      commit("ReportBuilder/setAllLayoutsData", {
        mode: 'listAllLayouts',
        payload: payload
      }, { root: true });
      let res = dispatch("ReportBuilder/getAllElementsOfACurrentLayout", {}, { root: true });
      console.log('res :>> ', res);
      if (res.ok) {
        commit("ReportBuilder/setAllFetchedElementsOfACurrentLayout", res.result, { root: true })
        commit("ReportBuilder/mapAllElementsOfACurrentLayout", res.result, { root: true })

      }
    },
    async setReportSupplement({ state, dispatch }) {
      try {
        let reportSupplementsLocal = Array.from(new Set([...(state.report.supplements || []), ...(state.report.initial_default_params || [])]));
        state.reportSupplements = [];
        if (reportSupplementsLocal.length) {
          let supplementRes = await dispatch("supplementStore/getSupplementsByIds", reportSupplementsLocal, { root: true });
          state.reportSupplements = supplementRes.data || []
        }
      } catch (error) {
        console.error("SET REPORT SUPPLEMEBT", error)
      }
    },
    getExport: async ({ dispatch }, payload) => {
      try {
        let response = await dispatch("apiCall", {
          method: "get",
          url: constants.REPORT_ELEMENTS() + "exportdata",
          params: { ...payload }
        });
        // console.log(`[ecData]:=`, response);
        return response
      } catch (error) {
        console.error(error);
        return { ok: true, info: [] }
      }
    }
  },
  getters: {
    auth: (state) => !!state.auth,
    userType: (state) => state.userType,
    authToken: (state) => state.auth,
    darkTheme: (state) => state.darkTheme
  },
})

export default store
