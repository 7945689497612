<template>
  <div style="height: 100%">
    <v-card class="mn-card content-view-port" elevation="0">
      <!-- style="border-radius: 15px !important" -->
      <v-container
        grid-list-md
        text-xs-center
        fluid
        style="padding: unset"
        class="content-view-port"
      >
        <v-layout dense wrap :class="alignDataCenter" class="content-view-port">
          <template v-for="(item, index) in config.main_number_config">
            <v-flex
              style="
                text-align-last: lef;
                overflow: hidden;
                padding: 4px 10px;
                margin: auto;
              "
              :key="index"
            >
              <!-- <span class="vertical"></span> -->
              <!-- <v-list-item two-line :dark="false" style="height: 100%"> -->
              <!-- <div class="mx-auto px-auto"> -->
              <!-- <div> -->
              <v-card
                class="mx-auto multiple-number-card-outline"
                outlined
                style="background-color: rgb(255, 255, 255)"
              >
                <v-list-item-content class="main-number-content">
                  <v-list-item-subtitle class="main-number-title">{{
                    titleCase(item.title)
                  }}</v-list-item-subtitle>
                  <v-list-item-title class="main-number-values"
                    >{{ item.prefix }}
                    {{
                      data[item.mainNumberField] &&
                      data[item.mainNumberField].toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })
                    }}
                    {{ item.sufix }}</v-list-item-title
                  >
                </v-list-item-content>
                <!-- </div> -->
                <!-- </div> -->
              </v-card>
              <!-- </v-list-item> -->
            </v-flex>
          </template>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["chartConfig", "dataProvider"],
  data() {
    return {
      config: { main_number_config: [] },
      data: {},
    };
  },
  methods: {
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
  },
  computed: {
    showVerticalClass() {
      return Object.keys(this.config.main_number_config).length > 1
        ? "vertical"
        : "";
    },
    alignDataCenter() {
      return Object.keys(this.config.main_number_config).length == 1 ||
        Object.keys(this.config.main_number_config).length == 2
        ? "alignDataCenter"
        : "";
    },
  },
  created() {
    this.config = JSON.parse(
      JSON.stringify(this.chartConfig || { main_number_config: [] })
    );
    this.data = (this.dataProvider && this.dataProvider[0]) || {};
  },
};
</script>

<style scoped>
.alignDataCenter {
  justify-content: space-evenly;
  align-items: center;
}
/* .vertical {
  border-left: 1px solid #cdcdcd;
  height: 55px;
  position: absolute;
  left: 50%;
} */
.main-number-values {
  margin-top: 5px;
  font-size: 1.4em;
  font-weight: 600;
  color: #1e417f;
}
.mn-card {
  background-color: transparent;
}
.vertical {
  border-left: 1px solid #cdcdcd;
  height: 80px;
  position: absolute;
  left: 50%;
}
.multiple-number-card-outline {
  border-radius: 10px;
  padding: 1rem;
}
.main-number-content {
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0;
  padding-left: 5px;
}

.main-number-title {
  font-size: 1rem;
  margin-bottom: 6px;
  margin-bottom: 6px;
  color: #a5aebd !important;
  /* font-weight: 500; */
}
.theme--light.v-sheet--outlined {
  border: thin solid #dee1e2;
  /* box-shadow: rgb(65 65 65 / 2%) 0px 1px 3px 0px,
    rgb(33 37 41 / 15%) 0px 0px 0px 1px; */
}

@media only screen and (max-width: 650px) {
  .main-number-values {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 520px) and (min-width: 350px) {
  .main-number-values {
    font-size: 1rem !important;
  }
  .main-number-title {
    font-size: 1rem;
  }
}
</style>
