/* NPM MODULES */
import Vue from 'vue'

/* PROJECT COMPONENTS */
import BaseUI from './BaseUI.vue'
import BaseImage from './BaseImage.vue'
import UiButton from './UiButton.vue'
import UiExpansionPanel from './UiExpansionPanel.vue'

const components = {
  'base-ui': BaseUI,
  'base-image': BaseImage,
  'ui-button': UiButton,
  'ui-expansion-panel': UiExpansionPanel,
}

Object.keys(components).forEach((name) => Vue.component(name, components[name]))
