import * as Survey from 'survey-vue'

export default function () {
    var widget = {
        //the widget name. It should be unique and written in lowcase.
        name: "prioritycheckbox",
        //the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
        title: "Text with button",
        //the name of the icon on the toolbox. We will leave it empty to use the standard one
        iconName: "",
        //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
        widgetIsLoaded: function () {
            //return typeof $ == "function" && !!$.fn.select2; //return true if jQuery and select2 widget are loaded on the page
            return true; //we do not require anything so we just return true. 
        },
        //SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
        isFit: function (question) {
            //we return true if the type of question is prioritycheckbox
            return question.getType() == "checkbox" && question["renderAs"] == 'prioritycheckbox';
            //the following code will activate the widget for a text question with inputType equals to date
            //return question.getType() === 'text' && question.inputType === "date";
        },
        // init() {
        //     //Add a boolean property hasSearch into comment question.
        //     //Use switch property editor for it.
        //     //you can have a simple check property editor by changing ":switch" to ":boolean"
        //     //Add it to general category
        //     Survey.Serializer.addProperty("question", {
        //         name: "choices",
        //         //default: true, //uncomment this line to make this property true, search functionality is on, by default.
        //         category: "general",
        //     });
        // },

        isDefaultRender: true,
        //Use this function to create a new class or add new properties or remove unneeded properties from your widget
        //activatedBy tells how your widget has been activated by: property, type or customType
        //property - it means that it will activated if a property of the existing question type is set to particular value, for example inputType = "date" 
        //type - you are changing the behaviour of entire question type. For example render radiogroup question differently, have a fancy radio buttons
        //customType - you are creating a new type, like in our example "prioritycheckbox"
        activatedByChanged: function (activatedBy) {
            //we do not need to check acticatedBy parameter, since we will use our widget for customType only
            //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
            Survey.JsonObject.metaData.addClass("prioritycheckbox", [], null, "text");

            Survey.JsonObject.metaData.addProperties("prioritycheckbox", [{ name: "choices", default: [] }]);

            //signaturepad is derived from "empty" class - basic question class
            //Survey.JsonObject.metaData.addClass("signaturepad", [], null, "empty");

            //Add new property(s)
            //For more information go to https://surveyjs.io/Examples/Builder/?id=addproperties#content-docs
        },
        //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
        //You should use it if your set the isDefaultRender to false
        // htmlTemplate: `<input />`,
        //The main function, rendering and two-way binding

        afterRender: function (question, el) {
            let choicesByOrder = question.choicesOrder;
            // question.choicesOrder = '',
            // console.log('question', question);

            question.valueChangedCallback = function () {
                // console.log("Question Value Changed programatically ", question.value);

                if(question.value.length){
                    question.choicesOrder = '';
                }else{
                    question.choicesOrder = choicesByOrder;
                }

                let selectedItems = question.value.map(mp => question.choices.find(f => f.value == mp)); // [1,2]
    
                let choices = question.choices;

                let unselected = choices.filter(
                    (fl) => !question.value.includes(fl.value)
                );

                let newChoicesValue = selectedItems.concat(unselected);

                question.choices = newChoicesValue;
            }
            question.valueChangedCallback();
        },
        //Use it to destroy the widget. It is typically needed by jQuery widgets
        willUnmount: function (question, el) {
            //We do not need to clear anything in our simple example
            //Here is the example to destroy the image picker
            //var $el = $(el).find("select");
            //$el.data('picker').destroy();
        }
    }

    //Register our widget in singleton custom widget collection

    Survey.JsonObject.metaData.addProperty("checkbox", { name: 'renderAs', default: 'standard', choices: ['standard', 'prioritycheckbox'] });
    Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");

}