import constants from '../api/index'
import { cloneDeep, isEqual } from 'lodash'
import Vue from 'vue'
const MAX_STATE_HISTORY = 50
import { v4 as uuidv4 } from 'uuid';

const initialState = () => ({
  // layout: [{"x":0,"y":0,"w":12,"h":6,"i":0,"elements":[{"size":2},{"size":4},{"size":2}],"moved":false, props: { direction: "vertical", type: "simple" }}],
  layout: [],
  beforeEditLayoutState: [],
  previousLayoutStates: [],
  undoLayoutStates: [],
  selectedContainerIValue: -1,
  selectedContainerElementIndex: -1,
  // allElementsInAReport: {},
  isRedoAllowed: false,
  isUndoAllow: false,
  // allElementsSavedInDb: {},
  selectedElementData: {},
  // allElementsOfAReportFetchedFromDB: {},
  // elementsRefs: {},
  /*
  allElementsInReport: {
    "containerIndex": {
      "element1": {},
      "element2": {},
      "element3": {},
    }
  }
  */
  containerDirection: "",
  containerAlignmentType: "",
  allAvailableLayoutsData: [],
  currentLayoutData: {},
  layoutName: "",
  // layoutIdWiseElementMapping: {},
  // selectedElementDataSource: {},
  // mappedDataSourceHeaders: {},
  currentLayoutsElementRefs: [],
  // currentLayoutsElements: {},
  allElementsOfACurrentLayoutInObj: {},
  predifinedLayoutsDialog: false,
  localVersionOfElementsData: {},
  inContainerElementPreview: false,
  layoutSelection: false,
  currentTab: 0,
  isLayoutSave: true,
  isAllElemsAndCurrentLayoutSaved: true,
  inBoundParams: [],
  selectedPreviewLayout: false,
  selectedElementComponentPreview: {
    visibility: false,
  },
  drillUpdateInfo: {
    removed: [],
    attached: []
  }
  // inBoundParams: [{displayName: "Financial Year", name: "FY"}, {displayName: "State", name: "State"}, {displayName: "Headquarter", name: "HQ"}]

})

const onNewLayoutSection = (state, layout) => {
  state.selectedContainerIValue = -1;
  state.selectedElementData = {};
  state.inBoundParams = [];
  // state.localVersionOfElementsData = {};
  state.inContainerElementPreview = false;
  state.isRedoAllowed = false;
  state.isUndoAllow = false;
  state.undoLayoutStates = [];
  state.previousLayoutStates = [];
  state.beforeEditLayoutState = cloneDeep(layout);
  // state.previousLayoutStates.unshift(cloneDeep(layout));
}

const getters = {
  selectedContainerIndex(state) {
    return state.layout.findIndex((l) => l.i === state.selectedContainerIValue)
  },
  localVersionOfElementsDataGetter: (state) => state.localVersionOfElementsData,
  selectedElementComponentPreview: (state) => state.selectedElementComponentPreview

}

const mutations = {
  resetDrillUpdateInfo(state){
    state.drillUpdateInfo = {removed: [], attached: []}
  },
  // setLayout(state, layout) {
  //   state.layout = layout
  //   state.isRedoAllowed = false
  //   state.undoLayoutStates = []
  //   state.beforeEditLayoutState = cloneDeep(layout)
  //   state.previousLayoutStates.unshift(cloneDeep(layout))
  //   // state.selectedContainerIValue = -1
  // },
  setPreviousLayoutState(state, currentLayout) {
    console.log(`[currentLayout]: `, currentLayout);
    console.log(`[ state.previousLayoutStates[0]]: `, state.previousLayoutStates[0]);
    console.log("--------------", isEqual(currentLayout, state.previousLayoutStates[0]))
    if (
      currentLayout &&
      currentLayout.length !== 0 &&
      !isEqual(currentLayout, state.previousLayoutStates[0])
    ) {
      state.previousLayoutStates.unshift(currentLayout)
      if (state.previousLayoutStates.length > MAX_STATE_HISTORY) {
        state.previousLayoutStates = state.previousLayoutStates.slice(
          0,
          MAX_STATE_HISTORY
        )
      }
      state.isUndoAllow = true
    }
  },

  // how undo and redo work
  /**
   * initialy: undostack: [], redostack: [], original: [123456]
   * if any change it push to undostack
   * let say we changes 2 time some now undo will allow by ui
   * undostack: [123456, 1234568, 124567], redostack: [], original: [124567]
   * 
   * if 1 changes changes 
   * 
   * undostack: [123456], redostack: [], original: [1234568]
   * undostack: [], redostack: [1234568], original: [123456]
   * 
   * IF UNDO
   *  1st
   * undostack: [123456, 1234568], redostack: [], original: [124567]
   * 
   *  2nd
   * undostack: [123456], redostack: [1234568], original: [1234568]
   * 
   *  3nd
   * undostack: [], redostack: [1234568, 123456], original: [123456]
   * 
   * 
   * IF REDO
   * undostack: [], redostack: [124567, 1234568, 123456], original: [123456]
   * 
   * 1st
   * undostack: [123456], redostack: [124567, 1234568], original: [123456]
   * 
   * 2nd
   * undostack: [123456, 1234568], redostack: [124567], original: [1234568]
   * 
   * 3rd
   * undostack: [123456, 1234568, 124567], redostack: [124567], original: [124567]
   */
  undoLayoutState(state) {

    const previousLayout = cloneDeep(state.layout)
    if (state.previousLayoutStates.length) {
      state.isRedoAllowed = true;
      let lastState = cloneDeep(state.previousLayoutStates.shift())
      state.layout = lastState
      state.undoLayoutStates.push(previousLayout);
      state.isAllElemsAndCurrentLayoutSaved = false
    }
    if (!state.previousLayoutStates.length) {
      state.isUndoAllow = false
    } else {
      state.isUndoAllow = true;
    }
    state.selectedContainerIValue = -1
    // console.log(`[state.previousLayoutStates]: `, state.previousLayoutStates);
    // console.log(`[ state.undoLayoutStates]: `, state.undoLayoutStates);

  },
  redoLayoutState(state) {
    if (state.undoLayoutStates.length) {
      const previousLayout = cloneDeep(state.layout)
      state.layout = cloneDeep(state.undoLayoutStates.pop())
      state.selectedContainerIValue = -1
      state.previousLayoutStates.push(previousLayout)
      state.isUndoAllow = true;
      state.isAllElemsAndCurrentLayoutSaved = false;
    }
    if (!state.undoLayoutStates.length) {
      state.isRedoAllowed = false
    } else {
      state.isRedoAllowed = true;
    }
    // console.log(`[state.previousLayoutStates]: `, state.previousLayoutStates);
    // console.log(`[ state.undoLayoutStates]: `, state.undoLayoutStates);
  },
  setSelectedContainerIValue(state, selectedContainerIValue) {
    state.selectedContainerIValue =
      state.selectedContainerIValue === selectedContainerIValue
        ? -1
        : selectedContainerIValue
    const selectedContainerData = state.layout[state.selectedContainerIValue]
    if (selectedContainerData && Object.keys(selectedContainerData).length) {
      state.containerDirection = selectedContainerData.props && selectedContainerData.props.direction ? selectedContainerData.props.direction : ""
      state.containerAlignmentType = selectedContainerData.props && selectedContainerData.props.type ? selectedContainerData.props.type : ""
    }
    if (selectedContainerData && (!selectedContainerData.elements || !selectedContainerData.elements.length)) {
      state.selectedContainerElementIndex = -1
    }
    state.selectedElementData = {}
  },
  setSelectedElementIndex(state, { elementIndex, containerIValue, elemLinkTo }) {
    state.selectedContainerIValue = containerIValue
    state.selectedContainerElementIndex = state.selectedContainerElementIndex === elementIndex ? -1 : elementIndex

    console.log({ elementIndex, containerIValue, elemLinkTo });

    // if (!state.localVersionOfElementsData[elemLinkTo]) {
    //   state.isAllElemsAndCurrentLayoutSaved = false;
    //   state.isLayoutSave = false;
    // }
    console.log(`[localVersionOfElementsData]: `, state.localVersionOfElementsData);
    state.selectedElementData = state.localVersionOfElementsData[elemLinkTo] || { _id: elemLinkTo, isNewElem: true, isSaved: false }

    // // To locally set drill because we do not store drill in element obj instead we store inside container's element obj, so that's why we do state.selectedElementData["drill"] = ... in order to patch data locally 
    state.selectedElementData["drill"] = state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex] && state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] ? state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] : {}

    console.log('state.selectedElementData :>> ', state.selectedElementData);

    // if(state.selectedContainerIValue == -1 || state.selectedContainerElementIndex == -1){
    //   return
    // }

    // state.selectedElementData = state.allElementsOfACurrentLayoutInObj[elemLinkTo] && Object.keys(state.allElementsOfACurrentLayoutInObj[elemLinkTo]).length ? state.allElementsOfACurrentLayoutInObj[elemLinkTo] : {}
    // // To locally set drill because we do not store drill in element obj instead we store inside container's element obj, so that's why we do state.selectedElementData["drill"] = ... in order to patch data locally 
    // state.selectedElementData["drill"] = state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex] && state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] ? state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] : {}

    // console.log('---------------------------------------------------------------------')
    // console.log(' elementIndex, containerIValue, elemLinkTo :>> ',  elementIndex, containerIValue, elemLinkTo);

    // const key = state.selectedContainerIValue + "_" + state.selectedContainerElementIndex
    // if(!Object.keys(state.selectedElementData).length){
    //   state.selectedElementData = state.localVersionOfElementsData[key] ? state.localVersionOfElementsData[key] : {}
    // }else{
    //   state.selectedElementData = {...state.selectedElementData, ...state.localVersionOfElementsData[key]}
    // }

    // console.log('selectedElementData :>> ', state.selectedElementData)
    // console.log('---------------------------------------------------------------------')
  },
  resetSelectedElementIdx(state) {
    state.selectedContainerElementIndex = -1;
    state.selectedContainerIValue = -1;
  },
  updateElementDataInAllElementsInAReport(state, { element, selectedElementDataIndex, containerIValue }) {
    state.selectedContainerIValue = containerIValue
    // state.selectedContainerElementIndex = state.selectedContainerElementIndex === selectedElementDataIndex ? -1 : selectedElementDataIndex
    console.log({ selectedContainerElementIndex: state.selectedContainerElementIndex, selectedContainerIValue: state.selectedContainerIValue });
    state.selectedElementData = element
    try {
      state.allElementsOfACurrentLayoutInObj[element._id] = element
      state.localVersionOfElementsData[element._id] = { ...element, isNewElem: false, isSaved: true }
      state.isAllElemsAndCurrentLayoutSaved = true && state.isLayoutSave
    } catch (error) {
      console.error(error)
    }
  },
  setAllFetchedElementsOfACurrentLayout(state, elements = []) {
    state.allElementsOfACurrentLayoutInObj = {}
    elements.forEach((el) => {
      delete el.record
      state.allElementsOfACurrentLayoutInObj[el._id] = el
      state.localVersionOfElementsData[el._id] = { ...el, isSaved: true, isNewElem: false }
    })
    // console.log(`[true && state.isLayoutSave]: `, true && state.isLayoutSave, state.isLayoutSave);
    // state.isAllElemsAndCurrentLayoutSaved = true && state.isLayoutSave
  },
  mapAllElementsOfACurrentLayout(state) {
    return
    state.containerDirection = ""
    state.containerAlignmentType = ""
    state.selectedContainerIValue = -1
  },
  updateContainerDirection(state, data) {
    state.containerDirection = data
    Vue.set(state.layout[state.selectedContainerIValue], "props", { ...state.layout[state.selectedContainerIValue]["props"], direction: data })
  },
  updateContainerAlignmentType(state, data) {
    Vue.set(state.layout[state.selectedContainerIValue], "props", { ...state.layout[state.selectedContainerIValue]["props"], type: data })
    state.containerAlignmentType = data
  },
  updateElemDataInsideContainerArraysElem(state, { element, selectedContainerIndex, selectedElementIndex }) {
    console.log({ selectedContainerIndex, selectedElementIndex });
    console.log('state.layout[selectedContainerIndex]["elements"] :>> ', state.layout[selectedContainerIndex]["elements"]);
    Vue.set(state.layout[selectedContainerIndex]["elements"][selectedElementIndex], "elemLinkTo", element._id)
    Vue.set(state.layout[selectedContainerIndex]["elements"][selectedElementIndex], "name", element.name)
    Vue.set(state.layout[selectedContainerIndex]["elements"][selectedElementIndex], "drill", element.drill || {})
    state.localVersionOfElementsData[element._id]["isSaved"] = true
    state.isAllElemsAndCurrentLayoutSaved = true
    state.localVersionOfElementsData[element._id]["isNewElem"] = false
    if (!state.currentLayoutsElementRefs.includes(element._id)) {
      state.currentLayoutsElementRefs.push(element._id)
    }
  },
  updateElementInContainer(state, { element, selectedContainerIndex, selectedElementIndex }) {
    console.log({ selectedContainerIndex, selectedElementIndex });
    Vue.set(state.layout[selectedContainerIndex]["elements"][selectedElementIndex], "elemLinkTo", element._id)
    Vue.set(state.layout[selectedContainerIndex]["elements"][selectedElementIndex], "name", element.name)
    Vue.set(state.layout[selectedContainerIndex]["elements"][selectedElementIndex], "drill", element.drill || {})
  },
  updateDrillInSelectedContaineElem(state, { drill }) {
    Vue.set(state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex], "drill", drill || {})
    state.isAllElemsAndCurrentLayoutSaved = false;
  },
  setAllLayoutsData(state, { mode = "", payload = {}, devicesPrior }) {
    console.log('mode, payload [setAllLayoutsData]:>> ', mode, state.isAllElemsAndCurrentLayoutSaved);
    // delete && rootLayout
    /***********listAllLayouts***************************************/
    if (mode === "listAllLayouts") {
      /**
       * @isAllElemsAndCurrentLayoutSaved
       * if false then it should not discard current change
       * if current layout is delete then it should selected root layout or zero layout
       * 
       */
      console.log('state.currentLayoutData :>> ', cloneDeep(state.currentLayoutData));
      let isCurrentLayoutIsEditing = false
      if (state.currentLayoutData && state.currentLayoutData.identity) {
        isCurrentLayoutIsEditing = !state.isAllElemsAndCurrentLayoutSaved
      }
      console.log('isCurrentLayoutIsEditing :>> ', isCurrentLayoutIsEditing);
      let rootLayout = {}
      if (payload.layouts) {
        const allLayoutsTemp = []
        Object.keys(payload.layouts).forEach((l) => {
          allLayoutsTemp.push(payload.layouts[l])
          if (payload.layouts[l] && payload.layouts[l].props && !!payload.layouts[l].props.isRoot) {
            rootLayout = payload.layouts[l]
          }
        })
        state.allAvailableLayoutsData = allLayoutsTemp
      }

      let currentLayoutData = {};
      if (payload && payload.layouts && Object.keys(payload.layouts).length) {
        if (isCurrentLayoutIsEditing && payload.layouts[state.currentLayoutData.identity]) {

          currentLayoutData = Object.assign(state.currentLayoutData, payload.layouts[state.currentLayoutData.identity]);

        } else if (Object.keys(rootLayout).length) {
          currentLayoutData = rootLayout
          state.layout = currentLayoutData.structure && currentLayoutData.structure.containers ? currentLayoutData.structure.containers : []
          onNewLayoutSection(state, state.layout)
        } else {
          currentLayoutData = payload.layouts[Object.keys(payload.layouts)[0]]
          state.layout = currentLayoutData.structure && currentLayoutData.structure.containers ? currentLayoutData.structure.containers : []
          onNewLayoutSection(state, state.layout)
          state.localVersionOfElementsData = {}
        }
      } else {
        // WHEN DASHBOARD DOES NOT HAVE ANY LAYOUT (NEW DASHBOARD)
        state.layoutSelection = true
        state.currentTab = 0
      }

      // const currentLayoutData2 =  payload && payload.layouts && Object.keys(payload.layouts).length
      //     ? isCurrentLayoutIsEditing &&
      //       payload.layouts[state.currentLayoutData.identity]
      //       ? payload.layouts[state.currentLayoutData.identity]
      //       : Object.keys(rootLayout).length
      //         ? rootLayout
      //         : payload.layouts[Object.keys(payload.layouts)[0]]
      //     : (state.layoutSelection = true, state.currentTab = 0)
      // const currentLayoutData = payload && payload.layouts && Object.keys(payload.layouts).length ? payload.layouts[Object.keys(payload.layouts)[0]] : { identity: uuidv4() }

      state.currentLayoutData = currentLayoutData || "Unititled Name"
      state.layoutName = currentLayoutData.name
      // state.layout = isCurrentLayoutIsEditing ? state.layout : currentLayoutData.structure && currentLayoutData.structure.containers ? currentLayoutData.structure.containers : []
    }

    /***********editLayout********************** */
    if (mode === "editLayout") {
      // state.selectedContainerIValue = -1
      state.currentLayoutData = payload
      state.layoutName = payload.name || "Unititled Name"
      // state.inBoundParams = [];
      state.layout = payload.structure && payload.structure.containers ? payload.structure.containers : []
      const allElementsRefInACurrentLayout = state.currentLayoutData && state.currentLayoutData.structure && state.currentLayoutData.structure.elemRefs ? state.currentLayoutData.structure.elemRefs : []
      state.currentLayoutsElementRefs = allElementsRefInACurrentLayout
      onNewLayoutSection(state, state.layout)
      state.localVersionOfElementsData = {}
      // state.inContainerElementPreview = false
    }

    /*****************************createLayout******************************* */
    if (mode === "createLayout") {
      // state.selectedContainerIValue = -1
      onNewLayoutSection(state, payload)
      state.currentLayoutData = { identity: uuidv4(), devicesPrior }
      state.layoutName = payload.name ? payload.name : "Unititled Name"
      state.layout = payload
      // state.selectedElementData = {}
      state.currentLayoutsElementRefs = []
      state.allElementsOfACurrentLayoutInObj = {}
      state.localVersionOfElementsData = {}
      state.inBoundParams = [];
      // state.localVersionOfElementsData = {}
    }
  },

  setListAllLayoutData(state, payload) {
    let allLayouts = [];
    let rootLayoutId = ""
    if (payload.layouts) {
      for (let layoutId in payload.layouts) {
        let layout = payload.layouts[layoutId];
        if (layout.props && layout.props.isRoot) rootLayoutId = layoutId;
        allLayouts.push(layout)
      };
      state.allAvailableLayoutsData = allLayouts
      let currentLayoutId = state.currentLayoutData.identity;
      state.currentLayoutData = payload.layouts[currentLayoutId];
      state.layout = state.currentLayoutData && state.currentLayoutData.structure && state.currentLayoutData.structure.containers || [];
      state.layoutName = state.currentLayoutData.name;
      state.selectedContainerIValue = state.selectedContainerIValue
      state.selectedContainerElementIndex = -1
    }

  },
  setCurrentLayoutNameInAllLayouts(state, payload){
    let currentLayoutId = state.currentLayoutData.identity;
    let updatedName = payload.layouts && payload.layouts[currentLayoutId] && payload.layouts[currentLayoutId].name || ''
    
    state.currentLayoutData.name = updatedName;
    state.layoutName = updatedName

    let currentLayoutIndex = state.allAvailableLayoutsData.findIndex(
      (layout) => layout.identity === currentLayoutId
    )
    if (currentLayoutIndex !== -1){
      state.allAvailableLayoutsData[currentLayoutIndex].name = updatedName
    } else {
      state.allAvailableLayoutsData.push(payload.layouts && payload.layouts[currentLayoutId])
    } 

  },
  setAllLayoutDataExceptCurrent(state, payload) {
    let allLayouts = []
    payload && payload.layouts && Object.keys(payload.layouts).forEach(el => {
      if (state.currentLayoutData.identity == el && !state.isAllElemsAndCurrentLayoutSaved) {
        console.log(`[payload.layouts[el].props]: `, payload.layouts[el].props && payload.layouts[el].drillParent);
        if (payload.layouts[el].props && payload.layouts[el].props.drillParent && Object.keys(payload.layouts[el].props.drillParent).length) {
          state.currentLayoutData.props.drillParent = payload.layouts[el].props.drillParent
        }
        allLayouts.push(state.currentLayoutData)
      } else {
        allLayouts.push(payload.layouts[el])
      };
    });
    state.allAvailableLayoutsData = allLayouts
  },
  updateLayoutName(state, layoutName) {
    state.layoutName = layoutName
  },
  setDataSourceOfAElement(state, { dataSource = {} }) {
    // console.log('dataSource :>> ', dataSource);
    // state.selectedElementDataSource = dataSource
    // state.mappedDataSourceHeaders = dataSource && dataSource.headers ? dataSource.headers.map((d) => d.header) : []
  },
  togglePredifinedLayoutsDialog(state) {
    state.predifinedLayoutsDialog = !state.predifinedLayoutsDialog
  },
  setLocalVersionOfElementsData(state, { prop = "", data, _id }) {
    // console.log('prop, data, _id :>> ', prop, data, _id);
    if (prop && data && _id) {
      if (state.selectedContainerIValue == -1 || state.selectedContainerElementIndex == -1) {
        console.log("nothing change")
        return
      }
      // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
      const key = _id
      if (!state.localVersionOfElementsData[key]) {
        state.localVersionOfElementsData[key] = { ...(state.selectedElementData && state.selectedElementData._id) && { ...state.selectedElementData } }
        Vue.set(state.localVersionOfElementsData, key, { ...(state.selectedElementData && state.selectedElementData._id) && { ...state.selectedElementData } })
      }
      Vue.set(state.localVersionOfElementsData[key], prop, data)
      state.localVersionOfElementsData[key][prop] = data
      state.localVersionOfElementsData[key].isSaved = false
      state.isAllElemsAndCurrentLayoutSaved = false
      state.localVersionOfElementsData[key].isNewElem = !!state.selectedElementData.isNewElem
      state.selectedElementData = state.localVersionOfElementsData[key]
      console.log('%%%%%%%%%%%%%%%%%%% :>> ', state.localVersionOfElementsData, state.selectedElementData);
      // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
    }
  },
  addDataInLocalVersionOfElementsData(state, elements) {
    console.log(`[elements]: `, elements);
    if (!Array.isArray(elements)) elements = [elements];
    elements.forEach(el => {
      state.localVersionOfElementsData[el._id] = el;

    })
  },
  // updateLocalVersionOfElementsData(state, { op = "", data, key, elementIdx = 0, _id = "" }) {
  //   console.log('op, _id, elementIdx :>> ', op, _id, elementIdx);
  //   if (_id && op) {
  //     if (op == "delete") {
  //       let keys = []
  //       if (!Array.isArray(_id)) {
  //         keys = [_id]
  //       } else {
  //         keys = _id
  //       }
  //       const copy = JSON.parse(JSON.stringify(state.localVersionOfElementsData))
  //       console.log('keys :>> ', keys);
  //       keys.forEach((k) => {
  //         delete copy[k]
  //       })
  //       state.localVersionOfElementsData = copy
  //     }
  //     // if(op == "update" && key){
  //     //   state.localVersionOfElementsData[key] = data
  //     //   state.selectedElementData =  state.localVersionOfElementsData[key]
  //     // } 
  //     if (op == "update") {
  //       state.localVersionOfElementsData[_id] = data
  //       state.selectedElementData = state.localVersionOfElementsData[_id]
  //       state.selectedElementData["drill"] = state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex] && state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] ? state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] : {}
  //     }
  //     state.selectedContainerElementIndex == elementIdx
  //     // state.selectedContainerIValue = -1
  //   }
  // },
  toggleInContainerElementPreview(state) {
    state.inContainerElementPreview = !state.inContainerElementPreview
  },
  setLayoutSelection(state, selection = false) {
    state.layoutSelection = selection
  },
  setCurrentTab(state, tab = 0) {
    state.currentTab = tab
  },
  setIsAllElemsAndCurrentLayoutSaved(state, value) {
    console.log("*&*(&*&*&&**&&&&&&&&&&&&&&&&&&&&*********************************", value)
    state.isAllElemsAndCurrentLayoutSaved = !!value
    state.isLayoutSave = !!value;
  },
  setSelectedPreviewLayout(state, layout) {
    if (layout) {
      state.selectedPreviewLayout = layout
    } else {
      state.selectedPreviewLayout = state.layout;
    }
  },
  setSelectedElementComponentPreview(state, data) {
    state.selectedElementComponentPreview = data
  }
}

const actions = {
  detachDrillInfo({ dispatch, commit, rootState, state }, payload) {
    try {
      // console.log("payload:>>>", payload);
      let checkLayouts = state.allAvailableLayoutsData;
      // console.log("checkLayouts", checkLayouts)
      for (let layout of checkLayouts || []) {
        // console.log(layout.name);
        if (layout.props && layout.props.drillParent) {
          let layoutsWithDrillParent = layout.props.drillParent[
            state.currentLayoutData.identity
          ]
            ? layout.props.drillParent[state.currentLayoutData.identity]
            : [];
          if (layoutsWithDrillParent.length) {
            for (let lay of layoutsWithDrillParent) {
              if (lay.elementId === payload.elementId) {
                let childID = "";
                if (
                  payload.prop &&
                  (payload.prop == "isContainer" || payload.prop == "isElement")
                ) {
                  childID = layout.identity;
                } else if (
                  (payload.drill &&
                    Object.keys(payload.drill).length) &&
                  (payload.drill.layoutId === null || payload.drill.layoutId === '' ||
                    payload.drill.layoutId !== layout.identity)
                ) {
                  childID = layout.identity;
                }
                if (childID) {
                  console.log(childID, "++++++++++++++++++");
                  state.drillUpdateInfo["removed"].push({
                    childlLayoutId: childID,
                    reportId: rootState.report._id,
                    layoutId: state.currentLayoutData.identity,
                    elementId: payload.elementId,
                  });
                  // console.log(
                  //   "Drill Update after detach>>>>>>>>>>",
                  //   state.drillUpdateInfo
                  // );
                }
              }
            }
          }
        }
      }
      state.isAllElemsAndCurrentLayoutSaved = false;
    } catch (error) {
      console.log(error);
    }
  },
  attachDrillInfo({ dispatch, commit, rootState, state }, payload){
    for (let container of state.layout) {
      for (let elem of container.elements || []) {
        if (elem.drill && Object.keys(elem.drill).length) {
          if(elem.drill.layoutId) {
          // console.log("Drill>>>>>>>>>>")
            state.drillUpdateInfo['attached'].push({
              drill: elem.drill,
              childlDrillId: elem.drill.layoutId,
              reportId: rootState.report._id,
              layoutId: state.currentLayoutData.identity,
              containerId: container.i,
              elementId: elem.elemLinkTo
            })
            }
            dispatch('detachDrillInfo', {containerId: container.i, elementId: elem.elemLinkTo, drill: elem.drill })
          }
        }
      }
      // console.log("Drill Update after attach>>>>>>>>>>", state.drillUpdateInfo)
      state.isAllElemsAndCurrentLayoutSaved = false
  },
  setLayoutStates({ commit, state }, layout) {
    const currentLayout = cloneDeep(state.layout)
    state.layout = layout;
    commit('setPreviousLayoutState', currentLayout)
  },
  async updateBIReport({ dispatch }, payload) {
    return dispatch(
      'apiCall',
      {
        method: 'put',
        data: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/'
      },
      { root: true }
    )
  },
  async deleteLayout({ dispatch }, payload) {
    return dispatch(
      'apiCall',
      {
        method: 'delete',
        data: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/layout/delete'
      },
      { root: true }
    )
  },
  async duplicateLayout({ dispatch }, payload) {
    return dispatch(
      'apiCall',
      {
        method: 'post',
        data: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/layout/duplicate'
      },
      { root: true }
    )
  },
  async markUnMarkLayoutAsRoot({ dispatch, commit }, payload) {
    let res = await dispatch(
      'apiCall',
      {
        method: 'post',
        data: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/layout/rootLayout'
      },
      { root: true }
    )
    let result = await dispatch("getReportDetails", payload, { root: true });
    commit("setReport", result.result, { root: true })
    return res
  },
  async getAllElementsOfACurrentLayout({ dispatch, state, rootState }, payload = {}) {
    // console.log('state.currentLayoutData  :>> ', state.currentLayoutData);
    const allElementsRefInACurrentLayout = state.currentLayoutData && state.currentLayoutData.structure && state.currentLayoutData.structure.elemRefs ? state.currentLayoutData.structure.elemRefs : []
    state.currentLayoutsElementRefs = allElementsRefInACurrentLayout
    let makeSureOfAllElemLayout = []
    state.layout && state.layout.map((container, idx) => {
      for (let elem of container.elements || []) {
        if (elem.elemLinkTo) makeSureOfAllElemLayout.push(elem.elemLinkTo)
      }
    })
    const newPayload = {
      reportId: rootState.report._id,
      elementsRefs: [...allElementsRefInACurrentLayout, ...makeSureOfAllElemLayout]
    }
    // console.log(`[state.layout]: `, state.layout);
    // payload["elementsRefs"] = allElementsRefInACurrentLayout
    console.log('newPayload [getAllElementsOfACurrentLayout] :>> ', newPayload);
    return dispatch(
      'apiCall',
      {
        method: 'get',
        params: newPayload,
        url: constants.BASE_EX_URL() + '/Elements/v0.1/'
      },
      { root: true }
    )
  },
  async setLayoutDrillChildren({ dispatch, state, rootState, commit }, payload) {
    try {
      //'layoutId', 'elementId'
      let res = await dispatch(
        'apiCall',
        {
          method: 'post',
          data: {
            drill: payload,
            reportId: rootState.report._id,
            layoutId: state.currentLayoutData.identity,
            containerId: state.selectedContainerIValue,
            elementId: state.selectedElementData._id
          },
          url: constants.BASE_EX_URL() + '/BIReports/v0.1/layout/attachDrill'
        },
        { root: true }
      );
      console.log(`[res]: `, res);
      if (res.ok && res.result) {
        commit("setReport", res.result, { root: true })
        commit("setAllLayoutDataExceptCurrent", res.result)
      }

    } catch (error) {
      console.log(error)
    }
  },
  async setOutBoundParams({ dispatch, state, rootState, commit }, payload) {
    try {
      //'layoutId', 'elementId'
      let res = await dispatch(
        'apiCall',
        {
          method: 'post',
          data: {
            drill: payload,
            reportId: rootState.report._id,
            layoutId: state.currentLayoutData.identity,
          },
          url: constants.BASE_EX_URL() + '/BIReports/v0.1/layout/mapDrillParams'
        },
        { root: true }
      );
      console.log(`[res]: `, res);
      if (res.ok && res.result) {
        commit("setReport", res.result, { root: true })
        commit("setAllLayoutDataExceptCurrent", res.result)
      }

    } catch (error) {
      console.log(error)
    }
  },
  async getReportPublishHistory({ dispatch }, payload) {
    return dispatch(
      'apiCall',
      {
        method: 'get',
        params: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/report/publishHistory'
      },
      { root: true }
    )
  },
  async publishReport({ dispatch }, payload) {
    return dispatch(
      'apiCall',
      {
        method: 'put',
        data: payload,
        url: constants.BASE_EX_URL() + '/BIReports/v0.1/report/publish'
      },
      { root: true }
    )
  },
  async onLayoutSave({ commit, dispatch }) {
    let res = await dispatch("getAllElementsOfACurrentLayout");
    commit("setAllFetchedElementsOfACurrentLayout", res.result)
    // commit("setSelectedContainerIValue", 0) 
  },
  async setLayoutSupplement({ state, dispatch, commit }, payload) {
    // console.log("*****************************************", state.currentLayoutData.structure.supRefs)
    let supplement = payload || state.currentLayoutData.structure && state.currentLayoutData.structure.supRefs || [];
    console.log(`[supplement]: `, supplement);
    state.inBoundParams = []
    if (supplement.length) {
      let supplementRes = await dispatch("supplementStore/getSupplementsByIds", supplement, { root: true });
      console.log(`[supplementRes]: `, supplementRes);
      state.inBoundParams = supplementRes.data;
    }
  },

  updateLocalVersionOfElementsData({ state, dispatch, commit }, { op = "", data, key, elementIdx = 0, _id = "" }) {
    console.log('op, _id, elementIdx :>> ', op, _id, elementIdx);
    if (_id && op) {
      if (op == "delete") {
        let keys = []
        if (!Array.isArray(_id)) {
          keys = [_id]
        } else {
          keys = _id
        }
        const copy = JSON.parse(JSON.stringify(state.localVersionOfElementsData))
        console.log('keys :>> ', keys);
        keys.forEach((k) => {
          delete copy[k]
        })
        state.localVersionOfElementsData = copy
      }
      // if(op == "update" && key){
      //   state.localVersionOfElementsData[key] = data
      //   state.selectedElementData =  state.localVersionOfElementsData[key]
      // } 
      if (op == "update") {
        console.log(`[data]: `, data);
        state.localVersionOfElementsData[_id] = data
        state.selectedElementData = state.localVersionOfElementsData[_id]
        state.selectedElementData["drill"] = state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex] && state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] ? state.layout[state.selectedContainerIValue]["elements"][state.selectedContainerElementIndex]["drill"] : {}
      }
      state.selectedContainerElementIndex == elementIdx
      // state.selectedContainerIValue = -1
    }
  },


  discardChanges({ state, commit }) {
    const currentLayout = cloneDeep(state.beforeEditLayoutState);
    state.layout = currentLayout.structure && currentLayout.structure.containers ? currentLayout.structure.containers : []
  }
  // getElementById(state, id) {
  //   return state.localVersionOfElementsData[id]
  // }
}




export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
