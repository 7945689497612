<template>
  <v-card>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
}
</script>

<style></style>
