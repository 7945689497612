<template>
  <component :is="dynamic" class="svg-icon-vue" />
</template>


<script>
export default {
  props: ["name"],
  data() {
    return {
      dynamic: false,
    };
  },
  async created() {
    try {
      if (this.name) {
        this.dynamic = await import(`../../assets/icons/${this.name}.svg`);
      }
    } catch (error) {
      console.log("svg-icon", this.name, error);
    }
  },
};
</script>

<style>
</style>