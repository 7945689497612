<template>
  <div class="fill-height login-bg" :style="backgroundImage">
    <forgotPassword
      :resetPasswordDialogStatus="resetPasswordDialogStatus"
      @closeResetPasswordDialog="closeResetPasswordDialog"
    />
    <SAMLLogin
      :SAMLLoginDialogStatus="SAMLLoginDialogStatus"
      @closeSAMLLoginDialog="closeSAMLLoginDialog"
      @ssoLogin="ssoLogin"
    />
    <resetPassword
      :optResetPasswordDialogStatus="optResetPasswordDialogStatus"
      @closeOtpResetDialog="closeOtpResetDialog"
      @onLoginSuccess="onLoginSuccess"
    />
    <v-container fluid class="fill-height pa-0">
      <!-- :style="{
                   'background-image': `url('../../common/bg/BG option 5.svg')`
               }" -->
      <v-row no-gutters align="center" justify="center">
        <v-col
          cols="10"
          lg="3"
          md="4"
          sm="6"
          align="center"
          :class="
            ['md', 'lg', 'xl'].includes($vuetify.breakpoint.name) &&
            'd-md-sarkaw'
          "
          justify="center"
        >
          <v-card
            style="border-radius:1em;"
            elevation="22"
            v-if="!$store.state['authStore'].otpAsPasswordDialog && !resetToken"
          >
            <v-card-text class="pa-0">
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col
                    cols="12"
                    class="px-0 pb-0"
                    align="center"
                    justify="center"
                  >
                    <div class="circle-bg">
                      <v-avatar class="border" size="100">
                        <img src="../../common/images/final_logo_o2576_1.svg" />
                        <!-- <div> -->
                        <!-- <div >
                        </div> -->
                        <!-- <v-img
                          style="
                            margin: 1%;
                            background-position: inherit !important;
                          "
                          src="../../common/images/final_logo_o2576_1.svg"
                        /> -->
                        <!-- </div> -->
                        <!-- <v-img src="../../common/images/kennect-logo.png" /> -->
                        <!-- <v-img v-show="false" src="../../common/images/logo.png" /> -->
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="10" class="mx-auto" md="8" sm="9">
                    <v-text-field
                      prepend-inner-icon="mdi-account-outline"
                      v-model="username"
                      label="Enter Username"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="10" class="mx-auto" md="8" sm="9">
                    <v-text-field
                      :append-icon="
                        isPasswordInvisible ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="isPasswordInvisible = !isPasswordInvisible"
                      prepend-inner-icon="mdi-lock-outline"
                      :type="isPasswordInvisible ? 'password' : 'text'"
                      v-model="password"
                      label="Enter Password"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" align="center" justify="center">
                    <v-btn
                      color="primary"
                      raised
                      rounded
                      large
                      :loading="isLoading"
                      @click="login"
                      >Login</v-btn
                    >
                      <!-- @click="extecuteGoogleReCaptchaV2" -->
                    <!-- <br /> -->
                    <v-row flex class="justify-center align-center mt-1">
                      <!-- <v-col v-show="!isMobile" cols="6" class="mx-auto" sm="6">
                        <v-flex>
                          <span v-show="!isGoogleLogin" class="loginBtns" id="google-signin-btn"></span>
                          <v-btn v-show="isGoogleLogin" class="loginBtns" :loading="isGoogleLogin"></v-btn>
                        </v-flex>
                      </v-col> -->
                      <v-col cols="6" class="mx-auto" md="4" sm="6">
                        <v-btn
                          class="loginBtns"
                          color="secondary"
                          raised
                          rounded
                          large
                          @click="openSSOLoginDialog"
                          >SSO</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-btn text class="mt-2" @click="openResetPasswordDialog"
                      >Forgot password?</v-btn
                    >
                    <v-btn text class="mt-2" @click="goToUserOnBoardingScreen"
                      >Have an OTP?</v-btn
                    >
                    <help-and-support />
                  </v-col>
                  <v-col
                    cols="10"
                    align="center"
                    justify="center"
                    class="mx-auto"
                  >
                    <v-alert dismissible :type="alertType" :value="isAlert">{{
                      alertText
                    }}</v-alert>
                    <!-- <GoogleReCaptchaV2
                      @onReCaptchaSuccess="onReCaptchaSuccess"
                    /> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import forgotPassword from "./forgotPassword";
import SAMLLogin from "./SAMLLogin.vue";
import resetPassword from "./resetPassword";
import getAuthConfig from "../getAuthConfig"; //= getAuthConfig();
import StorageUtils from "../StorageUtils";
import HelpAndSupport from "./HelpAndSupport";
import kxBrowserUtils from "@common-ui/authenticator/api/browserUtils";
import GoogleReCaptchaV2 from "@common-ui/authenticator/components/GoogleReCaptchaV2";
import bg0 from "../../common/bg/BG option 0.svg";
import bg1 from "../../common/bg/BG option 1.svg";
import bg2 from "../../common/bg/BG option 2.svg";
import bg3 from "../../common/bg/BG option 3.svg";
import bg4 from "../../common/bg/BG option 4.svg";
import bg5 from "../../common/bg/BG option 5.svg";
import bg6 from "../../common/bg/BG option 6.svg";

export default {
  components: {
    forgotPassword,
    SAMLLogin,
    resetPassword,
    HelpAndSupport,
    GoogleReCaptchaV2,
  },
  props: ["portal"],
  data() {
    return {
      uri: getAuthConfig().uri,
      username: "",
      password: "",
      isAlert: false,
      alertText: "",
      alertType: "error",
      isLoading: false,
      isGoogleLogin: false,
      isPasswordInvisible: true,
      resetPasswordDialogStatus: false,
      optResetPasswordDialogStatus: false,
      isIFrameWindowLogin: false,
      targetOrigin: null,
      SAMLLoginDialogStatus: false,
      resetToken: "",
      showCaptcha: false,
      isCaptchaVerificationNeeded: false,
    };
  },
  created() {
    // Check for : If auth is loaded for normal window login or for any child window logging in as a iFrame Auth
    let { reset } = kxBrowserUtils.extractHash(window);
    this.resetToken = reset;
    // console.log("Logged Output: created -> reset", reset)
    // console.log('reset :>> ', this.resetToken);
    if (window.location.hash.includes("#KennectAuth")) {
      this.targetOrigin = window.location.hash.split("#")[2];
      this.isIFrameWindowLogin = true;
    }
    window.addEventListener("keypress", this.loginKeyPressEventHandler);
  },
  destroyed() {
    window.removeEventListener("keypress", this.loginKeyPressEventHandler);
  },
  methods: {
    async loginKeyPressEventHandler(event) {
      if (event.keyCode === 13) {
        try {
          await this.login();
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    openResetPasswordDialog() {
      this.resetPasswordDialogStatus = true;
    },
    openSSOLoginDialog() {
      this.SAMLLoginDialogStatus = true;
    },
    closeResetPasswordDialog({ isPasswordResetSuccessful }) {
      if (isPasswordResetSuccessful === true) {
        this.setSuccessText("Password Reset Successful.");
      }
      this.resetPasswordDialogStatus = false;
    },
    closeSAMLLoginDialog({ isSAMLLoginSuccessful }) {
      if (isSAMLLoginSuccessful === true) {
        this.setSuccessText("SSO Login Successful.");
      }
      this.SAMLLoginDialogStatus = false;
    },
    closeOtpResetDialog() {
      this.optResetPasswordDialogStatus = false;
      // this.isUserOnBoarding = false;
    },
    async login(captchaResponse = "") {
      try {
        if (this.isLoading || !this.isLoginValid()) {
          return;
        }
        const { uri } = getAuthConfig();
        let url = uri.login;
        this.isLoading = true;
        this.username = this.username.trim();
        let loginRequestBody = {
          username: this.username,
          password: this.password,
          reCaptchaToken: captchaResponse
        };
        if (this.isMobile) {
          loginRequestBody.operatingSystem = {
            name: device.platform,
            version: device.version,
          };
        } else if (window.cordova && device && device.platform === "browser") {
          loginRequestBody.operatingSystem = {
            name: device.model,
            version: device.version,
          };
        }
        let result = await axios.post(url, loginRequestBody);
       
        delete loginRequestBody.password;
        if (!result.data.ok) {
          if (result.data.message) {
            this.setErrorText(result.data.message);
          } else {
            this.setErrorText("Error Occured during login.");
          }
          this.isLoading = false;
          this.$store.commit("authStore/setCaptchaResponse", "");
          return;
        }
        if (
          result &&
          result.data &&
          result.data.mortal &&
          result.data.mortal.passwordResetToken
        ) {
          this.$store.commit("authStore/setOtpAsPwdDialog", true);
          this.$store.commit(
            "authStore/setMortalForOtpLogin",
            result.data.mortal
          );
        } else {
          let accessToken = result.data.token;
          let refreshToken = result.data.refreshToken;
          let mortal = result.data.mortal;

          await this.onLoginSuccess(accessToken, refreshToken, mortal);

          // Set tokens or generate tabId if and only if it is not any child window login from iFrameAuth
          if (
            this.$store.getters["authStore/ready"] &&
            !this.isIFrameWindowLogin
          ) {
            this.$emit("login", { accessToken, refreshToken, mortal });
            this.$store.commit("authStore/makeTabId");
            this.$store.commit("authStore/setTokens", {
              refreshToken,
              accessToken,
            });
            this.$store.commit("authStore/setMortal", mortal);
            await StorageUtils.setItem("auth", accessToken);
            await StorageUtils.setItem("refresh-token", refreshToken);
            await StorageUtils.setItem("userData", JSON.stringify(mortal));
          } else {
            console.log(
              "[WARNING]",
              "Ypu might have forgotten to register the $store "
            );
          }
          if (this.isIFrameWindowLogin) {
            console.log("this.targetOrigin:", this.targetOrigin);
            window.opener.postMessage(
              { accessToken, refreshToken, mortal },
              this.targetOrigin
            );
            console.log("window[loginNow.vue] :", window);
            let iFrameLoginHadler = (event) => {
              if (event.origin === this.targetOrigin) {
                if (event.data === "iFrameAuthSuccess") {
                  window.removeEventListener("message", iFrameLoginHadler);
                  console.log(
                    '%c window.removeEventListener("message", iFrameLoginHadler);',
                    "color:yellow; font-weight:bold;font-size:20px"
                  );
                  // Close auth window after login success for any child window
                  window.close();
                  console.log(`%c [event] :`, "color:green; font-size:16px");
                  console.log(event);
                  console.log(`%c [event] :`, "color:green; font-size:16px");
                }
                if (event.data === "iFrameAuthFailed") {
                  console.log(
                    "FAILED TO LOGIN FROM iFRAME AUTH WINDOW [TRY AGAIN ....]"
                  );
                  // Close auth window if something went wrong and unable to login
                  window.close();
                }
              }
            };
            window.addEventListener("message", iFrameLoginHadler);
            console.log(
              '%c window.addEventListener("message", iFrameLoginHadler);',
              "color:yellow; font-weight:bold;font-size:20px"
            );
          }
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log("error", error);
      }
    },
    async onLoginSuccess(accessToken, refreshToken, mortal) {
      // Set tokens or generate tabId if and only if it is not any child window login from iFrameAuth
      if (this.$store.getters["authStore/ready"]) {
        if (!this.isIFrameWindowLogin) {
          console.log("authStore ready ", {
            refreshToken,
            accessToken,
            mortal,
          });
          this.$emit("login", { accessToken, refreshToken, mortal });
          this.$store.commit("authStore/makeTabId");
          this.$store.commit("authStore/setTokens", {
            refreshToken,
            accessToken,
          });
          this.$store.commit("authStore/setMortal", mortal);
          await StorageUtils.setItem("auth", accessToken);
          await StorageUtils.setItem("refresh-token", refreshToken);
          await StorageUtils.setItem("userData", JSON.stringify(mortal));
        } else {
          console.log("[WARNING]", "SOMETHING WENT WRONG");
        }
      } else {
        console.log(
          "[WARNING]",
          "Ypu might have forgotten to register the $store "
        );
      }
      if (this.isIFrameWindowLogin) {
        console.log("this.targetOrigin:", this.targetOrigin);
        window.opener.postMessage(
          { accessToken, refreshToken, mortal },
          this.targetOrigin
        );
        console.log("window[loginNow.vue] :", window);
        let iFrameLoginHadler = (event) => {
          if (event.origin === this.targetOrigin) {
            if (event.data === "iFrameAuthSuccess") {
              window.removeEventListener("message", iFrameLoginHadler);
              // Close auth window after login success for any child window
              window.close();
              console.log(event);
            }
            if (event.data === "iFrameAuthFailed") {
              console.log(
                "FAILED TO LOGIN FROM iFRAME AUTH WINDOW [TRY AGAIN ....]"
              );
              // Close auth window if something went wrong and unable to login
              window.close();
            }
          }
        };
        window.addEventListener("message", iFrameLoginHadler);
      }

      this.$emit("login", { accessToken, refreshToken, mortal });
      this.isLoading = false;
    },
    isLoginValid() {
      this.resetAlert();
      if (this.username === "") {
        this.setErrorText("Username cannot be empty.");
        return false;
      } else if (this.password === "") {
        this.setErrorText("Password cannot be empty.");
        return false;
      }
      return true;
    },
    resetAlert() {
      this.alertText = "";
      this.alertType = "success";
      this.isAlert = false;
    },
    setErrorText(errorText) {
      this.alertText = errorText;
      this.isAlert = true;
      this.alertType = "error";
    },
    setSuccessText(successText) {
      this.alertText = successText;
      this.isAlert = true;
      this.alertType = "success";
    },
    async renderGoogleLoginButton() {
      // await gapi.auth2.getAuthInstance().signOut()
      await gapi.signin2.render("google-signin-btn", {
        // 'scope': 'email', // Optional if fetch_basic_profile is not set to false.
        onsuccess: this.onGoogleSignIn,
        onfailure: this.onGoogleSignInFail,
      });
    },
    async onGoogleSignIn(googleUser) {
      try {
        this.resetAlert();
        this.isGoogleLogin = true;
        let id_token = googleUser.getAuthResponse().id_token;
        // Additional user details
        // let profile = googleUser.getBasicProfile();
        // let data = {
        //   id:  profile.getId(),
        //   full_name:  profile.getName(),
        //   given_name:  profile.getGivenName(),
        //   family_name:  profile.getFamilyName(),
        //   img_url:  profile.getImageUrl(),
        //   email:  profile.getEmail(),
        //   id_token
        // };
        // console.log('profile :', data);
        const { uri } = getAuthConfig();
        let url = uri.loginUsingGoogle;
        let result = await axios.post(url, {
          id_token,
        });
        if (!result.data.ok) {
          await gapi.auth2.getAuthInstance().signOut(); // remove google instance after successful/Failed login attempt to prevent from auto login
          if (result.data.message) {
            this.setErrorText(result.data.message);
          } else {
            this.setErrorText("Error Occured during login.");
          }
          this.isGoogleLogin = false;
          return;
        }
        await gapi.auth2.getAuthInstance().signOut(); // remove google instance after successful/Failed login attempt to prevent from auto login
        // To get user is Signed In or not ===> gapi.auth2.getAuthInstance().isSignedIn.get()

        let accessToken = result.data.token,
          refreshToken = result.data.refreshToken,
          mortal = result.data.mortal;

        await this.onLoginSuccess(accessToken, refreshToken, mortal);
        this.isGoogleLogin = false;
      } catch (error) {
        gapi.auth2.getAuthInstance().signOut();
        this.isGoogleLogin = false;
        console.log("error [google login] :>> ", error);
      }
    },
    onGoogleSignInFail(error) {
      this.setErrorText("Error Occured during login.");
      console.log("error :", error);
      this.isGoogleLogin = false;
    },
    async ssoLogin(data) {
      await this.onLoginSuccess(data.token, data.refreshToken, data.mortal);
    },
    goToUserOnBoardingScreen() {
      // const userOnBoardingURL = `${getAuthConfig().sdkConf.KNX_UI}/#userOnBoarding=true`
      // window.open(userOnBoardingURL, "_self")
      // this.isUserOnBoarding = true;

      //  from merge confl with wip sso
      // const userOnBoardingURL = `${
      //     getAuthConfig().sdkConf.KNX_UI
      //   }/#userOnBoarding=true`;
      //   window.open(userOnBoardingURL, "_blank");
      // },
      this.optResetPasswordDialogStatus = true;
    },
    onReCaptchaSuccess(response){
      console.log('response [onReCaptchaSuccess] :>> ', response);
      this.resetAlert()
      this.login(response.reCaptchaToken)
      window.grecaptcha.reset();
    },
    extecuteGoogleReCaptchaV2(){
      if(!window.grecaptcha){
        this.setErrorText("Something went wrong/ Internet connectivity issue, please reload page")
        return
      }
      window.grecaptcha.execute()
    },
  },
  mounted() {
    // if (typeof gapi !== "undefined") {
    //   this.renderGoogleLoginButton();
    // } else {
    //   window.addEventListener("google-loaded", this.renderGoogleLoginButton);
    // }
  },
  computed: {
    isMobile() {
      return StorageUtils.isMobile();
    },
    backgroundImage() {
      let all = [bg0, bg1, bg2, bg3, bg4, bg5, bg6];
      let randIndex = Math.floor(Math.random() * all.length);
      return {
        // backgroundImage: `url("../../common/bg/BG option ${[randIndex]}.svg") !important`
        backgroundImage: `url("${all[randIndex]}") !important`,
        backgroundColor: `white`,
      };
    },
  },
};
</script>

<style scoped>
.border {
  /* border: 1px solid rgb(66, 66, 66); */
  border-radius: 0%;

  /* padding: 20%; */
}
.circle-bg {
  /* background-color: white;
  border-radius: 50%;
  margin-top: -15%; */
  height: 100px;
  min-width: 100px;
  width: 100px;
  /* background-color: aquamarine; */
  /* background-image: url('../../common/images/final_logo_o2576_1.svg'); */
}
.background {
  background-color: #e5e5e5;
}
.loginBtns {
  width: 67%;
  height: 37px !important;
}
.login-bg {
  /* background-color: white; */
  background-repeat: no-repeat;
  background-size: cover;
}
.d-md-sarkaw {
  margin-right: -30rem;
  margin-top: -5rem;
  padding-bottom: 1rem;
  /* background-image: url("../../common/bg/BG option 0.svg");
  background-image: url("../../common/bg/BG option 1.svg");
  background-image: url("../../common/bg/BG option 2.svg");
  background-image: url("../../common/bg/BG option 3.svg");
  background-image: url("../../common/bg/BG option 4.svg");
  background-image: url("../../common/bg/BG option 5.svg");
  background-image: url("../../common/bg/BG option 6.svg");
  background-image: url("../../common/bg/BG option 7.svg");
  background-image: url("../../common/bg/BG option 8.svg"); 
  */
  /* border: 5px solid black; */
}
</style>