// const constants = require("./api/uri");
const { uri } = require("./getAuthConfig")();
const axios = require("axios");
const StorageUtils = require("./StorageUtils");
const authByPopUpUtils = require("./api/attemptAuthByPopUpUtils");
const isJSON = async (obj) => {
  try {
    obj = JSON.parse(obj);
    return true;
  } catch (error) {
    return false;
  }
}

const inititalState = () => {
  return {
    tabId: "",
    identity: "",
    accessToken: "",
    refreshToken: "",
    mortal: {},
    otpAsPasswordDialog: false,
    mortalForOtpLogin: {},
    captchaResponse: "",
    specialLoginPayload: {},
  }
}

const state = inititalState();
module.exports = {
  namespaced: true,
  state,
  mutations: {
    resetState(state) {
      Object.assign(state, inititalState());
    },
    setTokens(state, { refreshToken, accessToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    clearTokens(state) {
      state.accessToken = "";
      state.refreshToken = "";
    },
    setOtpAsPwdDialog: (state, payload) => {
      state.otpAsPasswordDialog = payload
    },
    setMortalForOtpLogin: (state, payload) => {
      state.mortalForOtpLogin = payload
    },
    setMortal(state, mortal) {
      //[START] For Manual Integration of Kenx portl card inside portal list
      // console.log('mortal :', mortal);
      // let t = mortal
      // if(!t.portals.includes("kenx")){
      //   t.portals.push("kenx")
      //   t.portalKeys.kenx = "http://localhost:4008"
      // }
      // console.log('t :', t);
      // state.mortal = t;
      //[END] For Manual Integration of Kenx portl card inside portal list
      state.mortal = mortal
      state.identity = mortal.id + "~" + mortal.username;
    },
    makeTabId(state, tabId) {
      //[START] For Kenx AuthStore Instance
      if (tabId && tabId != "") {
        state.tabId = tabId
        sessionStorage.setItem("tabId", state.tabId);
      }
      //[END] For Kenx AuthStore Instance
      if (!state.tabId) {
        state.tabId =
          new Date().toString() + "~~" + (Math.random() * 1000).toFixed(0);
        sessionStorage.setItem("tabId", state.tabId);
        console.log("state.tabId :", state.tabId);
      }
    },
    sendLogoutMsgToChildWindows() {
      if (window.allChilds && window.allChilds.length > 0) {
        for (let windowRef of window.allChilds) {
          // calling logout method of all child windows [ when parent window call its logout method ]
          windowRef.logout();
        }
      }
    },
    setCaptchaResponse(state, data = ""){
      state.captchaResponse = data
    },
    setSpecialLoginPayload(state, payload){
      state.specialLoginPayload = payload
    }
  },
  actions: {
    logout: async ({ commit, state, dispatch }, payload) => {
      return new Promise(async (resolve, reject) => {
        try {
          if (payload && payload.selfLogoutInitiated) {
            let url = uri.logout;
            let resp = await axios({
              url,
              method: "post",
              headers: {
                Authorization: state.accessToken,
                "refresh-token": state.refreshToken
              },
              data: {
                operatingSystem: payload.operatingSystem
              }
            });
            if (!resp.data.ok) {
              if (resp.data.message) {
                throw resp.data.message;
              } else {
                throw "Error occured during login";
              }
            }
          }
          commit("sendLogoutMsgToChildWindows");
          commit("resetState");
          // Because KENX does not have childRef as DW/KBET/DSI
          if(authByPopUpUtils.KNX_UI === window.location.origin){
            await dispatch("resetState", null, {root: true})
          }
          await StorageUtils.clearStorage();
          resolve(true);
        } catch (error) {
          commit("sendLogoutMsgToChildWindows");
          reject(error);
        }
      });
    },
    rotateAccessToken: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await axios({
            method: 'post',
            url: uri.getNewToken,
            headers: {
              "refresh-token": state.refreshToken
            }
          }, { root: true });
          if (result && !result.data.ok) {
            reject(result.data);
          }
          let accessToken = result.data.token;
          state.accessToken = accessToken;
          await StorageUtils.setItem("auth", state.accessToken);
          resolve(result.data);
        } catch (error) {
          console.log('error', error);
          reject(error);
        }
      })
    },
    setAuthFromSessionStorage: async ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        try {
          let token = await StorageUtils.getItem("auth");
          let userData = await StorageUtils.getItem("userData")
          userData = typeof userData === 'string' && isJSON(userData) ? JSON.parse(userData) : userData;
          let refreshToken = await StorageUtils.getItem("refresh-token");
          if (token && userData) {
            commit('setTokens', {
              accessToken: token,
              refreshToken
            });
            commit('setMortal', userData);
          } else {
            commit('setTokens', {
              accessToken: '',
              refreshToken: ''
            });
            commit('setMortal', {});
            await StorageUtils.clearStorage();
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    // setAuth({ commit }, payload) {
    //   window.allChildWindows = []
    //   sessionStorage.setItem('tabId', payload.tabId)
    //   commit('login', {
    //     token: payload.accessToken,
    //     userType: payload.mortal.type,
    //     refreshToken: payload.refreshToken,
    //     mortal: payload.mortal,
    //     reportId: payload.reportId
    //   })
    //   if (payload.mortal.userData) {
    //     commit('userData', payload.mortal.userData)
    //   }
    //   if (payload.mortal.portalKeys) {
    //     portal_keys_constants.setPortalKeys(payload.mortal.portalKeys)
    //   }
    // },
    generateClientSLRT: async ({state}, payload) => {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await axios({
            method: 'post',
            data: payload,
            url: uri.client_slrt,
            headers: {
              Authorization: state.accessToken,
            }
          });
          if (result && !result.data.ok) {
            reject(result.data);
          }
          resolve(result.data);
        } catch (error) {
          console.log('error', error);
          reject(error);
        }
      });
    },
    forceResetPassword({ dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await axios({
            method: 'post',
            data: payload,
            url: uri.resetPassword,
            headers: {
              Authorization: state.accessToken,
            }
          });
          if (result && !result.data.ok) {
            reject(result.data);
          }
          resolve(result.data);
        } catch (error) {
          console.log("Logged Output: forceResetPassword -> error", error)
          reject(error);
        }
      });
    },
  },
  getters: {
    ready: () => true,
    privileges: state => state.mortal.privileges,
    portals: state => state.mortal.portals,
    crosstabPayload: state => {
      return {
        mortal: state.mortal,
        refreshToken: state.refreshToken,
        accessToken: state.accessToken,
        identity: state.identity,
        tabId: state.tabId
      };
    },
    kbetPayload: state => ({
      accessToken: state.accessToken,
      identity: state.identity,
      mortal: {
        type: 30,
        owner: state.mortal.owner,
        id: state.mortal.id,
        username: state.mortal.username,
        userData: state.mortal.userData,
        tenant: state.mortal.tenant,
        employerGID: state.mortal.employerGID,
        specs: state.mortal.specs,
        portalKeys: state.mortal.portalKeys
      }
    }),
    isLoggedIn: state => !!state.accessToken,
    getInfo: state => {
      return {
        mortal: state.mortal
      }
    },
    userType: state => state.mortal.type
  },
  modules: {}
};
