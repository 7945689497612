<template>
  <v-card :color="info.color" style="margin:14px 22px;" @click="onClick" v-if="isShowPortal()">
    <v-card-text>
      <p class="display-1 text--primary">{{ isKenxPortal ? "Data Management / Reports" : info.title}}</p>
      <p>{{isKenxPortal ? "DW Reports/Journeys/Schemes" : info.description}}</p>
    </v-card-text>
  </v-card>
</template>

<script>
import getAuthConfig from "../getAuthConfig"; //= getAuthConfig();
// const { sdkConf, portals: infoMap } = getAuthConfig();
export default {
  props: ["id", "isKenxPortal"],
  computed: {},
  data: () => ({
    sdkConf: getAuthConfig().sdkConf,
    infoMap: getAuthConfig().portals,
    childRef: null,
    targetOrigin: null,
    info: {
      title: "Kennect",
      color: "pink",
      description: "Default Description",
      uriKey: "KX_UI"
    }
  }),
  created() {
    // console.log(this.id);
    if (this.id && this.infoMap[this.id]) this.info = this.infoMap[this.id];
    // console.log(this.isShowPortal());
  },
  methods: {
    onClick() {
      const uri = this.sdkConf[this.info.uriKey];
      const pathArray = uri.split("/");
      const protocol = pathArray[0];
      const host = pathArray[2];
      this.targetOrigin = protocol + "//" + host;
      // console.log("clicked on ", this.info.title, "open", uri);
      const hash = `#load=attempt_auth_by_opener&opener=${window.location.origin}`;
      this.childRef = window.open(uri + hash, "_blank");

      // let payload = this.$store.getters["authStore/crosstabPayload"]
      let tabId = sessionStorage.getItem("tabId");
      // console.log('this.$store.state["authStore"].tabId :', this.$store.state['authStore'].tabId);

      if(!window.allChilds){
        window.allChilds = []
      }

      window.allChilds &&
        window.allChilds.push({
          name:host,
          logout: () => {
            this.childRef.postMessage(
              {
                request: "logout",
                tabId
              },
              this.targetOrigin
            );
          }
        });
      // this.childRef = window.open(uri+hash, "blank");
      let handler = event => {
        // console.log("message from child ", event);
        // we have  event.source, event.origin, event.data;
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
        if (event.origin === this.targetOrigin) {
          // approve and send the shit
          this.approve();
          window.removeEventListener("message", handler);
          // console.log(
          //   '%c window.removeEventListener("message", handler);',
          //   "color:yellow; font-weight:bold;font-size:20px"
          // );
        }
      };
      window.addEventListener("message", handler);
      // console.log(
      //   '%c window.addEventListener("message", handler);',
      //   "color:yellow; font-weight:bold;font-size:20px"
      // );

      // Setting Listener for to receive logout message from any child window
      let receiveLogoutMsgFromChild = event => {
        let tabId = sessionStorage.getItem("tabId");
        // CHECK
        if (event.origin === this.targetOrigin) {
          if (
            event.data.request === "logoutReqFromChild" &&
            tabId === event.data.tabId
          ) {
            // console.log("event[receiveLogoutMsgFromChild] :", event);

            // Logout all childs which are spawned from this parent window
            // console.log("this.$store.state :", this.$store.state["authStore"]);
            let accessToken = this.$store.state["authStore"].accessToken;
            // To avoid multiple child logout req from child windows
            if (accessToken && accessToken !== "") {
              this.$store.dispatch("authStore/logout");
            }
            // this.$emit("logoutReqFromChild")
            window.removeEventListener("message", receiveLogoutMsgFromChild);
            // console.log(
            //   '%c window.removeEventListener("message", receiveLogoutMsgFromChild);',
            //   "color:yellow; font-weight:bold;font-size:20px"
            // );
          }
        }
      };
      window.addEventListener("message", receiveLogoutMsgFromChild);
      // console.log(
      //   '%c window.addEventListener("message", receiveLogoutMsgFromChild);',
      //   "color:yellow; font-weight:bold;font-size:20px"
      // );
    },
    approve() {
      console.log("approving on ", this.info.title);
      // TODO: what if parent is not using authStore?? possible failure
      let payload = this.$store.getters["authStore/crosstabPayload"];
      this.childRef.postMessage(payload, this.targetOrigin);
    },
    isShowPortal() {
      let payload = this.$store.getters["authStore/crosstabPayload"];
      if (this.id == "incentive") {
        return false // For temporarily only
      }
      if (payload.mortal.type == 30) {
        if (this.id == "dx") {
          return (
            (payload.mortal.selfPrivileges.report) ||
            (payload.mortal.selfPrivileges.journey) ||
            (payload.mortal.selfPrivileges.scheme_builder)
          )
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>

<style>
</style>