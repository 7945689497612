<template>
  <!-- <div> -->
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header v-if="header" expand-icon="mdi-menu-down"
        >{{ header }}</v-expansion-panel-header
      >
      <v-expansion-panel-content class="customStyle">
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    header: String
  }
}
</script>

<style>
div.customStyle > div {
  padding: 8px !important;
}
</style>
