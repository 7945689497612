<template>
  <div class="main" style="height: 100%">
    <v-container
      grid-list-md
      text-xs-center
      style=""
      class="content-view-port plain-number-container"
    >
      <v-layout dense wrap class="content-view-port">
        <template v-for="(item, index) in chartConfig.main_big_number_config">
          <v-flex
            :style="`padding: ${padding_to_card} px`"
            v-bind="getCustomBreakpoint(index)"
            :key="index"
          >
            <v-card outlined width="auto" class="plain-number-content">
              <v-card-text>
                <div
                  class="icon-data"
                  v-if="
                    data[item.smallNumberField] &&
                    (item.isIcon || !!item.remarkToneFeild)
                  "
                  style="margin-bottom: 10px;"
                >
                  <div
                    :style="{
                      color: item.__$color__,
                    }"
                    style="
                      display: flex;
                      flex-direction: row;
                      text-align: center;
                      padding-top: 10px;
                      padding-left: 10px;
                    "
                  >
                    <div
                      class="growth-graph-svg"
                      v-show="item.isIcon || !!item.remarkToneFeild"
                    >
                      <SvgIcon
                        class="growth-svg"
                        :name="
                          remarkToneFeild(item) == true
                            ? 'line-graph-up'
                            : 'line-graph-down'
                        "
                        :style="{
                          fill: item.__$color__,
                        }"
                      />
                    </div>
                    <div
                      style="
                        margin-left: 14px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <SvgIcon
                        v-show="item.isIcon"
                        :style="{
                          fill: item.__$color__,
                        }"
                        :name="
                          remarkToneFeild(item) == true
                            ? 'arrow-up'
                            : 'arrow-down'
                        "
                      />
                      <!-- {{ setColor(data[item.smallNumberField], index) }} -->
                      <!-- {{
                          remarkToneFeild(item) == true
                            ? "arrow_downward"
                            : "arrow_upward"
                        }} -->
                      <span
                        style="margin-left: 4px"
                        :style="{
                          color: item.__$color__,
                        }"
                        >{{ data[item.smallNumberField]
                        }}{{ item.smallNumberSufix }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  :class="{
                    'plain-number-value-content':
                      data[item.smallNumberField] &&
                      !(item.isIcon || !!item.remarkToneFeild),
                  }"
                  :style="
                    data[item.smallNumberField] &&
                    (item.isIcon || !!item.remarkToneFeild)
                      ? 'margin-top:10px;padding-left:10px'
                      : ''
                  "
                >
                  <div class="plain-number-value">
                    <span>{{ item.prefix }}</span>
                    <span>
                      {{
                        $Chelper.modifyMainNumber(data[item.mainNumberField]) ||
                        0
                      }}</span
                    >
                    <span>{{ item.suffix }}</span>
                  </div>
                  <div class="plain-number-title">{{ item.title }}</div>
                  <div
                    v-if="
                      data[item.smallNumberField] &&
                      !(item.isIcon || !!item.remarkToneFeild)
                    "
                    :style="{
                      color: item.__$color__,
                    }"
                    style="font-weight: 600; font-size: 1.2rem"
                  >
                    <span
                      >{{ data[item.smallNumberField]
                      }}{{ item.smallNumberSufix }}</span
                    >
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
    <!--   </v-card> -->
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
let tableCellsPallete = () => [
  "#BF360C",
  "#D84315",
  "#E64A19",
  "#E65100",
  "#EF6C00",
  "#F57C00",
  "#F57F17", // red to orange , white text
  //[behind 7] : 35%  white bg till here| after this black. [13 ahead]
  // '#FFAB00', '#FFC400', '#FFD600', '#FFEA00', // orange to yellow :: dark
  "#FFAB00",
  "#FFC400",
  "#d6921e",
  "#cf8506", // orange to yellow :: dark --> (overwrite yellow with orange)
  "#9E9D24",
  "#689F38",
  "#558B2F",
  "#2E7D32", // yellow to green :: dark
  "#00897B",
  "#00ACC1",
  "#0097A7",
  "#0288D1",
  "#0277BD", // teal to blue  :: dark
];

export default {
  props: ["chartConfig", "dataProvider", "containerSpec"],
  data: () => ({
    data: {},
    config: {},
    padding_to_card: 12,
  }),
  // inject: ["containerSpec"],
  created() {
    this.data = (this.dataProvider && this.dataProvider[0]) || {};
    this.chartConfig.main_big_number_config.forEach((item, index) => {
      item.__$color__ = this.setColor(this.data[item.smallNumberField], index);
    });
  },
  computed: {
    breakpoint() {
      console.log("breakpoint.name", this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    remarkToneFeild(item) {
      console.log("Logged Output: remarkToneFeild -> item", item);
      let remarkNumberCheck = Math.sign(item.remarkToneFeild);
      if (
        (item.colorScale == "bad_good" && remarkNumberCheck >= 0) ||
        (item.colorScale == "good_bad" && remarkNumberCheck < 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCustomBreakpoint(index) {
      let grid_column_end = 1 + this.containerSpec.x + this.containerSpec.w;
      if (grid_column_end <= 4) {
        this.padding_to_card = 4;
        return {};
      }
      let defaultBreakpoint = {
        xs6: true,
        md4: true,
      };
      index++;
      let length = this.chartConfig.main_big_number_config.length;
      if (["xs", "sm"].includes(this.breakpoint)) {
        let lastElementLength = length % 2;
        if (lastElementLength === 1 && index === length) {
          return {
            xs12: true,
          };
        } else {
          return defaultBreakpoint;
        }
      } else {
        let lastElementLength = length % 3;
        if (lastElementLength === 1 && index === length) {
          return {
            xs12: true,
          };
        } else if (
          lastElementLength === 2 &&
          [length, length - 1].includes(index)
        ) {
          return {
            xs6: true,
          };
        } else {
          return defaultBreakpoint;
        }
      }
    },
    setColor(value, index) {
      let range = this.chartConfig.main_big_number_config[index].range;
      let colorScale =
        this.chartConfig.main_big_number_config[index].colorScale;
      let N = (range && range.toString().split(",").map(Number)) || [];
      let colors = [tableCellsPallete()[0]];
      let colorPelletsLength = tableCellsPallete().length;
      let jumper = Math.floor(colorPelletsLength / (N.length + 1));
      let jumperSum = 0;
      for (let i = 0; i < N.length; i++) {
        jumperSum += jumper;
        colors.push(tableCellsPallete()[jumperSum]);
      }
      if (colorScale === "good_bad") {
        colors = colors.reverse();
      }
      if (value < N[0]) {
        return colors[0];
      } else if (value > N[N.length - 1]) {
        return colors[colors.length - 1];
      } else {
        for (let i = 0; i <= N.length; i++) {
          if (value <= N[i]) return colors[i];
        }
      }
    },
  },
};
</script>

<style scoped>
.plain-number-container {
  padding: unset;
  /* max-height: 249px; */
  /* padding: unset; */
  /* max-width: 400px; */
  /* min-width: 249px; */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */
  /* top: 50%;
  margin-left: 5%; */
  /* left: 50%; */
  /* transform: translateY(-50%); */
}
.plain-number-content {
  border-radius: 20px;
  height: 100%;
  /* justify-content: center; */
  /* display: flex !important; */
}
.plain-number-value-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.plain-number-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e417f;
  margin-bottom: 10px;
}
.plain-number-title {
  margin-bottom: 10px;
  color: #a5aebd !important;
  font-size: 1rem;
}
.card-shadow {
  background: #d6d6d6;
}
.card-shadow:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s;
}
.v-list {
  display: block;
  /* padding: 8px 0; */
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}
.growth-graph-svg {
  width: 28px;
  height: 28px;
}
.growth-svg {
  width: 28px;
  height: 28px;
  border-radius: 8px;
}

svg.arrow_upward {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
