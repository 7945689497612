var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",staticStyle:{"height":"100%"}},[_c('v-container',{staticClass:"content-view-port plain-number-container",attrs:{"grid-list-md":"","text-xs-center":""}},[_c('v-layout',{staticClass:"content-view-port",attrs:{"dense":"","wrap":""}},[_vm._l((_vm.chartConfig.main_big_number_config),function(item,index){return [_c('v-flex',_vm._b({key:index,style:(("padding: " + _vm.padding_to_card + " px"))},'v-flex',_vm.getCustomBreakpoint(index),false),[_c('v-card',{staticClass:"plain-number-content",attrs:{"outlined":"","width":"auto"}},[_c('v-card-text',[(
                  _vm.data[item.smallNumberField] &&
                  (item.isIcon || !!item.remarkToneFeild)
                )?_c('div',{staticClass:"icon-data",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","text-align":"center","padding-top":"10px","padding-left":"10px"},style:({
                    color: item.__$color__,
                  })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isIcon || !!item.remarkToneFeild),expression:"item.isIcon || !!item.remarkToneFeild"}],staticClass:"growth-graph-svg"},[_c('SvgIcon',{staticClass:"growth-svg",style:({
                        fill: item.__$color__,
                      }),attrs:{"name":_vm.remarkToneFeild(item) == true
                          ? 'line-graph-up'
                          : 'line-graph-down'}})],1),_c('div',{staticStyle:{"margin-left":"14px","display":"flex","align-items":"center"}},[_c('SvgIcon',{directives:[{name:"show",rawName:"v-show",value:(item.isIcon),expression:"item.isIcon"}],style:({
                        fill: item.__$color__,
                      }),attrs:{"name":_vm.remarkToneFeild(item) == true
                          ? 'arrow-up'
                          : 'arrow-down'}}),_c('span',{staticStyle:{"margin-left":"4px"},style:({
                        color: item.__$color__,
                      })},[_vm._v(_vm._s(_vm.data[item.smallNumberField])+_vm._s(item.smallNumberSufix))])],1)])]):_vm._e(),_c('div',{class:{
                  'plain-number-value-content':
                    _vm.data[item.smallNumberField] &&
                    !(item.isIcon || !!item.remarkToneFeild),
                },style:(_vm.data[item.smallNumberField] &&
                  (item.isIcon || !!item.remarkToneFeild)
                    ? 'margin-top:10px;padding-left:10px'
                    : '')},[_c('div',{staticClass:"plain-number-value"},[_c('span',[_vm._v(_vm._s(item.prefix))]),_c('span',[_vm._v(" "+_vm._s(_vm.$Chelper.modifyMainNumber(_vm.data[item.mainNumberField]) || 0))]),_c('span',[_vm._v(_vm._s(item.suffix))])]),_c('div',{staticClass:"plain-number-title"},[_vm._v(_vm._s(item.title))]),(
                    _vm.data[item.smallNumberField] &&
                    !(item.isIcon || !!item.remarkToneFeild)
                  )?_c('div',{staticStyle:{"font-weight":"600","font-size":"1.2rem"},style:({
                    color: item.__$color__,
                  })},[_c('span',[_vm._v(_vm._s(_vm.data[item.smallNumberField])+_vm._s(item.smallNumberSufix))])]):_vm._e()])])],1)],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }