<template>
  <div>
    <App v-if="auth && !isErrorFetchingReportDetails" />
    <div v-if="!auth || isErrorFetchingReportDetails">
      <!-- <kxAuthByPopup v-if="!auth && loadType == 'normal'" /> -->
      <div class="redirectCSS">
        <v-btn class="loginBtn" @click="goToKennect">Login to Kennect</v-btn>
        <b
          >You need to login into Kennect to access this resource. Please click
          on above button to login into Kennect System</b
        >
      </div>
    </div>
    <div class="errror-message" v-if="auth && isErrorFetchingReportDetails">
      <h3>{{ fetchingReportErrorMessage }}</h3>
    </div>
    <kxAuthByOpener
      @onAuthSuccess="onAuthSuccess"
      v-if="!auth && (loadType=='attempt_auth_by_opener' || loadType=='attempt_auth_by_DSI')"
      :type="loadType=='attempt_auth_by_opener' ? 'authenticate' : loadType=='attempt_auth_by_DSI' ? 'requestPayloadForSpecialLogin' : '' "
      :opener="opener"
    />
    <v-snackbar
      class="snackbarCSS"
      :timeout="snackbarTime"
      :value="true"
      color="blue-grey"
      absolute
      right
      v-model="showSnackbar"
      top
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <ConfirmDialog />
    <div class="progressBar" v-show="loading">
      <v-progress-circular
        indeterminate
        :size="50"
        :width="5"
        color="#2a51e8"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import {
  createNamespacedHelpers as cnh,
  mapState as mapRootState,
  mapMutations as mapRootMutations,
  mapActions as mapRootActions
} from 'vuex'
const {
  mapMutations: mapReportBuilderMutations,
  mapState: mapReportBuilderState,
  mapActions: mapReportBuilderActions
} = cnh('ReportBuilder')
import App from './App.vue'
import authUtils from '@common-ui/authenticator/api/authUtils'
import kxBrowserUtils from '@common-ui/authenticator/api/browserUtils'
import getAuthConfig from '@common-ui/authenticator/getAuthConfig'
import ConfirmDialog from '../src/components/Common/ConfirmDialog'
export default {
  components: {
    App,
    ConfirmDialog
  },
  data: () => ({
    opener: '',
    showSnackbar: false,
    isErrorFetchingReportDetails: false,
    fetchingReportErrorMessage: '',
    iskxAuthByOpener: false,
    hashParams: {},
  }),
  async created() {
    this.hashParams = kxBrowserUtils.extractHash(window)
    console.log('intent ====== ', this.hashParams)
    this.$store.subscribe((mutation, state) => {
      // console.log(`[mutation==============]: `, mutation);
      if (
        mutation.type === 'openSnackbar' ||
        mutation.type === 'openSnackbarWithTime'
      ) {
        this.showSnackbar = !!state.snackbarText
      } else if (mutation.type === 'closeSnackbar') {
        this.showSnackbar = false
      }
    })

    if (this.hashParams && (this.hashParams.load === 'attempt_auth_by_opener'|| this.hashParams.load === "attempt_auth_by_DSI" )) {
      window.location.hash = ''
      this.opener = this.hashParams.opener
      this.$store.commit('setAuthLoadType', this.hashParams.load)
    } else {
      // try auth by sessionStorage
      this.$store.commit('setAuthLoadType', 'attempt_auth_by_session_storage')
      await this.$store.dispatch('setAuthFromSessionStorage')
      // window.location = '' redirect to auth.kennectweb.com
      await authUtils.setLogoutReqFromParent(this.$store)
      const reportId = sessionStorage.getItem("reportId")
      if(reportId){
        await this.getReportDetailsFromReportId(reportId)
      } else {
        this.fetchAllElements()
      }
    }
    // TODO: Manage Privileges below
    // this.$router.beforeEach((to, from, next) => {
    //   // if (this.$store.getters.userType === 20 || this.$store.getters.allowedRoutesPath.includes(to.path)) {
    //   if (this.$store.getters.allowedRoutesPath.includes(to.path)) {
    //     // let progress = this.$Progress
    //     // progress.start()
    //     setTimeout(() => {
    //       // progress.finish()
    //     }, 60000)
    //     next()
    //   } else {
    //     // console.log('-- Blocked route')
    //     next({ name: 'unauthorized', query: { pathName: to.name } })
    //   }
    // })

    // this.$router.afterEach((to, from) => {
    //   // let progress = this.$Progress
    //   // progress.finish()
    // })
  },
  mounted() {},
  computed: {
    ...mapRootState([
      'snackbarText',
      'snackbarTime',
      'auth',
      'report',
      'loading'
    ]),
    ...mapReportBuilderState([
      'allElementsOfAReportFetchedFromDB',
      'currentLayoutData'
    ]),
    auth: function () {
      return this.$store.getters.auth
    },
    loadType: function () {
      return this.$store.state.authLoadType
    }
  },
  methods: {
    ...mapRootMutations([
      'openSnackbarWithTime',
      'closeSnackbar',
      'setReport',
      'toggleLoader',
      'setAllElementsForImport'
    ]),
    ...mapReportBuilderActions([
      'getAllElementsOfACurrentLayout',
      'setLayoutSupplement'
    ]),
    ...mapReportBuilderMutations([
      'setAllFetchedElementsOfAReport',
      'setAllFetchedElementsOfACurrentLayout',
      'setAllLayoutsData',
      'mapAllElementsOfACurrentLayout',
      'setSelectedContainerIValue'
    ]),
    ...mapRootActions(['getReportDetails', 'getAllElements', 'setReportSupplement']),
    logOut() {
      this.$store.dispatch('logout')
    },
    async onAuthSuccess(payload) {
      console.log('==on auth success==', payload)
      await this.$store.dispatch('setAuth', payload)
      let selectedReportId = this.hashParams.reportId || sessionStorage.getItem('reportId')
      console.log(!!this.auth, selectedReportId)
      if (!!this.auth && selectedReportId && selectedReportId != 'undefined') {
        this.$router.push('/compose-report')
        await this.getReportDetailsFromReportId(selectedReportId)
      } else {
         await this.fetchAllElements()
      }
    },
    goToKennect() {
      const URI = getAuthConfig().sdkConf['KX_UI']
      window.location = URI
    },
    async fetchAllElements() {
      const resp = await this.getAllElements({
        reportId: this.report._id || false,
        allElems: true
      })
      console.log('resp :>> ', resp)
      if (resp.ok) {
        this.setAllElementsForImport(resp.result)
      }
    },
    async getReportDetailsFromReportId(reportId) {
      try {
        console.log('*****', reportId == 'undefined', {
          reportId,
        })
        if (!reportId || reportId == 'undefined') {
          throw new Error('No Report ID provided')
        }
        this.toggleLoader()
        const res = await this.getReportDetails({ reportId })
        console.log('res :>> ', res)
        if (res.ok) {
          this.setReport(res.result)
          this.$InstallCtx("install", {theme: this.report.props && this.report.props.themeSelected || "theme_1", amchart: true})
          this.setAllLayoutsData({
            mode: 'listAllLayouts',
            payload: res.result
          })
          await this.setReportSupplement()
          await this.getAllElementsOfASelectedLayout()
          await this.setLayoutSupplement()
          await this.fetchAllElements()
        } else {
          if (res.message) {
            this.fetchingReportErrorMessage = res.message
            throw res
          }
          throw new Error('Failed to fetch report')
        }
        this.toggleLoader()
      } catch (error) {
        this.toggleLoader()
        if (reportId && reportId !== 'undefined')
          this.isErrorFetchingReportDetails = true
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt:
            'Failed to Load Report. Contact Kennect. Error: ' + error.message,
          time: 4000
        })
      }
    },
    async getAllElementsOfASelectedLayout() {
      try {
        // const payload = {
        //   owner_report_info: {
        //     // reportId: "60ad0bc13c82a54d90857f12"
        //     reportId: this.report._id
        //   }
        // }
        // const res = await this.getAllElementsOfACurrentLayout(payload)
        const res = await this.getAllElementsOfACurrentLayout()
        console.log('res :>> ', res)
        if (res.ok) {
          // this.setAllFetchedElementsOfAReport(res.result)
          this.setAllFetchedElementsOfACurrentLayout(res.result)
          this.mapAllElementsOfACurrentLayout()
          console.log(`[currentLayoutData]: `, this.currentLayoutData)
          if (
            this.currentLayoutData &&
            this.currentLayoutData.structure &&
            this.currentLayoutData.structure.containers &&
            this.currentLayoutData.structure.containers.length
          )
            this.setSelectedContainerIValue(0) // set default selected container
        } else {
          if (res.message) throw res
          throw new Error('Failed to fetch report')
        }
      } catch (error) {
        this.isErrorFetchingReportDetails = true
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt:
            'Failed to Load Report. Contact Kennect. Error: ' + error.message,
          time: 4000
        })
      }
    }
  }
}
</script>

<style lang="scss">
.redirectCSS {
  margin: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loginBtn {
  background-color: #00e5ff !important;
  border-color: #00e5ff !important;
  color: white !important;
  margin-bottom: 1%;
}
.snackbarCSS {
  align-items: center !important;
  margin-top: -16% !important;
  z-index: 100 !important;
}
.progressBar {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
}
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #e9e9e9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #979797;
  width: 3px;
  height: 5px;
  /* border-radius: 2em 2px 1px 2em; */
}
.errror-message {
  margin: 0 auto;
  width: 40vw;
  height: fit-content;
  padding: 20px;
  background-color: rgb(255 0 0 / 70%);
  border-radius: 10px;
}
.v-tabs .v-tab{
  text-transform: capitalize !important;
}
.v-btn{
  text-transform: capitalize !important;
}
.v-tabs-slider{
  background-color: $royal-blue !important;
  border-color: $royal-blue !important;
}
.v-tab--active{
  color: $royal-blue !important;
}
</style>