import loginNow from './components/loginNow';
import miniProfile from './components/miniProfile';
import portalsList from './components/portalsList';
import authStore from './authStore';
import AuthByOpener from "./components/AuthByOpener"
import AuthByPopup from "./components/AuthByPopup"
import resetPassword from "./components/resetPassword"
// import Vuex from 'vuex';

export default {
    install(vue, opts) {
        console.log('Installing the Common kennect auth plugin')
        // Fun will happen here
        if (!opts || !opts.store) {
            console.error('AuthStore will not be registered. Please pass the store in Vue.use(authenticator, { store }); ')
        } else {
            opts.store.registerModule('authStore', authStore);
        }
        // if(opts.buildModeOverride){
        // }
        vue.component('kxPortalsList', portalsList)
        vue.component('kxLoginNow', loginNow)
        vue.component('kxMiniProfile', miniProfile)
        vue.component('kxAuthByOpener', AuthByOpener)
        vue.component('kxAuthByPopup', AuthByPopup)
        vue.component('kxResetPassword', resetPassword)
    }
}

/*
Assumptions in parent project

Vuetify @
Material Icons as font

insire parent store/index.js

    import authStore from '@common-ui/authenticator/authStore'

    //and do the following change

    new Vuex.Store({  ...,
        modules: { authStore, ... }
    }

*/