
// REFENCE FROM: https://mui.com/customization/default-theme/

// don't forget to update gradient color in palette while creating a complete new theme

// const tFire = {
//     // pallette copied from bet.js
//     name: "fire",
//     colors: [
//         "#ff4d83", // floresc pink
//         "#2c3e50", // dark gray
//         "#30bfef", // blue
//         "#ff9334", // orange
//         "#ffca45", // yellow
//         "#0cccaf", // green
//         "#c2f83d", // lemon
//         "#f7565B", // red s1
//         "#9b59b6", // voilet
//         //  "#e74c3c", "#8e44ad", "#1abc9c", "#ff9334", "#8ed1b7", "#9394d9"
//     ],
//     font: [],
//     fontSize: []
// }

// const TWater = {
//     // pallette2
//     name: "water",
//     colors: [
//         // second pallette
//         "#7ab9bf",
//         "#3b6978",
//         "#74929c",
//         "#204051",

//         "#a0c1b8",
//         "#709fb0",
//         "#726a95",

//         "#4e476c",
//         "#9be3de",
//         "#405b6a",
//         "#cdc1d7",

//         // "#e7dfd5", 
//     ],
//     font: [],
//     fontSize: []
// }

//common theme property
const palette = {
    "primary": "#3399FF",
    "divider": "#132F4C",
    "background": {
        "default": "#001E3C",
        "paper": "#0A1929"
    },
    "common": {
        "black": "#1D1D1D",
        "white": "#fff"
    },
    "text": {
        "primary": "#fff",
        "secondary": "#AAB4BE",
        "disabled": "rgba(255, 255, 255, 0.5)",

    },
    "grey": {
        "50": "#F3F6F9",
        "100": "#EAEEF3",
        "200": "#E5E8EC",
        "300": "#D7DCE1",
        "400": "#BFC7CF",
        "500": "#AAB4BE",
        "600": "#7F8E9D",
        "700": "#46505A",
        "800": "#2F3A45",
        "900": "#20262D",
        "A100": "#f5f5f5",
        "A200": "#eeeeee",
        "A400": "#bdbdbd",
        "A700": "#616161"
    },
    "error": {
        "50": "#FFF0F1",
        "100": "#FFDBDE",
        "200": "#FFBDC2",
        "300": "#FF99A2",
        "400": "#FF7A86",
        "500": "#FF505F",
        "600": "#EB0014",
        "700": "#C70011",
        "800": "#94000D",
        "900": "#570007",
        "main": "#EB0014",
        "light": "#FF99A2",
        "dark": "#C70011",
        "contrastText": "#fff"
    },
    "success": {
        "50": "#E9FBF0",
        "100": "#C6F6D9",
        "200": "#9AEFBC",
        "300": "#6AE79C",
        "400": "#3EE07F",
        "500": "#21CC66",
        "600": "#1DB45A",
        "700": "#1AA251",
        "800": "#178D46",
        "900": "#0F5C2E",
        "main": "#1DB45A",
        "light": "#6AE79C",
        "dark": "#1AA251",
        "contrastText": "rgba(0, 0, 0, 0.87)"
    },
    "warning": {
        "50": "#FFF9EB",
        "100": "#FFF4DB",
        "200": "#FFEDC2",
        "300": "#FFE4A3",
        "400": "#FFD980",
        "500": "#FCC419",
        "600": "#FAB005",
        "700": "#F1A204",
        "800": "#DB9A00",
        "900": "#8F6400",
        "main": "#F1A204",
        "light": "#FFE4A3",
        "dark": "#F1A204",
        "contrastText": "rgba(0, 0, 0, 0.87)"
    },
    "secondary": {
        "main": "#ce93d8",
        "light": "#f3e5f5",
        "dark": "#ab47bc",
        "contrastText": "rgba(0, 0, 0, 0.87)"
    },
    "info": {
        "main": "#29b6f6",
        "light": "#4fc3f7",
        "dark": "#0288d1",
        "contrastText": "rgba(0, 0, 0, 0.87)"
    },
    "action": {
        "active": "#fff",
        "hover": "rgba(255, 255, 255, 0.08)",
        "hoverOpacity": 0.08,
        "selected": "rgba(255, 255, 255, 0.16)",
        "selectedOpacity": 0.16,
        "disabled": "rgba(255, 255, 255, 0.3)",
        "disabledBackground": "rgba(255, 255, 255, 0.12)",
        "disabledOpacity": 0.38,
        "focus": "rgba(255, 255, 255, 0.12)",
        "focusOpacity": 0.12,
        "activatedOpacity": 0.24
    }
}
const typography = {
    "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
    "fontFamilyCode": "Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace",
    "h1": {
        "fontSize": "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
        "fontWeight": 800,
        "lineHeight": 1.1142857142857143
    },
    "h2": {
        "fontSize": "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
        "fontWeight": 800,
        "lineHeight": 1.2222222222222223,
        "color": "#E5E8EC"
    },
    "h3": {
        "fontSize": "2.25rem",
        "lineHeight": 1.2222222222222223,
        "letterSpacing": 0,
        "fontWeight": 400
    },
    "h4": {
        "fontSize": "1.75rem",
        "lineHeight": 1.5,
        "letterSpacing": 0,
        "fontWeight": 400
    },
    "h5": {
        "fontSize": "1.5rem",
        "lineHeight": 1.5,
        "letterSpacing": 0,
        "fontWeight": 400
    },
    "h6": {
        "fontSize": "1.25rem",
        "lineHeight": 1.5,
        "letterSpacing": 0,
        "fontWeight": 500
    },
    "button": {
        "textTransform": "initial",
        "fontWeight": 700,
        "letterSpacing": 0,
        "fontSize": "0.875rem",
        "lineHeight": 1.75
    },
    "subtitle1": {
        "fontSize": "1.125rem",
        "lineHeight": 1.3333333333333333,
        "letterSpacing": 0,
        "fontWeight": 500,
    },
    "body1": {
        "fontSize": "1rem",
        "lineHeight": 1.5,

        "fontWeight": 400
    },
    "body2": {
        "fontSize": "0.875rem",
        "lineHeight": 1.5,
        "letterSpacing": 0,
        "fontWeight": 400
    },
    "caption": {
        "display": "inline-block",
        "fontSize": "0.75rem",
        "lineHeight": 1.5,

        "fontWeight": 700,
    },
    "htmlFontSize": 16,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold": 700,
    "subtitle2": {
        "fontWeight": 500,
        "fontSize": "0.875rem",
        "lineHeight": 1.57
    },
    "overline": {
        "fontWeight": 400,
        "fontSize": "0.75rem",
        "lineHeight": 2.66,
        "textTransform": "uppercase"
    }
}

//Themes object starts here
const theme1 = {
    name: "theme_1",
    //amchart related property
    colors: [
        "#43BCCD",
        "#FF7D05",
        "#51459E",
        "#F9C80E",

        "#DF711B",
        "#FDE49C",
        "#5C7AEA",

        "#E6E6E6"
    ],
    font: [],
    fontSize: [],
    //

    "components": {
        "button": {
            "primary": {
                "fontSize": "14"
            },
            "secondary": {
                "border": "soild 1px black"
            },
        }
    },

    "palette": { ...palette },
    "shape": {
        "borderRadius": 10
    },
    "typography": { ...typography },
    "nprogress": {
        "color": "#3399FF"
    },
    "gradient": {
        "color-1": '#43BCCD',
        "color-2": '#51459E',
        "color-3": '#FF7D05',
        "color-4": '#F9C80E',
        "color-5": '#DF711B',
        "color-6": '#FDE49C',
        "color-7": '#5C7AEA',
        "color-8": '#E6E6E6',
        "color-9": '#ffffff85',
    },

    primaryColor: "red",
    secondaryColor: "black",
    backgroundColor: "backg",
    warningColor: "",
    errorColor: "",
    successColor: "",
    infoColor: ""
}
const theme3 = {
    name: "theme_3",
    //amchart related property
    colors: [
        "#39BFE4",
        "#3E98DA",
        "#432E93",
        "#475FB5",

        "#BE79C2",
        "#C996CC",
        "#9D84B7",
        "#6858B1",
    ],
    font: [],
    fontSize: [],
    //


    "components": {
        "button": {
            "primary": {
                "fontSize": "14"
            },
            "secondary": {
                "border": "soild 1px black"
            },
        }
    },


    "palette": { ...palette },
    "shape": {
        "borderRadius": 10
    },
    "typography": { ...typography },
    "nprogress": {
        "color": "#3399FF"
    },
    "gradient": {
        "color-1": '#39BFE4',
        "color-2": '#475FB5',
        "color-3": '#BE79C2',
        "color-4": '#3E98DA',
        "color-5": '#432E93',
        "color-6": '#C996CC',
        "color-7": '#9D84B7',
        "color-8": '#6858B1',

    },

    primaryColor: "red",
    secondaryColor: "black",
    backgroundColor: "",
    warningColor: "",
    errorColor: "",
    successColor: "",
    infoColor: ""
}

const theme4 = {
    name: "theme_4",
    //amchart related property
    colors: [
        // second pallette
        "#F8B195",
        "#6C5B7B",
        "#C06C84",
        "#F67280",

        "#900C3F",
        "#FBC687",
        "#654062",

        "#CCAFAF",
        "#D54062",
        "#E7DFD5",

        // "#e7dfd5", 
    ],
    font: [],
    fontSize: [],
    //


    "components": {
        "button": {
            "primary": {
                "fontSize": "14"
            },
            "secondary": {
                "border": "soild 1px black"
            },
        }
    },


    "palette": { ...palette },
    "shape": {
        "borderRadius": 10
    },
    "typography": { ...typography },
    "nprogress": {
        "color": "#3399FF"
    },
    "gradient": {
        "color-1": '#F67280',
        "color-2": '#C06C84',
        "color-6": '#F8B195',
        "color-4": '#6C5B7B',
        "color-5": '#900C3F',
        "color-3": '#FBC687',
        "color-7": '#654062',
        "color-8": '#CCAFAF',
        "color-9": '#D54062',
        "color-10": '#E7DFD5',
    },

    primaryColor: "red",
    secondaryColor: "black",
    backgroundColor: "",
    warningColor: "",
    errorColor: "",
    successColor: "",
    infoColor: ""
}


const theme = {
    // tFire,
    // TWater,
    theme_1: theme1,
    theme_4: theme4,
    theme_3: theme3
}
export default function getThemes(tname) {
    return theme[tname]
}