<template>
  <!-- always follow kebab case for file and folder name. so change all file name wich is camel case like Email to email -->
  <div class="bi-table" v-if="!chartConfig.hideElementIfNoData">
    <link
      href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
      rel="stylesheet"
    />
    <!-- toolbar row for export button -->
    <div class="bi-table-toolbar">
      <span
        ><h2 v-if="chartConfig.title">{{ chartConfig.title }}</h2></span
      >
      <div class="display-flex-centered">
        <v-menu
            v-if="windowSizeIdx < 2"
            bottom
            left
            :close-on-content-click="false"
            v-model="closeActionMenu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-for="(item, key) in tableActions">
                
                  <v-list-group
                    v-if="item.items && item.items.length"
                    :key="key"
                    color="primary"
                    no-action
                    class="listGroup"
                  >
                    <template slot="prependIcon">
                        <SvgIcon :name="item.icon"/>
                    </template>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="child in item.items"
                      :key="child.value"
                      @click="actionTaken(key,child.value)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                      </v-list-item-content>
                     </v-list-item>
                  </v-list-group>


                  <v-list-item v-else :key="item.title" @click="actionTaken(key,item.value)">
                    <v-list-item-icon style="margin-right: 10px !important">
                      <SvgIcon :name="item.icon"/>
                    </v-list-item-icon>

                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item>
              </template>
              
            </v-list>
        </v-menu>
        <!-- <button class="info-btn">?</button> -->
        <!-- <button class="export-btn common-btn">
          Export
          <SvgIcon name="export" />
        </button>
        <button class="export-btn common-btn">
          Email
          <SvgIcon name="email" />
        </button>
        <InfoButton /> -->
        <div v-if="chartConfig.downloadOn && chartConfig.downloadOn.length && windowSizeIdx >= 2">
          <div
            :class="
              chartConfig.downloadOn.length < 2 &&
              chartConfig.downloadOn[0] === 'mobile'
                ? 'd-flex d-sm-none'
                : chartConfig.downloadOn.length < 2 &&
                  chartConfig.downloadOn[0] === 'desktop'
                ? 'd-none d-lg-flex d-xl-none'
                : ''
            "
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :class="
                    'toolbar-btn my-4 mx-2 ' +
                    (windowSizeIdx >= 2 ? 'px-2' : 'px-0')
                  "
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  height="30px"
                  style="text-transform: capitalize"
                  :loading="loadingDownload"
                  :disabled="loadingDownload"
                >
                  {{ windowSizeIdx >= 2 ? "Export" : ""
                  }}<SvgIcon name="export" style="margin: 0 4px" />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(option, i) in chartConfig.downloadOptions"
                  :key="i"
                  @click="download(option)"
                >
                  <v-list-item-title>{{ option.btnName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-btn
          :class="
            'toolbar-btn my-4 mx-2 ' + (windowSizeIdx >= 2 ? 'px-2' : 'px-0')
          "
          outlined
          height="30px"
          v-if="chartConfig.emailSelf && windowSizeIdx >= 2"
          style="text-transform: capitalize"
          :loading="loading"
          :disabled="loading"
          @click="email"
        >
          {{ windowSizeIdx >= 2 ? "Email" : ""
          }}<SvgIcon name="email" style="margin: 0 4px" />
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <!-- <ExportButton v-if="chartConfig.downloadOn.length" /> -->
        <!-- <EmailButton v-if="chartConfig.emailSelf" /> -->
      </div>
    </div>

    <div
      class="bi-table-action-bar bi-table-description"
      v-if="chartConfig.tableDescription"
    >
      <p>
        {{ chartConfig.decscriptionField }}
        <!-- {{ searchOnColumnFilters }} -->
      </p>
    </div>

    <div class="bi-table-action-bar">
      <div
        class="search-sort-switcher"
        :style="'flex-direction: ' + (windowSizeIdx <= 2 ? 'column' : 'row')"
      >
        <!-- <div class="bi-table-action-bar-content"> -->
          <!-- <div style="flex: 1"> -->
            <div
              class="switch-field"
              v-show="chartConfig.splitTable && chartConfig.splitConfig.length"
            >
              <template v-for="(spConf, spIdx) of chartConfig.splitConfig">
                <input
                  type="radio"
                  :key="spIdx + 'input'"
                  :id="spConf.title"
                  name="switch-one"
                  :value="spConf.title"
                  v-model="splitColumnType"
                />
                <label :key="spIdx + 'label'" :for="spConf.title">{{
                  spConf.title
                }}</label>
                <!-- </span> -->
              </template>
            </div>
          <!-- </div> -->

          <!-- <div class="mob-filter-view" v-if="windowSizeIdx <= 2">
            <button
              @click="showSearchOnColumn = !showSearchOnColumn"
              v-if="chartConfig.searchOnColumn"
            >
              <SvgIcon name="filter-funnel" />
            </button>
            <button @click="setcard = false">
              <SvgIcon name="list-view" :style="setcard ? 'fill: rgba(0, 0, 0, 0.87)': 'fill: #0C519A'"/>
            </button>
            <button @click="setcard = !setcard">
              <SvgIcon name="card-view" :style="setcard ? 'fill: #0C519A': 'fill: rgba(0, 0, 0, 0.87)'" />
            </button>
          </div> -->
        <!-- </div> -->

        <div class="bi-search-container" v-if="chartConfig.tableSearch">
          <v-select
            v-if="!chartConfig.searchOnColumn"
            class="column-multi-select"
            v-model="selectedColumn"
            :items="headers"
            :menu-props="{ maxHeight: '400' }"
            label="Select"
            multiple
            outlined
            single-line
            hide-details
            height="34px"
            flat
            solo
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selectedColumn.length - 1 }} other)
              </span>
            </template>
          </v-select>
          <!-- <select name="epsum" id="lorem">
            <option value="">Select All</option>
            <option
              v-for="header in headers"
              :key="header.value"
              :value="header.value"
            >
              {{ header.text }}
            </option>
          </select> -->

          <div class="search-bar-container" v-if="!chartConfig.searchOnColumn">
            <!-- <v-text-field
            label="Outlined"
            placeholder="Placeholder"
            outlined dense
          ></v-text-field> -->
            <!-- <input
              class="search-bar"
              type="text"
              placeholder="Search"
              v-model="searchText"
            /> -->
            <v-text-field
              label="Search"
              outlined
              single-line
              v-model="searchText"
              hide-details
              height="34px"
              flat
              solo
              class="custom-tf-search-bar"
            >
              <template v-slot:append>
                <SvgIcon name="search" />
              </template>
            </v-text-field>
            <!-- <SvgIcon name="search" /> -->
          </div>
        </div>
      </div>
      <div v-if="!(windowSizeIdx <= 2)" class="add-filter">
        <div @click="showSearchOnColumn = !showSearchOnColumn">
          <button
            class="add-filter-btn common-btn"
            v-if="chartConfig.searchOnColumn"
          >
            <SvgIcon name="filter" />
            Add Filters
          </button>
        </div>
        <button @click="setcard = false">
          <SvgIcon name="list-view" :style="setcard ? 'fill: rgba(0, 0, 0, 0.87)': 'fill: #0C519A'"/>
        </button>
        <button @click="setcard = !setcard">
          <SvgIcon name="card-view" :style="setcard ? 'fill: #0C519A': 'fill: rgba(0, 0, 0, 0.87)'" />
        </button>
      </div>
    </div>

    <!-- table start -->
    <div class="table-wrapper" v-show="!setcard">
      <table class="table-container">
        <thead v-if="!chartConfig.hideHeaders" class="table-header">
          <template v-if="chartConfig.extraVisualAid">
            <tr class="header-rows" v-for="i in uiHeaders.length" :key="i">
              <th
                v-for="(header, index) in uiHeaders[i - 1]"
                :rowspan="header.children === 0 ? uiHeaders.length : 1"
                :colspan="header.children > 0 ? header.children : 1"
                :key="header.text + header.children + header.value"
                :class="{
                  'header-column': true,
                  'border-right-header border-bottom-header': true,
                  'border-left-header': i === 1 && index === 0,
                  'border-top-header': i === 1,
                  'fixed-first-column-class':
                    i == 1 && index === 0 && chartConfig.fixedFirstColumn,
                }"
              >
                <div class="header-title" @click="onSort(header.value)">
                  {{ header.text }}
                  <SvgIcon
                    v-if="header.children === 0"
                    name="sort"
                    :class="
                      (currentSort.key == header.value && currentSort.type) ||
                      'asc'
                    "
                  />
                </div>
                <!-- for search box for seach on column -->
                <div style="display: flex; justify-content: center">
                  <div
                    class="header-search-bar"
                    style="display: flex; flex-direction: row"
                    v-if="
                      chartConfig.tableSearch &&
                      chartConfig.searchOnColumn &&
                      chartConfig.searchByColumn.includes(header.value) &&
                      showSearchOnColumn
                    "
                  >
                    <!-- <template v-for="(item, index) in searchByColumn"> -->
                    <input
                      class="search-bar-column"
                      type="text"
                      placeholder="Search"
                      v-model="searchOnColumnFilters[header.value]"
                      @input="searchColumns"
                    />
                    <SvgIcon name="search" />
                    <!-- </template> -->
                  </div>
                </div>
              </th>
            </tr>
          </template>
          <template v-else>
            <tr class="">
              <th
                v-for="(header, index) in headers"
                :key="header.value"
                :class="{
                  'table-cell-wrap-content header-column table-header': true,
                  'border-right-header': headers.length === index + 1,
                  'border-left-header': index === 0,
                  'fixed-first-column-class':
                    index === 0 && chartConfig.fixedFirstColumn,
                }"
              >
                <div class="header-title" @click="onSort(header.value)">
                  {{ header.text }}
                  <SvgIcon
                    v-if="header.value !== 'atch'"
                    name="sort"
                    :class="
                      (currentSort.key == header.value && currentSort.type) ||
                      'asc'
                    "
                  />
                </div>
                <div style="display: flex; justify-content: center">
                  <div
                    class="header-search-bar"
                    style="display: flex; flex-direction: row"
                    v-if="
                      chartConfig.tableSearch &&
                      chartConfig.searchOnColumn &&
                      chartConfig.searchByColumn.includes(header.value) &&
                      showSearchOnColumn
                    "
                  >
                    <!-- <template v-for="(item, index) in searchByColumn"> -->
                    <input
                      class="search-bar-column"
                      type="text"
                      placeholder="Search"
                      v-model="searchOnColumnFilters[header.value]"
                      @input="searchColumns"
                    />
                    <SvgIcon name="search" />
                    <!-- </template> -->
                  </div>
                </div>
              </th>
            </tr>
          </template>
        </thead>

        <tbody>
          <tr v-if="!dataProvider || dataProvider.length == 0" class="no-data">
            {{
              chartConfig.messageForNoData || "No Data"
            }}
          </tr>
          <template v-else v-for="(row, rowIdx) in displayedRows">
            <tr :key="rowIdx + 'row'" class="main-table-row">
              <td
                v-for="(header, hIdx) in headers"
                :key="row[header.value] + header.value"
                :class="{
                  'table-cell-wrap-content table-rows table-cell': true,
                  'first-col-text-color': hIdx === 0,
                  'border-top': rowIdx === 0 && chartConfig.hideHeaders,
                  'fixed-first-column-class':
                    hIdx === 0 && chartConfig.fixedFirstColumn,
                  'border-right-body': headers.length === hIdx + 1,
                  'drill-first-column': hIdx === 0 && !!isDrill,
                }"
                :style="
                  `background-color: #${
                    chartConfig.extraVisualAid
                      ? colorsForExtraVisualAid[
                          row[chartConfig.extraVisualAidColumns[0].field]
                        ]
                      : 'fff'
                  }` +
                  ';' +
                  (chartConfig.highlightRow && row._hilyt
                    ? highlightRowHandler(row._hilyt, hIdx, rowIdx)
                    : '')
                "
                @click="drillClicked(row, hIdx)"
              >
                <!-- {{ row[header.value] }} -->
                <div>
                  <div
                    v-if="
                      chartConfig.isAttachment &&
                      chartConfig.attachmentColumn === 'atch' &&
                      header.value === 'atch'
                    "
                  >
                    <v-btn
                      icon
                      @click.stop="
                        dialog = true;
                        carouselId = rowIdx + 'row';
                      "
                      ><v-icon>mdi-paperclip mdi-rotate-45</v-icon></v-btn
                    >
                    <v-dialog
                      v-model="dialog"
                      max-width="1200"
                      v-if="dialog && carouselId === rowIdx + 'row'"
                      ><v-card>
                        <v-card-actions class="mx-0 py-0"
                          ><v-spacer></v-spacer
                          ><v-btn icon large
                            ><v-icon>mdi-tray-arrow-down</v-icon></v-btn
                          ></v-card-actions
                        >
                        <v-carousel v-if="dialog">
                          <v-carousel-item
                            v-for="(item, i) in row[header.value]"
                            :key="i"
                            eager
                            ><v-img :src="item.src" height="100%" eager
                          /></v-carousel-item>
                        </v-carousel>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div
                    v-else-if="
                      chartConfig.downloadAttachment &&
                      chartConfig.downloadColumn === 'atch' &&
                      header.value === 'atch'
                    "
                  >
                    <v-btn icon><v-icon>mdi-download</v-icon></v-btn>
                  </div>
                  <div v-else-if="header.value !== 'atch'">
                    <label
                      :class="{
                        'color-indicator-chip':
                          `__color_indicator_${header.value}` in row,
                      }"
                      :style="`background-color: ${
                        `__color_indicator_${header.value}` in row
                          ? row[`__color_indicator_${header.value}`]
                          : undefined
                      }; color: ${
                        `__color_indicator_${header.value}_text` in row
                          ? row[`__color_indicator_${header.value}_text`]
                          : ''
                      };`"
                      >{{ row[header.value] }}</label
                    >
                  </div>
                </div>
              </td>
              <div v-show="row[chartConfig.rowSchema]" class="drop">
                <!--   to only show element if it includes nestedtabledata -->
                <button
                  @click="toggle(rowIdx)"
                  :style="{
                    transform:
                      'rotate( ' +
                      (opened.includes(rowIdx) ? '180' : '0') +
                      'deg )',
                    transition: 'transform 150ms ease',
                  }"
                >
                  <i class="arrow down"></i>
                </button>
                <!--     switches the value that is set to false to true and reverse -->
              </div>
            </tr>

            <template
              v-if="
                chartConfig.expandableSubTable &&
                chartConfig.hideHeaderSubTable &&
                opened.includes(rowIdx) &&
                row[chartConfig.rowSchema] &&
                row[chartConfig.rowSchema].length > 0
              "
            >
              <tr
                v-for="(subTableRow, subTableRowIdx) in row[
                  chartConfig.rowSchema
                ]"
                :key="subTableRowIdx + 'sub-table-row'"
              >
                <td
                  v-for="(header, hIdx) in headers"
                  :key="
                    subTableRow[header.value] + header.value + 'sub-table-cell'
                  "
                  :class="{
                    'table-cell-wrap-content table-rows table-cell sub-table-cell': true,
                    'first-col-text-color': hIdx === 0,
                    'fixed-first-column-class':
                      hIdx === 0 && chartConfig.fixedFirstColumn,
                    'border-right-body': headers.length === hIdx + 1,
                  }"
                >
                  {{ subTableRow[header.value] }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr
                :key="rowIdx + 'subtable-row'"
                v-if="
                  chartConfig.expandableSubTable &&
                  opened.includes(rowIdx) &&
                  row[chartConfig.rowSchema] &&
                  row[chartConfig.rowSchema].length > 0
                "
              >
                <td :colspan="headers.length" class="expandable-table-section">
                  <div>
                    <v-data-table
                      :headers="subheaders"
                      :items="row[chartConfig.rowSchema]"
                      :items-per-page="3"
                      :footer-props="{
                        itemsPerPageText: '',
                      }"
                      :mobile-breakpoint="0"
                      :class="{
                        'elevation-1 sub-table-vuetify': true,
                        'sub-table-fixed-first-col':
                          chartConfig.freezeRowSubTable,
                      }"
                    ></v-data-table>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <!-- table end -->

    <!-- card-view -->
    <div style="display: flex; flex-wrap: wrap">
      <div :class="expand[rowIdx] ? 'card' : 'card fixcard'" v-show="setcard" v-for="(row, rowIdx) in displayedRows" :key="rowIdx + 'row'">
        <div v-for="header in headers" :key="row[header.value] + header.value">
          <p style="color: #0c519a; font-size: 15px;margin-top: 8px;margin-bottom: 0px;padding-left: 7px;">
            {{ header.text === "Title" ? row[header.value] : "" }}
          </p>
        </div>
        <div :class="expand[rowIdx]  ? 'Readmore' : 'Readless'">
          <div class="container" style="display: flex; flex-flow: column wrap; height: 198px">
            <div v-for="header in headers" :key="row[header.value] + header.value">
              <div >
                <p style="color: grey; margin-bottom: 8px">{{ header.text }}</p>
                <!-- header.text === "Title" ? "" : header.text -->
                <p :class="{
                    'color-indicator-chip':
                      `__color_indicator_${header.value}` in row,
                  }"
                  style="color: black; margin-bottom: 8px"
                  :style="`background-color: ${
                    `__color_indicator_${header.value}` in row
                      ? row[`__color_indicator_${header.value}`]
                      : undefined
                  }; color: ${
                    `__color_indicator_${header.value}_text` in row
                      ? row[`__color_indicator_${header.value}_text`]
                      : ''
                  };`">
                  {{ row[header.value] }}
                </p>
                <!--    header.text === "Title" ? "" : row[header.value] -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            style="
              color: #0c519a;
              font-size: 15px;
              margin-top: 0px;
              margin-bottom: 0px;
              padding-left: 7px;
            "
            @click=" togglerr(rowIdx)"
          >
          <!-- <v-icon style="font-size:16px;">{{ toggleExpandAndCollapse(rowIdx) == true ? 'arrow_upward' : 'arrow_downward' }}</v-icon> -->
            {{ expand[rowIdx]   ? "Read Less" : "Read more" }}
          </button>
        </div>
      </div>
    </div>
    <!-- end card view -->
    <!-- Pagination start -->
    <div
      class="table-pagination bi-table-toolbar"
      v-if="dataProvider.length > 5"
    >
      <select v-model="paginationConf.perPage">
        <option v-for="op of paginationConf.pageOptions" :key="op" :value="op">
          {{ op }}
        </option>
        <!--pending to handle -->
      </select>

      <div class="pn-btn-grp">
        <span
          >Show {{ paginationConf.from }} - {{ paginationConf.to }} of
          {{ dataProvider.length }}</span
        >
        <span
          ><button
            v-if="paginationConf.page != 1"
            @click="paginationConf.page--"
          >
            <SvgIcon name="pagination" /></button
        ></span>
        <span
          ><button
            @click="paginationConf.page++"
            v-if="paginationConf.page < paginationConf.pages.length"
            class="next-btn"
          >
            <SvgIcon name="pagination" /></button
        ></span>
      </div>
    </div>
    <!-- Pagination end -->
  </div>
</template>

<script>
const EXTRACT_KEYS_LIMIT = 20;

export default {
  props: [
    "dataProvider",
    "chartConfig",
    "windowSizeIdx",
    "getEmailDownload",
    "drillConf",
  ],
  data() {
    return {
      loading: false,
      loadingDownload: false,
      display: [],
      clicked: null,
      expand: {},
      toggle1: false,
      setcard: false,
      sortContent: [],
      filteredColumnContent: [],
      selectedColumn: [],
      searchText: "",
      showSearchOnColumn: false,
      searchOnColumnFilters: {},
      currentSort: { key: "any", asc: true, type: "" },
      dialog: false,
      model: 0,
      carouselId: "",
      splitColumnType: "",
      paginationConf: {
        page: 1,
        from: "",
        to: "",
        perPage: 5,
        pages: [],
        pageOptions: [5, 10, 20],
      },
      rowHighlightData: {
        bgColor: "",
        textColor: "",
        borderColor: "",
        borderThickness: "2px",
        borderDefault: "1px",
      },

      dropdown: false,
      drop: [0],
      opened: [],

      headers: [],
      test: null,
      subheaders: [],

      // Extra Visual Header Variables Starts here
      visHeaders: null,
      uiHeaders: null,
      colorsForExtraVisualAid: [],
      isDrill: false,

      //We can add more menu to tableActions
      tableActions: {
        email: {
          icon: 'email',
          items: [],
          value: 'email',
          title: 'Email',
        },
        export: {
          icon: 'export',
          items: [],
          value: 'export',
          title: 'Export',
        },
        toggleTableView: {
          icon: 'card-view',
          items: [],
          value: 'toggleTableView',
          title: 'Card View',
        }
      },
      closeActionMenu: false
    };
  },
  methods: {
    toggleExpandAndCollapse(rowIndex) {
      if(this.expand[rowIndex]){
        return true
      }else{
        return false
      }
    },
    actionTaken(type, action){
      // console.log('action', action);
      if(action === 'toggleTableView'){
        this.setcard = !this.setcard;
        this.tableActions.toggleTableView.title = this.setcard ? 'List View' : 'Card View'
        this.tableActions.toggleTableView.icon = this.setcard ? 'list-view' : 'card-view'
      }
      if(type === 'export')
        this.download({type: action})
      if(type === 'email')
        this.email()

      this.closeActionMenu = !this.closeActionMenu
    },
    //Expand table toggler
    drillClicked(selectedRow, hIdx) {
      if (!this.isDrill || hIdx !== 0) {
        return;
      }
      // console.log(`[selectedRow]: `, selectedRow);
      // console.log(`[this.drillConf]: `, this.drillConf);
      this.$emit("onChartRowSelect", { selectedRow });
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      // console.log(index, this.opened);
      if (index > -1) {
        this.opened.splice(index, 1); //The splice method returns the removed element from the array
      } else {
        this.opened.push(id);
      }
      // console.log("I have been clicked");
      // console.log("opened==", this.opened);
      this.drop.pop();
      // console.log(this.drop);
      this.drop.push(id - 1);
      // console.log(this.drop);

      this.dropdown = !this.dropdown;
    },
    togglerr(value) {
      let isValueavailable = this.expand[value]
      this.$set(this.expand,value,!this.expand[value])
      if(isValueavailable){
        console.log('hdsdsdsdsd')
        delete this.expand[value]
      }
      // else{
      //   this.expand = value;
      //  this.$set(this.expand,value,true)
      // }
      console.log("I have been clicked");


        console.log(this.expand)
      console.log(value)
      // this.expand[value] = !this.expand[value]
    },
    //For rowHighlighting to set data to default after 20sec
    changeStyle() {
      setTimeout(() => {
        this.rowHighlightData = {
          bgColor: "",
          textColor: "",
          borderColor: "rgb(128, 128, 128, 0.2)",
          borderThickness: "0px",
          borderDefault: "1px",
        };
      }, 20000);
    },
    highlightRowHandler(_hilytVal, hIdx, rowIdx) {
      if (_hilytVal === 1) {
        return `background-color: ${this.chartConfig.colorOfHighlightRow}; color: ${this.chartConfig.colorOfHighlightText}`;
      } else if (_hilytVal === 10) {
        return `background-color: ${this.rowHighlightData.bgColor}; color: ${this.rowHighlightData.textColor}`;
      } else if (_hilytVal === 3) {
        let styles = "";
        let hltColor = this.chartConfig.colorOfHighlightRow;

        if (hIdx === 0) styles += `border-left: 2px solid ${hltColor};`;
        if (hIdx === this.headers.length - 1)
          styles += `border-right: 2px solid ${hltColor};`;

        styles += `border-bottom: 2px solid ${hltColor}; border-top: 2px solid ${hltColor};`;
        return styles;
      } else if (_hilytVal === 13) {
        let styles = "";
        let borderDefault = this.rowHighlightData.borderDefault;
        let borderColor = this.rowHighlightData.borderColor;
        if (hIdx === 0)
          styles += `border-left: ${borderDefault} solid ${borderColor};`;
        if (hIdx === this.headers.length - 1)
          styles += `border-right: ${borderDefault} solid ${borderColor};`;

        styles += `border-bottom: ${borderDefault} solid ${borderColor}; border-top: ${this.rowHighlightData.borderThickness} solid ${borderColor}`;
        return styles;
      }
    },
    //
    sortColumnsByMonth(array) {
      let tempArray = [].concat(array);
      let monthlist = [
        /^jan/gi,
        /^feb/gi,
        /^mar/gi,
        /^apr/gi,
        /^may/gi,
        /^jun/gi,
        /^jul/gi,
        /^aug/gi,
        /^sept/gi,
        /^oct/gi,
        /^nov/gi,
        /^dec/gi,
        /^ytd/gi,
      ];
      let filteredbyMonth = {};
      let filteredbyMonthYear = [];
      let finalResult = [];
      for (let i = 0; i < array.length; i++) {
        monthlist.forEach((el, idx) => {
          let regex = new RegExp(monthlist[idx]);
          if (regex.test(array[i])) {
            if (/\d/.test(array[i])) {
              filteredbyMonthYear.push(array[i]);
            } else {
              filteredbyMonth[array[i]] = idx;
            }
          }
          tempArray = tempArray.filter((v) => {
            return !regex.test(v);
          });
        });
      }
      if (Object.keys(filteredbyMonth).length) {
        finalResult = Object.keys(filteredbyMonth).sort(function (a, b) {
          return filteredbyMonth[a] - filteredbyMonth[b];
        });
      }

      if (Object.keys(filteredbyMonthYear).length) {
        let _YTD = finalResult.length ? finalResult : [];
        finalResult = filteredbyMonthYear
          .sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime();
          })
          .concat(_YTD);
      }
      return tempArray.concat(finalResult);
    },

    //download starts
    async download(option) {
      this.loadingDownload = true;
      await this.getEmailDownload({
        ecName: this.chartConfig.ecName,
        as:
          option.type === "xls"
            ? this.chartConfig.extraVisualAid
              ? "vis_xlsx"
              : "xlsx"
            : option.type,
        title: this.chartConfig.title,
        otherParams: this.chartConfig.extraVisualAid
          ? {
              ...this.getHeader(this.headers),
              extraVisualAidColumns: this.chartConfig.extraVisualAidColumns,
            }
          : {},
      });

      this.loadingDownload = false;
    },

    async email() {
      this.loading = true;
      await this.getEmailDownload({
        ecName: this.chartConfig.ecName,
        as: this.chartConfig.extraVisualAid ? "email_vis_xlsx" : "email_xlsx", //``,
        title: this.chartConfig.title,
        mailBodyText: this.chartConfig.mailBodyText,
      });
      this.loading = false;
    },

    //sorting start
    numericSort(arr, key, asc) {
      arr.sort((item1, item2) => {
        let a = !isNaN(item1[key]) ? Number(item1[key]) : 0;
        let b = !isNaN(item2[key]) ? Number(item2[key]) : 0;
        return (a - b) * (asc ? 1 : -1);
      });
      return arr;
    },
    nonNumericSort(arr, key, asc) {
      arr.sort((item1, item2) => {
        let a = item1[key].toLowerCase().trim();
        let b = item2[key].toLowerCase().trim();
        return (a > b ? 1 : -1) * (asc ? 1 : -1);
      });
      return arr;
    },
    orderBy(arr, key, asc) {
      // console.log("orderBy:", key)
      if (asc == null) asc = true;
      let numbersArray = [],
        numIdx = 0;
      let nonNumericArray = [],
        charIdx = 0;
      for (let vIdx = 0; vIdx < arr.length; vIdx++) {
        if (arr[vIdx][key] ? !isNaN(arr[vIdx][key]) : true) {
          numbersArray[numIdx++] = arr[vIdx];
        } else if (isNaN(arr[vIdx][key])) {
          nonNumericArray[charIdx++] = arr[vIdx];
        }
      }
      let numericSorted = this.numericSort(numbersArray, key, asc);
      // console.log(
      //   "Logged Output ~ file: advance.vue ~ line 415  ~ numericSorted",
      //   numericSorted
      // );
      let sorted = numericSorted.concat(
        this.nonNumericSort(nonNumericArray, key, asc)
      );
      // console.log(
      //   "Logged Output ~ file: advance.vue ~ line 422  ~ sorted",
      //   sorted
      // );
      return sorted;
    },
    sortByOtherColumn(arr, key, byColumn, asc) {
      // console.log("sortByOtherColumn", key, byColumn)
      if (asc == null) asc = true;
      let numbersArray = [],
        numIdx = 0;
      let nonNumericArray = [],
        charIdx = 0;
      for (let vIdx = 0; vIdx < arr.length; vIdx++) {
        if (arr[vIdx][byColumn] ? !isNaN(arr[vIdx][byColumn]) : true) {
          numbersArray[numIdx++] = arr[vIdx];
        } else if (isNaN(arr[vIdx][byColumn])) {
          nonNumericArray[charIdx++] = arr[vIdx];
        }
      }
      let sorted = this.numericSort(numbersArray, byColumn, asc).concat(
        this.nonNumericSort(nonNumericArray, byColumn, asc)
      );
      return sorted;
    },
    onSort(sortKey) {
      // console.log("===================", sortKey);
      if (!sortKey || sortKey === "atch") {
        return;
      }

      if (this.currentSort.key === sortKey) {
        // this.currentSort.key
        // console.log(">>>>>>>>>>");
        this.currentSort.asc = !this.currentSort.asc;
      }
      this.currentSort.key = sortKey;
      this.currentSort.type = !this.currentSort.asc ? "asc" : "dsc";
      let params = this.currentSort;
      sessionStorage.setItem("Sort", JSON.stringify(this.currentSort));
      if (this.dataProvider.length) {
        let checkForcolumnsMapping =
          this.chartConfig.isSortColumnByOtherColumn &&
          this.chartConfig.columnsMappingForSort.find(
            (el) => el.target === params.key
          );
        if (checkForcolumnsMapping && checkForcolumnsMapping.key) {
          /* Initialize */
          this.sortContent = this.sortByOtherColumn(
            this.dataProvider,
            params.key,
            checkForcolumnsMapping.key,
            params.type == "asc"
          );
          // console.log(this.sortContent, "-----------------");
        } else {
          /* Initialize */
          this.sortContent = this.orderBy(
            this.dataProvider,
            params.key,
            params.type == "asc"
          );
        }
      }
    },

    //search starts here
    searchColumns() {
      if (
        this.chartConfig.tableSearch &&
        this.chartConfig.searchOnColumn &&
        this.chartConfig.searchByColumn.length
      ) {
        let temp = [];
        let arrKeys = Object.keys(this.searchOnColumnFilters).filter(
          (v) => !!this.searchOnColumnFilters[v]
        );
        // console.log(arrKeys);
        for (const row of this.dataProvider) {
          let flag = false;
          for (const kk of arrKeys) {
            if (
              String(row[kk])
                .toLowerCase()
                .includes(
                  this.searchOnColumnFilters[kk].toString().toLowerCase()
                )
            ) {
              flag = true;
            } else {
              flag = false;
              break;
            }
          }
          if (flag) {
            temp.push(row);
          }
        }
        this.filteredColumnContent = [...temp];
        // console.log(this.filteredColumnContent);
      }
    },
    setPages() {
      this.paginationConf.pages = [];
      let numberOfPages = Math.ceil(
        this.dataProvider.length / this.paginationConf.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.paginationConf.pages.push(index);
      }
      // console.log(this.paginationConf.pages);
    },

    //Main pagination
    paginate(data) {
      let page = this.paginationConf.page;
      let perPage = this.paginationConf.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      this.paginationConf.from = from + 1;
      //this handles range of page to show, it should not exceed available rows
      if (to > this.dataProvider.length)
        this.paginationConf.to = this.dataProvider.length;
      else this.paginationConf.to = to;

      return data.slice(from, to);
    },

    //To make headers readable
    makeStringReadable(s) {
      return s.replace(/[_]/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
    },

    extractKeys(dataArray) {
      let keysCollection = {};
      for (
        let idx = 0;
        idx <= EXTRACT_KEYS_LIMIT || idx <= dataArray.length;
        idx++
      ) {
        let obj = dataArray[idx];
        Object.assign(keysCollection, obj);
      }
      return Object.keys(keysCollection).filter(
        (el) => !el.startsWith("_") && el != this.chartConfig.rowSchema
      );
    },

    buildHeader(columns) {
      this.headers = [];
      columns.forEach((el) => {
        this.headers.push({
          text: this.makeStringReadable(el),
          value: el,
        });
      });
    },

    buildSubTableHeader(columns) {
      this.subheaders = [];
      columns.forEach((el) => {
        this.subheaders.push({
          text: this.makeStringReadable(el),
          value: el,
        });
      });
    },

    setupSplitColumn() {
      let columns = this.chartConfig.splitConfig[0].columns;
      if (this.splitColumnType === this.chartConfig.splitConfig[1].title) {
        columns = this.chartConfig.splitConfig[1].columns;
      }
      this.buildHeader(columns);
    },

    // color indicator function
    tableCellsPallete() {
      return [
        "#BF360C",
        "#D84315",
        "#E64A19",
        "#E65100",
        "#EF6C00",
        "#F57C00",
        "#F57F17", // red to orange , white text
        //[behind 7] : 35%  white bg till here| after this black. [13 ahead]
        // '#FFAB00', '#FFC400', '#FFD600', '#FFEA00', // orange to yellow :: dark
        "#FFAB00",
        "#FFC400",
        "#d6921e",
        "#cf8506", // orange to yellow :: dark --> (overwrite yellow with orange)
        "#9E9D24",
        "#689F38",
        "#558B2F",
        "#2E7D32", // yellow to green :: dark
        "#00897B",
        "#00ACC1",
        "#0097A7",
        "#0288D1",
        "#0277BD", // teal to blue  :: dark
      ];
    },

    getColorofColorIndicator(
      colorIndicatorConfig,
      row,
      indexOfCurrentSortedRow,
      sortedTableDataLength
    ) {
      let data;
      let rangeString;
      if (colorIndicatorConfig.type === "percentile") {
        if (!!!colorIndicatorConfig.sourceColumnValue) {
          data = row[colorIndicatorConfig.column];
        } else {
          data = row[colorIndicatorConfig.sourceColumnValue];
        }

        data = ((indexOfCurrentSortedRow + 1) / sortedTableDataLength) * 100;
      } else {
        if (!!!colorIndicatorConfig.sourceColumnValue) {
          data = row[colorIndicatorConfig.column];
        } else {
          data = row[colorIndicatorConfig.sourceColumnValue];
        }
      }

      data = parseInt(data);

      // console.log("data: ", data);

      if (!!!colorIndicatorConfig.dynamicRangeColumn) {
        rangeString = colorIndicatorConfig.range;
      } else {
        rangeString = row[dynamicRangeColumn];
      }

      let rangeArray = rangeString.split(",");
      rangeArray = rangeArray.map((v, i) => parseFloat(v));

      // console.log("rangeArray: ", rangeArray);

      let colorsPallete =
        colorIndicatorConfig.colorScale === "good_bad"
          ? this.tableCellsPallete().reverse()
          : this.tableCellsPallete();

      // console.log("colorPallete", colorsPallete);

      let totalRangeCount = Math.round(
        colorsPallete.length / (rangeArray.length + 2)
      );

      // console.log("totalRangeCount", totalRangeCount);
      let colorPosition = 0;

      for (let i = 0; i < rangeArray.length; i++) {
        // console.log("loop rangeArray[i]", rangeArray[i]);
        if (data <= rangeArray[i] && i === 0) {
          colorPosition = 0;
          // console.log("loop condition met", colorPosition);
          break;
        } else if (data < rangeArray[i] && data > rangeArray[i - 1]) {
          colorPosition = i;
          // console.log("loop condition met", colorPosition);
          break;
        } else if (data >= rangeArray[i] && i === rangeArray.length - 1) {
          colorPosition = i;
          // console.log("loop condition met", colorPosition);
          break;
        } else {
          // console.log("Not found searching in second round");
        }
      }
      return {
        chipColor: this.hexToRgbA(
          colorsPallete[(colorPosition + 1) * totalRangeCount]
        ),
        textColor: colorsPallete[(colorPosition + 1) * totalRangeCount],
      };
    },
    hexToRgbA(hex) {
      var c;
      // @Shivam u can do to just by adding `4d` to hex;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          ",0.3)"
        );
      }
      throw new Error("Bad Hex");
    },

    addColorIndicatorProperties() {
      this.chartConfig.colorIndicatorData.forEach((colorConf) => {
        let sortedArray = [...this.dataProvider];
        if (colorConf.type === "percentile") {
          let cc = colorConf.sourceColumnValue || colorConf.column;
          sortedArray.sort(function (a, b) {
            return a[cc] - b[cc];
          });
        }
        for (let i = 0; i < sortedArray.length; i++) {
          let row = sortedArray[i];
          row[`__color_indicator_${colorConf.column}`] =
            this.getColorofColorIndicator(
              colorConf,
              row,
              i,
              sortedArray.length
            ).chipColor;
          row[`__color_indicator_${colorConf.column}_text`] =
            this.getColorofColorIndicator(
              colorConf,
              row,
              i,
              sortedArray.length
            ).textColor;
        }
      });
    },

    buildExtraVisualAidHeaders() {
      this.colorsForExtraVisualAid =
        this.chartConfig.extraVisualAidColumns[0].colors.split(",");

      const { visHeaderTree, uiHeaderTree, fetchValueSequence } =
        this.getHeader(this.headers);
      this.visHeaders = visHeaderTree;
      this.uiHeaders = uiHeaderTree;
      let headers = [];
      fetchValueSequence.forEach((element) => {
        let f = this.headers.find((el) => el.value == element);
        headers.push(f);
      });
      this.headers = headers;
      // console.log(this.visHeaders);
      console.log("uiHeaders", this.uiHeaders);
      // console.log(fetchValueSequence);
      // console.log(this.headers);
      // console.log(`[headers]: `, headers);
    },
    //To get headers based on config
    setInitialConfig() {
      this.setPages();

      //for sub table(expandable)
      if (
        this.chartConfig.expandableSubTable &&
        !this.chartConfig.hideHeaderSubTable &&
        this.chartConfig.isExpandableTableColumns
      ) {
        this.buildSubTableHeader(this.chartConfig.expandableTableColumns);
      } else if (this.chartConfig.expandableSubTable) {
        let columns = [];
        for (let row of this.dataProvider) {
          if (
            row[this.chartConfig.rowSchema] &&
            row[this.chartConfig.rowSchema].length
          ) {
            columns = this.extractKeys(row[this.chartConfig.rowSchema]);
            break;
          }
        }
        // console.log("sub header columns==", columns);
        this.buildSubTableHeader(columns);
      }

      if (
        this.chartConfig.expandableSubTable &&
        this.chartConfig.defaultExpandAll
      ) {
        this.dataProvider.forEach((row, idx) => {
          if (
            row[this.chartConfig.rowSchema] &&
            row[this.chartConfig.rowSchema].length
          ) {
            this.opened.push(idx);
          }
        });
      }
      // console.log("=========opened defalut=====", this.opened);
      //

      //For search
      if (this.chartConfig.tableSearch && this.chartConfig.searchOnColumn) {
        // it will come from config
        this.chartConfig.searchByColumn.forEach((el) => {
          this.searchOnColumnFilters[el] = "";
        });
      }
      //

      const config = this.chartConfig;
      if (config.isColumnSelected) {
        this.buildHeader(config.columns);
      } else if (config.selectAllColumnDynamically) {
        let columns = this.extractKeys(this.dataProvider);
        if (config.SortColumnByMonthInSelectColumnDynamically) {
          columns = this.sortColumnsByMonth(columns);
        }
        this.buildHeader(columns);
      } else if (config.splitTable) {
        this.splitColumnType = config.splitConfig[0].title;
        this.setupSplitColumn();
      }

      if (this.chartConfig.isColorIndicationForcolumns) {
        this.addColorIndicatorProperties();
      }

      if (this.chartConfig.extraVisualAid) {
        this.buildExtraVisualAidHeaders();
      }

      if (config.numberOfRowsToDispaly != undefined) {
        this.paginationConf.perPage = config.numberOfRowsToDispaly;
        this.paginationConf.pageOptions = [
          this.paginationConf.perPage * 1,
          this.paginationConf.perPage * 2,
          this.paginationConf.perPage * 4,
        ];
      }

      //Highlight row initial setup
      if (config.highlightRow) {
        this.rowHighlightData = {
          bgColor: config.colorOfHighlightRow,
          textColor: config.colorOfHighlightText,
          borderColor: config.colorOfHighlightRow,
          borderThickness: "2px",
          borderDefault: "2px",
        };
      }
      console.log(`[this.drillConf]: `, this.drillConf);
      if (this.drillConf && Object.keys(this.drillConf).length) {
        this.isDrill = !!this.drillConf.layoutId;
      }
      console.log(`[  this.isDrill]: `, this.isDrill);

      // Table Menu list update for mobile view based on config
      if(this.chartConfig.downloadOptions && this.chartConfig.downloadOptions.length){
        this.chartConfig.downloadOptions.forEach(downloadOption => {
          this.tableActions.export.items.push({
            title: downloadOption.btnName,
            value: downloadOption.type
          })
        })
      }

    },

    // Extra Visual Aid methods starts here

    getHeader(headerObject) {
      // console.log(typeof headerObject);
      let arr = [];
      headerObject.forEach((el) => {
        arr.push(el.value);
      });

      // holders
      let headerTree = [];
      let deepOb = {};

      // loop the data for extracting the header keys
      for (let i = 0; i < arr.length; i++) {
        const head = arr[i];

        // skip if private values found
        if (head.startsWith("_")) {
          continue;
        }

        // uncomment below one for dynamic header depth
        deepOb = this.createDeepObject(
          head,
          this.chartConfig.extraVisualAidColumns[0].seperator,
          head,
          deepOb,
          i
        );

        // comment this if using dynamic depth of header
        // deepOb = this.createTwoLevelObject(head, "|", head, deepOb, i);
      }

      let l = 0;
      let nextLevel = [];
      do {
        if (l === 0) {
          headerTree.push([
            ...Object.keys(deepOb).map((v) => {
              let children =
                typeof deepOb[v] === "object"
                  ? this.getChildrenLength(deepOb[v])
                  : 0;
              if (children > 0) {
                nextLevel.push(deepOb[v]);
              }
              return {
                text: v,
                value: deepOb[v],
                children,
              };
            }),
          ]);
          if (nextLevel.length > 0) {
            l++;
          }
        } else {
          if (nextLevel.length > 0) {
            headerTree[l] = [];
            let tempNextElement = [];
            for (let i = 0; i < nextLevel.length; i++) {
              const element = nextLevel[i];
              // console.log(element);
              headerTree[l].push(
                ...Object.keys(element).map((v) => {
                  let children =
                    typeof element[v] === "object"
                      ? this.getChildrenLength(element[v])
                      : 0;
                  if (children > 0) {
                    tempNextElement.push(element[v]);
                  }
                  return {
                    text: v,
                    value: element[v],
                    children,
                  };
                })
              );
            }
            nextLevel = tempNextElement;
            if (nextLevel.length > 0) {
              l++;
            }
          } else {
            break;
          }
        }
        //   console.log(nextLevel);
      } while (nextLevel.length > 0);

      // console.log(deepOb);
      // console.log(headerTree);

      let fetchValueSequence = this.getChildrensArray(
        deepOb,
        this.chartConfig.extraVisualAidColumns[0].seperator
      );
      return {
        visHeaderTree: deepOb,
        uiHeaderTree: headerTree,
        fetchValueSequence,
      };
    },

    createDeepObject(string, separator, value, object, round) {
      var parts = string.split(separator);
      var last = parts[parts.length - 1];
      var tree = object;
      // console.log(round);
      // console.log("parts", parts);

      let previousKey;
      let previousState;

      var node = parts.reduce(function (memo, current) {
        // console.log("memo", JSON.stringify(memo));
        // console.log("current", JSON.stringify(current));
        // console.log("memo[current]", JSON.stringify(memo[current]));
        // console.log("previousKey", previousKey);
        // console.log("befor modification");
        if (!memo[current] || previousState) {
          if (previousKey) {
            if (current === last) {
              previousState[last] = value;
            } else {
              if (!previousState[current]) {
                previousState[current] = {};
              }
              previousState = previousState[current];
            }
          } else {
            if (current === last) {
              memo[last] = value;
            } else {
              memo[current] = {};
              previousState = memo[current];
            }
          }
        } else {
          // console.log("memo[current] is not null");
          previousState = memo[current];
        }
        previousKey = current;
        // console.log("after modification");
        // console.log("memo", JSON.stringify(memo));
        // console.log("memo[current]", JSON.stringify(memo[current]));
        // console.log("\n");
        return memo;
      }, tree);

      // console.log(JSON.stringify(node));
      // node[last] = value;
      // console.log(JSON.stringify(node));
      // console.log("\n\n\n");
      return tree;
    },

    getChildrenLength(obj) {
      let length = 0;
      Object.keys(obj).forEach((v) => {
        let flag = typeof obj[v] === "object";
        if (flag) {
          length += this.getChildrenLength(obj[v]);
        } else {
          length++;
        }
        // console.log(length);
      });
      return length;
    },

    getChildrensArray(obj, seperator = "|", previous = false) {
      return [].concat.apply(
        [],
        Object.keys(obj).map((v) => {
          if (typeof obj[v] === "object") {
            // console.log(v);
            return this.getChildrensArray(
              obj[v],
              seperator,
              `${previous ? previous + seperator : ""}${v}`
            );
          } else {
            // console.log(v);
            return `${previous ? previous + seperator : ""}${v}`;
          }
        })
      );
    },
  },
  created() {
    // console.log("=======dataProvider in props==", this.dataProvider);

    // console.log("=======chartConfig in props==", this.chartConfig);
    this.setInitialConfig();
  },
  watch: {
    "paginationConf.perPage"() {
      this.paginationConf.page = 1;
      this.setPages();
    },
    splitColumnType() {
      this.setupSplitColumn();
    }
  },
  mounted() {
    this.changeStyle();
  },
  computed: {
    // headers() {
    //   let s = new Set();

    //   this.dataProvider.forEach((data) => {
    //     for (let f in data) {
    //       s.add(f);
    //     }
    //   });
    //   return Array.from(s).map((a) => {
    //     return a;
    //   });
    // },
    displayedRows: {
      get() {
        // console.log("computed");
        // console.log(">>>>>>>>.", this.selectedColumn)
        if (
          this.chartConfig.tableSearch &&
          !this.chartConfig.searchOnColumn &&
          this.searchText
        ) {
          let searchedContent = this.dataProvider.filter((item) => {
            let props = [];
            if (this.searchText && this.selectedColumn.length) {
              for (let col of this.selectedColumn) {
                // props = [item[col]];
                if (item[col]) {
                  props.push(item[col]);
                }
              }
            } else {
              props = Object.values(item);
            }
            // console.log("props", props);
            return props.some(
              (prop) =>
                (!this.searchText && prop != "") ||
                (typeof prop === "string"
                  ? prop
                      .toLowerCase()
                      .includes(this.searchText.toString().toLowerCase())
                  : prop.toString(10).includes(this.searchText))
            );
          });
          // console.log(searchedContent);
          return this.paginate(searchedContent);
        } else if (this.filteredColumnContent.length) {
          return this.paginate(this.filteredColumnContent);
        } else if (this.sortContent.length) {
          // console.log(this.sortContent, "##############")
          return this.paginate(this.sortContent);
        } else {
          // console.log("no search text");
          return this.paginate(this.dataProvider);
        }
      },
      // set(value){
      //     this.displayedRows = val
      // }
    },
  },
};
</script>

<style lang="scss">
//Switcher
$border-tbl-header: 2px solid var(--gradient-color-1);
.table-header {
  height: max-content;
  border-top: 2px solid var(--gradient-color-1);
  border-bottom: 2px solid var(--gradient-color-1);
}
.border-left-header {
  border-left: $border-tbl-header;
}
.border-right-header {
  border-right: $border-tbl-header;
}
.border-top-header {
  border-top: $border-tbl-header;
}
.border-bottom-header {
  border-bottom: $border-tbl-header;
}
.drop {
  margin-left: -25px;
  margin-top: 8px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.card {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  transition: 0.3s;
  min-width: 280px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 12px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 360px) {
  .card {
    min-width: 305px;
  }
}

.fixcard {
  height: 190px;
}

.Readmore {
  height: 198px;
}

.Readless {
  height: 125px;
  overflow: hidden;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.switch-field {
  margin: 16px 10px 10px 0px;
  input {
    position: relative !important;
    clip: rect(0, 0, 0, 0);
    height: 0px;
    width: 0px;
    border: 0;
    overflow: hidden;
  }
  label {
    // background-color: #e4e4e4;
    width: 80px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.1s ease-in-out;
  }
  label:hover {
    cursor: pointer;
  }
  input:checked + label {
    background-color: #0c519a;
    color: white;
    border-radius: 50px;
  }
  label:first-of-type {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50px 0 0 50px;
  }
  label:last-of-type {
    border-radius: 0 50px 50px 0;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: -15px;
  }
}

// .switch-field

// .switch-field

// .switch-field

// .switch-field

// .switch-field
//

button {
  // background-color: white;
  border: none;
}
.table-wrapper {
  overflow-x: auto;
}

.bi-table {
  overflow: hidden;
  font-family: Roboto;
  font-size: 13px;
}
.display-flex-centered {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.table-container {
  min-width: 100%;
  width: max-content;
  margin: 14px auto;
  border-spacing: 0px;
}

.table-head {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.header-column {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  width: max-content;
  text-align: center;
  font-weight: bold;
}

.column-multi-select {
  width: 260px;
  margin-bottom: 8px !important;
  .v-input__slot {
    margin-bottom: 0;
    // width: 80%;
  }
  .v-chip.v-size--default {
    height: 28px !important;
  }
}
.table-rows {
  border-bottom: 1px solid rgb(128, 128, 128, 0.2);
}
.main-table-row:hover {
  > td {
    background-color: #e0e0e0 !important;
  }
}
.table-cell {
  padding: 0 14px;
  text-align: center;
  align-items: center;
  width: max-content;
}
.sub-table-cell {
  background-color: rgb(240, 240, 240) !important;
}
.sub-table-vuetify {
  position: sticky;
  top: 0;
  left: 0;
  width: 91vw;
  border-radius: 10px;
  background-color: #f8f7f7 !important;
  text-align: center;
  .v-data-table__wrapper {
    border-radius: 10px;
    tbody {
      tr:hover {
        background-color: #eefafc !important;
      }
    }
  }
  .v-data-footer {
    display: flex;
    justify-content: flex-end;
    .v-select__slot {
      display: none;
    }
  }
}
.sub-table-fixed-first-col {
  .v-data-table__wrapper {
    > table {
      > thead > tr > th:first-of-type {
        @extend .fixed-first-column-class;
        background-color: rgb(240, 240, 240) !important;
        border-right: 1px solid rgb(179, 179, 179) !important;
      }
      > tbody > tr > td:first-of-type {
        @extend .fixed-first-column-class;
        background-color: rgb(240, 240, 240) !important;
        border-right: 1px solid rgb(179, 179, 179) !important;
      }
    }
  }
}
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 150ms ease;
}
.expandable-table-section {
  border-left: 1px solid rgb(128, 128, 128, 0.2);
  border-right: 1px solid rgb(128, 128, 128, 0.2);
  border-bottom: 1px solid rgb(128, 128, 128, 0.2);
  padding: 10px 0;
}
.fixed-first-column-class {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: white;
  border-right: 2px solid rgb(128, 128, 128, 0.2);
}
.border-to-left {
  border-left: 2px solid #01a0e2;
}
.border-top {
  border-top: 1px solid rgba(128, 128, 128, 0.2);
}

.border-right-body {
  border-right: 1px solid rgb(128, 128, 128, 0.2);
}
.table-cell-wrap-content {
  word-wrap: break-word;
  max-width: 154px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.height-to-00 {
  height: 47px;
}
.first-col-text-color {
  border-left: 1px solid rgb(128, 128, 128, 0.2);
  color: #0c519a;
}
.header-column .header-title > img {
  width: 14px;
  height: 14px;
  margin-left: 10px;
}
// .table-cell >img {
//    width: 2px;
//   height: 2px;
// }
// .table-content {
//   width: 100%;
//   margin: 0 auto;
//   // text-align: left;
// }
img.asc {
  fill: gray;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
img.dsc {
  fill: gray;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.table-pagination {
  display: flex;
  justify-content: space-between;
  margin: 20px auto !important;
}
.table-pagination select {
  height: 24px;
  width: 50px;
  appearance: auto;
  border-style: solid;
}
.pn-btn-grp {
  button {
    height: 22px;
    margin: 0 4px;
    border-radius: 3px;
    width: 20px;
    box-shadow: 0px 3px 3px 0px #c4c4c4;
  }
  img {
    width: 8px;
    height: 10px;
  }
}
.next-btn img, .next-btn svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

$black: #1e1e1e;
$grey: #cccccc;
$white: #ffffff;

// .bi-table-action-bar-content {
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   // align-items: center;
//   margin-top: 20px;
//   background-color: $white;
// }

.bi-table-action-bar {
  // width: 92%;
  // margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-indicator-chip {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 25px;
  border: 2px solid white;
  word-wrap: initial;
  font-weight: bold;
}
.bi-search-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
// .bi-search-container select {
//   margin-left: 20px;
//   appearance: auto;
//   border: 2px solid rgb(0 0 0 / 20%);
//   width: 150px;
//   // font-size: 14px;
//   padding: 2.5px;
// }
// .search-bar {
//   height: 40px;
//   margin-left: 20px;
//   border-style: revert;
//   width: 180px;
//   padding: 3px;
//   border: 1px solid rgb(0 0 0 / 20%);
// }

.custom-tf-search-bar,
.column-multi-select {
  .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
  .v-input__control {
    min-height: 36px !important;
  }
}
.search-bar-column {
  // margin-left: 20px;
  border-style: revert;
  width: 120px;
  padding: 3px;
  border: 2px solid rgb(0 0 0 / 20%);
}
.header-search-bar img {
  position: relative;
  top: 7px;
  right: 26px;
}
.search-bar-container {
  margin-left: 10px;
}
.search-bar-container img {
  // position: relative;
  // top: 3px;
  // right: 26px;
  // width: 16px;
  // height: 16px;
  margin-top: 4px;
}

.common-btn {
  height: 28px;
  border-style: solid;
  border-radius: 6px;
  border: 2px solid rgb(0 0 0 / 20%);
  img {
    margin-left: 5px;
  }
}
.add-filter-btn {
  padding: 0 6px;
}
.list-view-btn {
  margin-left: 40px;
}
.info-btn {
  width: 30px;
  height: 30px;
  margin: 8px;
  color: #8c8c8c;
  font-size: 16px;
  border-radius: 100px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
}
.export-btn {
  margin: 10px;
  width: 80px;
}
.search-sort-switcher {
  display: flex;
  // align-items: center;
}
.bi-table-toolbar {
  // width: 92%;
  // margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bi-table-description {
  width: 40%;
  // margin: 0px 0px 14px 14px;
}
.add-filter {
  display: flex;
  align-items: center;
  button {
    margin: 0 10px;
  }
}
.listGroup .v-list-item__icon{
  margin-right: 10px !important
}

@media only screen and (max-width: 768px) {
  .bi-table-action-bar {
    width: 96%;
    margin: 2%;
    display: block;
  }
  .bi-table-toolbar {
    width: 96%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .common-btn {
      font-size: 0;
      width: 50px;
      margin: 0;
      border: none;
      img {
        width: 20px;
        height: 20px;
        margin-left: 0;
      }
    }
  }
  // .bi-table-action-bar-content {
  //   margin-bottom: 16px;
  // }
  .toolbar-btn {
    min-width: 40px !important;
  }
  .mob-filter-view {
    margin-right: 10px;
    button {
      margin: 0px 4px;
    }
  }
  .search-sort-switcher {
    align-items: flex-start;
  }
  // .search-bar {
  //   margin-left: 2%;
  //   width: 70%;
  // }
  // .bi-table-action-bar-content {
  //   width: 100%;
  // }
  .switch-field{
    margin: 8px 10px 10px 0px;
  }
  .search-bar-container {
    margin-left: 0;
  }
  .bi-search-container {
    flex-direction: column;
    // select {
    //   margin-left: 0px;
    //   border: 2px solid rgb(0 0 0 / 20%);
    //   width: 45%;
    // }
  }
  .info-btn {
    box-shadow: none;
    border: thin solid currentColor;
  }
  .table-cell-wrap-content {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .header-column {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .color-indicator-chip {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.drill-first-column {
  color: rgb(182, 48, 182) !important;
  font-weight: 500;
}
</style>
