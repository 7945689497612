<template>
  <div
    class="main"
    style="max-height: 249px; min-height: 155px; max-width: 700px"
  >
    <div
      class="un-container"
      :class="clientHeight < 200 ? 'un-container-border' : ''"
      :id="gaugeId"
    >
      <template v-if="chartConfig.gauge">
        <div class="main-ubn-content">
          <div
            class="number-ubt-area"
            :style="`${clientHeight == 249 ? 'top:20%' : ''}`"
          >
            <div class="number-ubt-area-title">{{ chartConfig.title }}</div>
            <h2 style="margin-top: 10px">
              {{ chartConfig.prefix }}
              {{ $Chelper.modifyMainNumber(mainNumber) || 0 }}
              {{ chartConfig.sufix }}
            </h2>
          </div>
          <div
            class="guage-area guage-area-align"
            :key="showGuage"
            :style="`${clientHeight > 200 ? 'top:15%;' : ''}${
              clientWidth == 700 ? 'left:40%' : ''
            }`"
          >
            <vue-gauge
              :refid="gaugeId + chartConfig.title + 'uid'"
              :options="options"
              :chartWidth="options.chartWidth"
            ></vue-gauge>
          </div>
        </div>
      </template>
      <template v-if="!chartConfig.gauge">
        <div class="main-ubn-content-simple">
          <div class="number-ubt-area-simple">
            <h3>{{ chartConfig.title }}</h3>
            <h1 style="margin-top: 10px">
              {{ chartConfig.prefix }}
              {{ $Chelper.modifyMainNumber(mainNumber) || 0 }}
              {{ chartConfig.sufix }}
            </h1>
          </div>
          <div
            class="progress-linear"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            <v-progress-linear
              class="progress-color"
              color="#ffffff85"
              height="25"
              reactive
              rounded
              :value="Math.ceil(progress_bar_value)"
            >
              <strong
                >{{ chartConfig.progress_bar_title }}
                {{ Math.ceil(progress_bar_value) }}%</strong
              >
            </v-progress-linear>
          </div>
          <div class="extra-circle-2">
            <div class="extra-circle"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VueGauge from "vue-gauge";
// let GaugeChart = require("../../../node_modules/vue-gauge/assets/bundle");
export default {
  props: ["chartConfig", "dataProvider", "windowSizeIdx"],
  components: { VueGauge },
  mounted() {
    let doc = document.getElementById(this.gaugeId);
    console.log(
      `[doc.clientWidth]: `,
      // doc,
      doc.clientWidth,
      doc.clientHeight,
      this.windowSizeIdx
    );
    this.clientWidth = doc.clientWidth;
    this.clientHeight = doc.clientHeight;
    let dataProvider = this.dataProvider[0];
    this.mainNumber = dataProvider[this.chartConfig.mainNumberField];

    this.progress_bar_value = dataProvider[this.chartConfig.progress_bar_value];
    if (!isNaN(this.progress_bar_value)) {
      this.progress_bar_value = Number(this.progress_bar_value);
    }
    this.progress_bar_value = Math.round(this.progress_bar_value);
    this.options.centralLabel = `${this.progress_bar_value.toString()}%`;
    this.options.needleValue = this.progress_bar_value;
    if (this.windowSizeIdx > 2) {
      if (doc.clientWidth < 500) {
        this.options.chartWidth = 230;
      }
    }
    console.log(
      `[this.options.chartWidth]: `,
      this.chartConfig.gauge,
      this.options.chartWidth
    );
    this.showGuage = true;
  },
  data() {
    return {
      gaugeId: "vue-guage-" + Math.random().toString().substr(2),
      mainNumber: "",
      progress_bar_value: 0,
      showGuage: false,
      clientHeight: this.windowSizeIdx > 2 ? 200 : 150,
      clientWidth: 700,
      options: {
        hasNeedle: true,
        arcColors: [
          "hsl(5, 99%, 50%)",
          "hsl(60, 100%, 47%)",
          "hsl(90, 100%, 40%)",
        ],
        // arcLabels: ["Poor", "Good", "Excellent"],
        rangeLabel: ["0", "100"],
        needleValue: this.progress_bar_value,
        arcDelimiters: [40, 60],
        chartWidth: this.windowSizeIdx > 2 ? 250 : 150,
        centralLabel: `${this.progress_bar_value}%`,
        needleColor: "white",
        outerNeedle: true,
      },
      // gaugeOptions: {
      //   hasNeedle: true,
      //   outerNeedle: true,
      //   needleColor: "white",
      //   needleValue: 20,
      //   needleUpdateSpeed: 1000,
      //   arcColors: [
      //     "hsl(5, 99%, 50%)",
      //     "hsl(60, 100%, 47%)",
      //     "hsl(90, 100%, 40%)",
      //   ],
      //   arcDelimiters: [40, 60],
      //   rangeLabel: ["0", "100"],
      //   rangeLabelFontSize: 15,
      //   centralLabel: `${this.progressBarValue}%`,
      //   chartWidth: 200,
      // },
    };
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
}

.un-container {
  width: 100%;
  height: 100%;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 10px;
  background-image: linear-gradient(
    45deg,
    var(--gradient-color-1) 31%,
    var(--gradient-color-3)
  );
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
  border-radius: 40px;
}

.un-container-border {
  border-radius: 30px;
}

.main-ubn-content-simple {
  height: 100%;
  padding: 0.5rem;
  display: block;
  padding: 1.5em;
}
.main-ubn-content {
  height: 100%;
  padding: 0.5rem;
  display: flex;
  padding: 10px;
}
.number-ubt-area {
  text-align: left;
  position: absolute;
  top: 28%;
  padding-left: 1em;
  color: white;
}
.number-ubt-area-title {
  font-size: 1.1rem;
  line-height: 30px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.number-ubt-area,
.guage-area {
  flex-grow: 1;
}

.guage-area-align {
  text-align: center;
  position: absolute;
  left: 50%;
  /* top: 20%; */
}
.progress-color {
  width: 100%;
}

.extra-circle-2 {
  border-top-left-radius: 100%;
  /* padding: 10%; */
  height: 92%;
  width: 35%;
  right: 0;
  top: 3%;
  /* padding-left: 10%; */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
}
.extra-circle {
  position: absolute;
  border-top-left-radius: 100%;
  /* padding: 12%; */
  height: 90%;
  width: 60%;
  right: 0;
  top: 10%;
  background-color: rgba(255, 255, 255, 0.6);
}

@media only screen and (min-width: 250px) and (max-width: 640px) {
  .number-ubt-area,
  .guage-area {
    flex-grow: unset;
  }
  .un-container {
    border-radius: 30px;
  }
}

/* .card {
  max-width: 800px;
  min-width: 40%;
  background-color: #08aeea;
  background-image: linear-gradient(45deg, #08aeea 31%, #2af598 100%);
  border-radius: 20px !important;
  height: 150px;
}
@media only screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
  .cardStyleForGauge {
    max-width: 800px !important;
    min-width: 40% !important;
    text-align: center;
  }
}

@media (min-width: 960px) {
  .temp {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 50% !important;
    position: absolute;
    left: 50%;
  }
} */

/* @media only screen and (max-width: 1024px) {
  .cardStyleForGauge {
    height: 220px !important;
    max-width: 600px !important;
    min-width: 40% !important;
  }
} */

/* @media only screen and (min-width: 600px) and (max-width: 768px) {
  .cardStyleForGauge {
    height: 180px !important;
    max-width: 600px !important;
    min-width: 40% !important;
  }
  .ultra-big-number-title {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 959px) {
  .cardStyleForGauge {
    height: 180px !important;
  }
  .vue-gauge {
    margin-top: 20px !important;
  }
}
@media only screen and (min-width: 250px) and (max-width: 599px) {
  .vue-gauge {
    margin-top: 0px !important;
  }
  .main-number {
    font-size: 1.1rem !important;
  }
  .ultra-big-number-title {
    font-size: 0.9rem !important;
  }
}

.cardStyleForGauge {
  max-width: 800px !important;
  min-width: 40% !important;
  height: 150px;
  background-color: #08aeea;
  background-image: linear-gradient(45deg, #000046 31%, #1cb5e0 100%);
  border-radius: 20px !important;
} */

/* @media only screen and (max-width: 770px) { */

/* .extra-circle-2xx {
  border-top-left-radius: 100%;
  padding: 10%;
  height: 140%;
  width: 100%;
  padding-left: 10%;
  margin-right: -60px;
  background-color: rgba(255, 255, 255, 0.2);
}

.ultra-big-number-title {
  font-size: 1.5rem;
}

.font-styling {
  color: #ffff !important;
  font-weight: bold;
}

.main-number {
  font-size: 2.1rem !important;
} */

@media only screen and (min-width: 600px) and (max-width: 768px) {
  /* .main-number {
    font-size: 1.5rem !important;
  }
  .ultra-big-number-title {
    font-size: 1.3rem;
  } */
  .vue-gauge {
    margin-top: 0px;
  }
}
</style>
